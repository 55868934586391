@import "mixin";
$black_color: #000;
$white_color: #fff;
$gray_light_color: #eee;
$gray_color: #b1b1b1;
$gray_dark_color: #848484;

$primary_color: #dd4b03;
$secondary_color: #c44405;

$bg_primary_color: #f8f8f8;
$bg_secondary_color: #f5f4f3;

$text_color: #222;
$text2_color: #333;

$table_color: #e5e5e5;

$border_box_color: #d9e9e2;

$dept_blue_color: #58c1cd;
$dept_purple_color: #b8589d;
$dept_green_color: #00af84;
$dept_orange_color: #e18900;
$dept_yellow_color: #dbc157;
$dept_pink_color: #ed7986;

$jp_font: 'Noto Sans JP', 'Hiragino Sans', YuGothic, 'Yu Gothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
$en_font: 'Roboto Condensed', sans-serif;
$mincho_font: 'Noto Serif JP', serif;

$breakpoints-min: (
	'xxs': 'screen and (min-width: 361px)',
	'xs': 'screen and (min-width: 641px)',
	's' : 'screen and (min-width: 768px)',
	'sm': 'screen and (min-width: 992px)',
	'm': 'screen and (min-width: 1081px)',
	'ml': 'screen and (min-width: 1281px)',
	'l' : 'screen and (min-width: 1481px)',
	'xl': 'screen and (min-width: 1601px)',
	'xxl': 'screen and (min-width: 1921px)',
);
// @include mq-min(m){}

$breakpoints-max: (
	'xxs': 'screen and (max-width: 360px)',
	'xs': 'screen and (max-width: 640px)',
	's' : 'screen and (max-width: 767px)',
	'sm': 'screen and (max-width: 991px)',
	'm': 'screen and (max-width: 1080px)',
	'ml': 'screen and (max-width: 1280px)',
	'l' : 'screen and (max-width: 1480px)',
	'xl': 'screen and (max-width: 1600px)',
	'xxl': 'screen and (max-width: 1920px)',
);
// @include mq-max(m){}
