.front {
	position: relative;
	background: $bg_primary_color;
	display: block;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		background: $bg_secondary_color;
		width: 36.5%;
		height: 100%;
	}
}

.front-mainv {
	position: relative;
	z-index: 0;
	// background: $black_color;
	// padding-top: 56.25%;
	width: 100%;
	height: calc(100vh - 58px);
	overflow: hidden;

	@include mq-min(s) {
		height: 100vh;
	}

	iframe {
		position: absolute;
		z-index: -1;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	#yt-vol {
		position: absolute;
		z-index: 2;
		top: 15px;
		right: 7px;

		@include mq-min(s) {
			top: 120px;
			right: 12px;
		}

		& > div {
			padding: 5px;
			width: 40px;
			text-align: center;
			line-height: 1;
			cursor: pointer;

			@include mq-min(s) {
				width: 50px;
			}

			i {
				color: $primary_color;

				@include fontsize(24);

				@include mq-min(s) {
					@include fontsize(36);
				}
			}
		}

		.icon-vol-on {
			display: none;
		}

		.icon-vol-off {
			display: block;
		}

		&.vol-on {
			.icon-vol-on {
				display: block;
			}

			.icon-vol-off {
				display: none;
			}
		}
	}
}

.front-flash-news {
	position: relative;

	&__block {
		background: $white_color;

		@include mq-min(s) {
			display: flex;
		}
	}

	&__title {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 0 0 auto;
		background: $text2_color;
		color: $white_color;
		width: 100%;
		height: 45px;
		font-weight: 700;
		line-height: 1;

		@include fontsize(15);

		@include mq-min(s) {
			width: 300px;
			height: auto;

			@include fontsize(20);
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 7px 15px;
		min-height: 60px;

		@include mq-min(s) {
			flex: 1 1 auto;
			padding: 40px 85px;
			min-height: 120px;
		}
	}

	&__item {
		a {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			margin: 5px 0;
			padding: 5px 0;
			transition: all 0.2s ease;

			@include mq-min(s) {
				flex-wrap: nowrap;
				margin: 10px 0;
			}

			&:hover {
				.title {
					@include mq-min(s) {
						text-decoration: underline;
					}
				}
			}
		}

		.date {
			display: block;
			color: $text2_color;
			font-family: $en_font;
			font-weight: 700;
			line-height: 20px;
			letter-spacing: 0.08em;

			@include fontsize(12);

			@include mq-min(s) {
				line-height: 28px;

				@include fontsize(16);
			}
		}

		.title {
			margin-top: 5px;
			width: 100%;
			font-weight: 400;
			line-height: (38 / 24);

			@include fontsize(12);

			@include mq-min(s) {
				margin: 0 0 0 55px;

				@include fontsize(16);
			}
		}
	}
}

.front-pickup {
	position: relative;
	padding: 40px 0 35px;

	@include mq-min(s) {
		padding: 175px 0 85px;
	}

	.container {
		@include mq-min(s) {
			max-width: 1730px;
		}
	}

	&__title {
		position: relative;
		z-index: 1;
		color: $text2_color;
		margin-bottom: -15px;
		font-weight: 700;
		text-align: center;
		line-height: 1.25;
		letter-spacing: 0.08em;

		@include fontsize(25);

		@include mq-min(s) {
			margin-bottom: -30px;

			@include fontsize(40);
		}
	}

	&__list {
		position: relative;
		z-index: 0;
		background: $white_color;
		padding: 37.5px 5.75%;

		@include mq-min(s) {
			padding: 100px 65px 85px;
		}

		&::before {
			content: "";
			position: absolute;
			top: calc(100% - 52px);
			left: -10px;
			background-image: url(../img/front/pickup_title_en.png);
			background-position: left top;
			background-repeat: no-repeat;
			background-size: contain;
			width: 180px;
			height: 104px;

			@include mq-min(s) {
				top: -2.5%;
				left: 50%;
				margin-left: 270px;
				width: 234.5px;
				height: 135px;
				transform: translate(-50%, -50%);
			}

			@include mq-min(ml) {
				margin-left: 390px;
				width: 469px;
				height: 270px;
			}
		}
	}

	&__item {
		position: relative;
		background: $white_color;
		margin-bottom: 10px;

		@include mq-min(s) {
			margin-bottom: 20px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		a {
			position: relative;
			display: block;
			transition: all 0.2s ease;

			&::before {
				content: '';
				position: absolute;
				bottom: 0;
				right: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 52px 52px;
				border-color: transparent transparent $black_color transparent;

				@include mq-min(s) {
					border-width: 0 0 84px 84px;
				}
			}

			&::after {
				content: '';
				position: absolute;
				bottom: 12px;
				right: 13px;
				border-top: 2px solid $white_color;
				border-right: 2px solid $white_color;
				margin: auto;
				width: 7px;
				height: 7px;
				transform: rotate(45deg);

				@include mq-min(s) {
					bottom: 20px;
					right: 25px;
					border-top-width: 3px;
					border-right-width: 3px;
					width: 9px;
					height: 9px;
				}
			}

			&:hover {
				@include mq-min(s) {
					opacity: 0.7;
				}
			}
		}
	}
}

.front-outline {
	position: relative;
	padding: 35px 0 80px;

	@include mq-min(s) {
		padding: 85px 0;
	}

	&__title {
		display: none;
	}

	&__item {
		position: relative;
		z-index: 0;
		display: flex;

		&::before {
			@include mq-min(s) {
				content: "";
				position: absolute;
				top: 105px;
				right: 0;
				z-index: -1;
				background: $white_color;
				width: percentage(1760 / 1920);
				height: calc(100% - 105px);
			}
		}
	}

	.image {
		position: absolute;
		top: 0;
		left: 0;
		width: percentage(500 / 750);

		@include mq-min(s) {
			position: relative;
			margin-bottom: 140px;
			width: percentage(1100 / 1920);
		}
	}

	.catch {
		position: absolute;
		top: 0;
		right: 6.75%;
		width: 67.5px;

		@include mq-min(s) {
			top: -45px;
			right: 4.5%;
			width: 135px;
		}
	}

	.text-wrap {
		@include mq-min(s) {
			margin-top: 105px;
			padding: 160px 40px 80px 80px;
			width: percentage(820 / 1920);
		}
	}

	.text {
		position: relative;
		margin-left: percentage(70 / 750);
		margin-bottom: 30px;
		padding: 55% 45px 40px;
		line-height: (50 / 24);
		width: percentage(680 / 750);

		@include fontsize(12);

		@include mq-min(s) {
			background: transparent;
			margin: 0 0 10%;
			padding: 0;
			width: 100%;
			max-width: 545px;
			line-height: (60 / 16);

			@include fontsize(16);
		}

		&::before {
			content: "";
			position: absolute;
			top: 95px;
			right: 0;
			z-index: -1;
			background: $white_color;
			width: 100%;
			height: calc(100% - 95px);

			@include mq-min(s) {
				display: none;
			}
		}
	}

	.m-btn {
		margin: 0 auto;

		@include mq-min(s) {
			margin: 0;
		}
	}
}

.front-course {
	position: relative;
	padding: 55px 0 55px;

	@include mq-min(s) {
		padding: 115px 0 85px;
	}

	&__title {
		position: relative;
		z-index: 0;
		color: $text2_color;
		margin-bottom: 60px;
		font-weight: 700;
		text-align: center;
		line-height: 1.25;
		letter-spacing: 0.08em;

		@include fontsize(25);

		@include mq-min(s) {
			margin-bottom: 95px;

			@include fontsize(40);
		}

		&::before {
			content: "";
			position: absolute;
			top: 15%;
			left: 44%;
			z-index: -1;
			background-image: url(../img/front/course_title_en.png);
			background-position: left top;
			background-repeat: no-repeat;
			background-size: contain;
			width: 169px;
			height: 99px;
			transform: translate(-50%, -50%);

			@include mq-min(s) {
				top: 50%;
				left: 50%;
				width: 338px;
				height: 197px;
			}
		}
	}

	&__block {
		background: $white_color;
		margin-left: 5.85%;
		padding: 35px 0 50px;

		@include mq-min(s) {
			background: transparent;
			margin-left: 0;
			padding: 0;
		}
	}

	.catch {
		position: absolute;
		top: 70px;
		right: 4.75%;
		z-index: 2;
		width: 88px;

		@include mq-min(s) {
			top: -85px;
			right: 4.5%;
			width: 218px;
		}
	}

	&__item {
		position: relative;
		margin-bottom: 40px;

		@include mq-min(s) {
			display: flex;
			align-items: flex-end;
			margin-bottom: 90px;
		}

		&:nth-child(odd) {
			flex-direction: row-reverse;

			.image {
				@include mq-min(s) {
					margin-left: percentage(-1000 / 1920);
				}
			}

			.text-wrap-inner {
				@include mq-min(s) {
					padding: 55px 16% 35px 10%;
				}

				@include mq-min(ml) {
					padding: 55px 16% 35px 10%;
				}
			}
		}

		&:nth-child(even) {
			.image {
				@include mq-min(s) {
					margin-right: percentage(-1000 / 1920);
				}
			}

			.text-wrap-inner {
				@include mq-min(s) {
					margin-left: percentage(530 / 1300);
					padding: 55px 9% 35px 17%;
				}

				@include mq-min(ml) {
					padding: 55px 9% 35px 17%;
				}
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		.image {
			position: relative;
			z-index: 1;
			margin-bottom: 15px;
			padding-left: 7%;

			@include mq-min(s) {
				margin-bottom: 100px;
				padding-left: 0;
				width: percentage(1000 / 1920);
			}

			& > div {
				overflow: hidden;
			}

			img {
				transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
			}

			&.active {
				img {
					@include mq-min(s) {
						transform: scale(1.05);
					}
				}
			}
		}

		.text-wrap {
			position: relative;
			z-index: 0;
			padding: 0 7%;

			@include mq-min(s) {
				margin: 0 auto;
				padding: 0 40px;
				width: 100%;
				max-width: 1380px;
			}
		}

		.text-wrap-inner {
			position: relative;
			transition: all 0.2s ease;

			@include mq-min(s) {
				background: $white_color;
				width: percentage(770 / 1300);
				min-height: 340px;
			}

			@include mq-min(ml) {
				min-height: 390px;
			}

			&:hover {
				.btn {
					@include mq-min(s) {
						background: $primary_color;
					}

					&::before {
						@include mq-min(s) {
							border-top-color: $white_color;
							border-right-color: $white_color;
						}
					}
				}
			}

			a {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 43px;
				height: 43px;
				border-radius: 50%;

				@include mq-min(s) {
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 0;
				}
			}
		}

		.title {
			margin-bottom: 15px;

			@include mq-min(s) {
				margin-bottom: 40px;
			}

			.jp {
				display: block;
				color: $text2_color;
				font-weight: 700;
				line-height: 1.35;
				letter-spacing: 0.08em;

				@include fontsize(19);

				@include mq-min(s) {
					@include fontsize(34);
				}
			}

			.en {
				display: block;
				color: $primary_color;
				margin-top: 10px;
				font-family: $en_font;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 0.08em;

				@include fontsize(10);

				@include mq-min(s) {
					margin-top: 12px;

					@include fontsize(13);
				}
			}
		}

		.text {
			margin-bottom: 10px;
			line-height: (38 / 24);

			@include fontsize(12);

			@include mq-min(s) {
				margin-bottom: 40px;
				line-height: (30 / 16);

				@include fontsize(16);
			}
		}

		.btn {
			position: relative;
			display: block;
			background: $white_color;
			width: 43px;
			height: 43px;
			border-radius: 50%;
			box-shadow: 1px 3px 30px 3px rgba($black_color, 0.1);
			transition: all 0.2s ease;

			@include mq-min(s) {
				width: 86px;
				height: 86px;
				box-shadow: 2.5px 6.5px 72px 7px rgba($black_color, 0.1);
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 2px;
				border-top: 2px solid $text_color;
				border-right: 2px solid $text_color;
				margin: auto;
				width: 7px;
				height: 7px;
				transform: rotate(45deg);
				transition: all 0.2s ease;

				@include mq-min(s) {
					border-top-width: 3px;
					border-right-width: 3px;
					width: 9px;
					height: 9px;
				}
			}
		}
	}
}

.front-news {
	position: relative;
	padding: 55px 0 65px;

	@include mq-min(s) {
		padding: 90px 0 100px;
	}

	.container {
		position: relative;
	}

	&__title {
		position: relative;
		z-index: 0;
		color: $text2_color;
		margin-bottom: 45px;
		font-weight: 700;
		text-align: center;
		line-height: 1.25;
		letter-spacing: 0.08em;

		@include fontsize(25);

		@include mq-min(s) {
			position: absolute;
			top: 0;
			left: 40px;
			margin-bottom: 25px;

			@include fontsize(40);
		}

		&::before {
			content: "";
			position: absolute;
			top: 15%;
			left: 45%;
			z-index: -1;
			background-image: url(../img/front/news_title_en.png);
			background-position: left top;
			background-repeat: no-repeat;
			background-size: contain;
			width: 138px;
			height: 100px;
			transform: translate(-50%, -50%);

			@include mq-min(s) {
				width: 276px;
				height: 200px;
			}
		}
	}

	.news-tag-nav {
		margin-bottom: 15px;

		@include mq-min(s) {
			display: flex;
			flex-direction: row-reverse;
			margin-bottom: 60px;
		}

		.tag {
			display: flex;
			flex-wrap: wrap;

			span {
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid $text_color;
				color: $text2_color;
				margin: 0 -1px -1px 0;
				padding: 2px 7px;
				width: 25%;
				min-width: 60px;
				height: 40px;
				line-height: 1;
				white-space: nowrap;
				transition: all 0.2s ease;

				@include fontsize(12);

				@include mq-min(s) {
					padding: 5px 25px;
					width: auto;
					min-width: 100px;
					height: 60px;

					@include fontsize(15);
				}

				&.active {
					background: $text2_color;
					color: $white_color;
				}

				&:hover {
					@include mq-min(s) {
						background: $text2_color;
						color: $white_color;
					}
				}
			}
		}
	}

	&__body {
		position: relative;
	}

	.tag-box {
		position: relative;
	}

	.tag-box__inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: opacity .5s, visibility 0s ease .5s;
		opacity: 0;
		visibility: hidden;

		&.active {
			position: static;
			transition-delay: 0s;
			opacity: 1;
			visibility: visible;
		}
	}

	&__list {
		margin-bottom: 15px;

		@include mq-min(s) {
			margin-bottom: 35px;
		}
	}

	&__item {
		border-bottom: 1px solid #ccc;
		padding: 25px 0;

		@include mq-min(s) {
			padding: 35px 0;
		}

		&:last-child {
			border-bottom: none;
		}

		.link,
		.no-link {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			border-left: 2px solid $primary_color;
			padding: 0 20px;

			@include mq-min(s) {
				flex-wrap: nowrap;
				border-left-width: 3px;
				padding: 5px 0 5px 30px;
			}
		}

		.link {
			transition: all 0.2s ease;

			&:hover {
				.title {
					@include mq-min(s) {
						text-decoration: underline;
					}
				}
			}
		}

		.date {
			display: block;
			color: $primary_color;
			font-family: $en_font;
			font-weight: 700;
			line-height: 20px;
			letter-spacing: 0.08em;

			@include fontsize(12);

			@include mq-min(s) {
				line-height: 28px;

				@include fontsize(16);
			}
		}

		.tag {
			margin-left: 10px;

			@include mq-min(s) {
				margin-left: 40px;
			}

			span {
				display: flex;
				justify-content: center;
				align-items: center;
				background: $text2_color;
				color: $white_color;
				padding: 2px 7px;
				height: 20px;

				@include fontsize(11.5);

				@include mq-min(s) {
					padding-bottom: 3px;
					width: 100px;
					height: 28px;

					@include fontsize(14);
				}
			}
		}

		.title {
			margin-top: 5px;
			width: 100%;
			font-weight: 400;
			line-height: (38 / 24);

			@include fontsize(12);

			@include mq-min(s) {
				margin: 0 0 0 55px;

				@include fontsize(16);
			}
		}
	}
}

.front-learnmore {
	position: relative;
	background-image: url(../img/front/learnmore_bg.jpg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 40px 0;

	@include mq-min(s) {
		padding: 85px 0;
	}

	@include mq-min(sm) {
		padding: 115px 0;
	}

	&__main-block {
		margin-bottom: 35px;

		@include mq-min(s) {
			margin-bottom: 50px;
		}

		@include mq-min(sm) {
			margin-bottom: 65px;
		}
	}

	&__main-list {
		@include mq-min(s) {
			margin: 0 -15px;

			@include flex-wrap;
		}
	}

	&__main-item {
		margin-bottom: 12.5px;

		@include mq-min(s) {
			margin-bottom: 0;

			@include col(4, 15px);
		}

		&:last-child {
			margin-bottom: 0;
		}

		.image {
			margin-bottom: calc(-9.5% + -50px);
			width: 34.5%;
			overflow: hidden;

			@include mq-min(s) {
				margin-bottom: -90px;
				width: percentage(340 / 380);
			}

			&.active {
				img {
					transform: scale(1.05);
				}
			}

			img {
				transition: all 0.3s cubic-bezier(.25, .46, .45, .94);
			}
		}

		.title-wrap {
			background: $white_color;
			margin-left: 10%;
			width: 90%;

			@include mq-min(s) {
				margin-left: percentage(40 / 380);
				width: percentage(340 / 380);
			}

			a {
				display: block;
				padding: 9.5% 5% 9.5% 33.5%;

				@include mq-min(s) {
					padding: 115px 35px 30px;
				}
			}
		}

		.title {
			.jp {
				display: block;
				color: $text2_color;
				font-weight: 700;
				line-height: 1.35;
				letter-spacing: 0.08em;

				@include fontsize(17);

				@include mq-min(s) {
					@include fontsize(22);
				}
			}

			.en {
				display: block;
				color: $primary_color;
				margin-top: 3px;
				font-family: $en_font;
				font-weight: 700;
				letter-spacing: 0.08em;

				@include fontsize(10);

				@include mq-min(s) {
					margin-top: 8px;

					@include fontsize(11);
				}
			}
		}
	}

	&__sub-block {
		background: rgba($white_color, 0.5);
		padding: 25px 20px;

		@include mq-min(s) {
			padding: 40px 35px;
		}

		@include mq-min(sm) {
			padding: 50px 55px;
		}
	}

	&__sub-list {
		@include mq-min(s) {
			margin: 0 -25px;

			@include flex-wrap;
		}
	}

	&__sub-item {
		margin-top: 12.5px;

		@include mq-min(s) {
			margin-top: 30px;

			@include col(4, 25px);
		}

		&:nth-of-type(1) {
			margin-top: 0;
		}

		&:nth-of-type(2),
		&:nth-of-type(3) {
			@include mq-min(s) {
				margin-top: 0;
			}
		}

		a {
			position: relative;
			display: block;
			background: $white_color;
			border-left: 1px solid $primary_color;
			padding: 15px 0 15px 20px;
			box-shadow: 1px 3px 30px 3px rgba($black_color, 0.1);
			transition: all 0.2s ease;

			@include fontsize(12);

			@include mq-min(s) {
				border-left-width: 2px;
				padding: 33px 50px 33px 35px;
				box-shadow: 2.5px 6.5px 72px 7px rgba($black_color, 0.1);

				@include fontsize(16);
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				right: 20px;
				border-top: 2px solid $black_color;
				border-right: 2px solid $black_color;
				margin: auto;
				width: 7px;
				height: 7px;
				transform: rotate(45deg);
				transition: all 0.2s ease;

				@include mq-min(s) {
					right: 30px;
					border-top-width: 3px;
					border-right-width: 3px;
					width: 9px;
					height: 9px;
				}
			}

			&:hover {
				@include mq-min(s) {
					color: $primary_color;
				}

				&::before {
					border-top-color: $primary_color;
					border-right-color: $primary_color;
					right: 27px;
				}
			}
		}
	}
}
