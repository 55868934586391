/* ==========================
		bottomPageMainv
============================= */
.bottomPageMainv {
	position: relative;

	&__img {
		background-image: url("/hs/wp-content/themes/ghs/img/hanyo/mv.jpg");
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 250px;

		@include mq-min(s) {
			height: 620px;
		}
	}

	&.noMainv {
		.bottomPageMainv__img {
			background: none;
			height: 120px;

			@include mq-min(s) {
				height: 360px;
			}
		}
	}

	&.outline {
		.bottomPageMainv__img {
			background-image: url("/hs/wp-content/themes/ghs/img/outline/mv.jpg");
		}
	}

	&.course {
		.bottomPageMainv__img {
			background-image: url("/hs/wp-content/themes/ghs/img/course/mv.jpg");
		}
	}

	&.activity {
		.bottomPageMainv__img {
			background-image: url("/hs/wp-content/themes/ghs/img/activity/mv.jpg");
		}
	}

	&.schoollife {
		.bottomPageMainv__img {
			background-image: url("/hs/wp-content/themes/ghs/img/schoollife/mv.jpg");
		}
	}

	&.future {
		.bottomPageMainv__img {
			background-image: url("/hs/wp-content/themes/ghs/img/future/mv.jpg");
		}
	}

	&.for-alumni {
		.bottomPageMainv__img {
			background-image: url("/hs/wp-content/themes/ghs/img/alumni/mv.jpg");
		}
	}

	&.for-prospective {
		.bottomPageMainv__img {
			background-image: url("/hs/wp-content/themes/ghs/img/prospective/mv.jpg");
		}
	}

	&.for-students {
		.bottomPageMainv__img {
			background-image: url("/hs/wp-content/themes/ghs/img/students/mv.jpg");
		}
	}

	/* ------- enrollment/ ------- */
	@at-root {
		.page-id-35995 {
			.bottomPageMainv {
				.bottomPageMainv__img {
					background-image: url("/hs/wp-content/themes/ghs/img/future/enrollment/mv.jpg");
				}
			}
		}
	}

	/* ------- employment/ ------- */
	@at-root {
		.page-id-35993 {
			.bottomPageMainv {
				.bottomPageMainv__img {
					background-image: url("/hs/wp-content/themes/ghs/img/future/employment/mv.jpg");
				}
			}
		}
	}

	/* ------- application ------- */
	@at-root {
		.page-id-36222 {
			.bottomPageMainv {
				.bottomPageMainv__img {
					background-image: url("/hs/wp-content/themes/ghs/img/application/mv.jpg");
				}
			}
		}
	}

	/* ------- openschool ------- */
	@at-root {
		.page-id-36236 {
			.bottomPageMainv {
				.bottomPageMainv__img {
					background-image: url("/hs/wp-content/themes/ghs/img/application/openschool/mv.jpg");
				}
			}
		}
	}

	/* ------- contact ------- */
	@at-root {
		.page-id-32051 {
			.bottomPageMainv {
				.bottomPageMainv__img {
					background-image: url("/hs/wp-content/themes/ghs/img/contact/mv.jpg");
				}
			}
		}
	}

	/* ------- document-request ------- */
	@at-root {
		.page-id-36307,
		.page-id-36315 {
			.bottomPageMainv {
				.bottomPageMainv__img {
					background-image: url("/hs/wp-content/themes/ghs/img/request/mv.jpg");
				}
			}
		}
	}

	/* ------- recruitment ------- */
	@at-root {
		.page-id-36319 {
			.bottomPageMainv {
				.bottomPageMainv__img {
					background-image: url("/hs/wp-content/themes/ghs/img/recruitment/mv.jpg");
				}
			}
		}
	}

	/* ------- document-request ------- */
	@at-root {
		.page-id-31479 {
			.bottomPageMainv {
				.bottomPageMainv__img {
					background-image: url("/hs/wp-content/themes/ghs/img/request/mv.jpg");
				}
			}
		}
	}

	/* ------- privacy ------- */
	@at-root {
		.page-id-31479 {
			.bottomPageMainv {
				.bottomPageMainv__img {
					background-image: url("/hs/wp-content/themes/ghs/img/privacy/mv.jpg");
				}
			}
		}
	}

	/* ------- term ------- */
	@at-root {
		.page-id-31477 {
			.bottomPageMainv {
				.bottomPageMainv__img {
					background-image: url("/hs/wp-content/themes/ghs/img/term/mv.jpg");
				}
			}
		}
	}

	/* ------- hyouka ------- */
	@at-root {
		.page-id-36292 {
			.bottomPageMainv {
				.bottomPageMainv__img {
					background-image: url("/hs/wp-content/themes/ghs/img/hyouka/mv.jpg");
				}
			}
		}
	}

	&__block {
		position: absolute;
		bottom: -90px;
		left: 0;
		right: 0;
		z-index: 2;
		background: $white_color;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto;
		padding: 20px 15px 30px;
		width: 80%;
		min-height: 180px;

		@include mq-min(s) {
			padding: 30px 25px 50px;
			width: 600px;
			min-height: 280px;
		}
	}

	&__ttl {
		margin-bottom: 10px;
		font-family: $mincho_font;
		font-weight: 500;
		line-height: (80 / 50);
		letter-spacing: 0.08em;

		@include fontsize(28);

		@include mq-min(s) {
			margin-bottom: 25px;

			@include fontsize(50);
		}
	}
}

/* ==========================
	bottomPageBreadlist
============================= */

.bottomPageBread {
	& > span {
		display: block;
		margin: 0 -0.25em;
		line-height: 1.35;
		letter-spacing: 0;

		@include mq-min(s) {
			margin: 0 -0.8em;
		}

		& > span {
			color: $gray_dark_color;
			font-weight: 500;
			line-height: (24 / 14);
			letter-spacing: 0.08em;

			@include fontsize(10);

			@include mq-min(s) {
				@include fontsize(14);
			}

			& span.breadcrumb_last,
			& a {
				margin: 0 0.25em;

				@include mq-min(s) {
					margin: 0 0.8em;
				}
			}

			& span.breadcrumb_last {
				color: $primary_color;
				// &::after {
				// 	content: '';
				// 	position: relative;
				// 	top: 0;
				// 	left: 0;
				// 	display: inline-block;
				// 	border-top: 1px solid $gray_dark_color;
				// 	border-right: 1px solid $gray_dark_color;
				// 	margin: 0 1em;
				// 	width: 6px;
				// 	height: 6px;
				// 	vertical-align: middle;
				// 	transform: rotate(45deg);
				// 	pointer-events: none;

				// 	@include mq-min(s) {
				// 		margin: 0 0.8em;
				// 	}
				// }

				// &.breadcrumb_last::after {
				// 	display: none;
				// }
			}

			& a {
				color: $gray_dark_color;
				transition: all 0.2s ease;

				&:hover {
					@include mq-min(s) {
						color: $primary_color;
					}
				}
			}
		}
	}
}

/* ==========================
	Gray Back
============================= */
.grayBack {
	position: relative;
	background: $bg_primary_color;
	padding: 90px 0 55px;

	@include mq-min(s) {
		padding-bottom: 135px;
	}

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background: $bg_secondary_color;
		width: 36.5%;
		height: 100%;
	}
}

/* ==========================
	White Back
============================= */
.whiteBack {
	position: relative;
	padding: 0 3.5%;
	width: 100%;

	@include mq-min(s) {
		margin: 0 auto;
		padding: 0 40px;
		max-width: 1280px;
	}

	&-container {
		position: relative;
		z-index: 1;
		background: $white_color;
		padding: 1px 25px 45px;

		@include mq-min(s) {
			padding: 1px 120px 75px;
		}
	}
}
