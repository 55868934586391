@mixin cf {
	&::after {
		content: "";
		display: block;
		clear: both;
	}
}
// @include cf;

@mixin margin_auto {
	margin-right: auto;
	margin-left: auto;
}
// @include margin_auto;

@mixin abs ($top: auto, $right: auto, $bottom: auto, $left: auto) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}
// @include abs(5px, 20px, 10px, 15px);

@mixin box_s {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
// @include box_s;

@mixin transition($property: all, $duration: .5s) {
	transition: $property $duration 0s cubic-bezier(.165, .84, .44, 1);
}
// @include transition(all,0.5s);

@mixin transition2($property: all, $duration: .5s , $delay:0s) {
	transition: $property $duration $delay ease;
}
// @include transition2(all,0.5s, 1s);

@mixin transition2_2($property: all, $duration: .5s , $delay:0s) {
	transition: $property $duration $delay ease-out;
}
// @include transition2_2(all,0.5s, 0s);

@mixin transition3($property: all, $duration: .5s, $delay:0s, $cubic:(.645, .045, .355, 1)) {
	transition: $property $duration $delay cubic-bezier($cubic);
}
// @include transition3(all,0.5s,0s,(.645,.045,.355,1));

@mixin origin($property: left, $property2: top) {
	transform-origin: $property $property2;
}
// @include origin(left, top);

@mixin scale($atai:0) {
	transform: scale($atai);
}
// @include scale(1);

@mixin scaleX($atai:0) {
	-webkit-transform: scaleX($atai);
	transform: scaleX($atai);
}
// @include scaleX(1);

@mixin scaleY($atai:0) {
	transform: scaleY($atai);
}
// @include scaleY(1);

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacityIE: $opacity * 100;
	filter: alpha(opacity=$opacityIE);
}
// @include opacity(0);

@mixin wh100 {
	width: 100%;
	height: 100%;
}
// @include wh100;

@mixin mq-min($breakpoint) {
	@media #{map-get($breakpoints-min, $breakpoint)} {
		@content;
	}
}
// @include mq-min(m) {}

@mixin mq-max($breakpoint) {
	@media #{map-get($breakpoints-max, $breakpoint)} {
		@content;
	}
}
// @include mq-max(m) {}

@mixin animation($name, $duration: .5s, $timing:ease, $delay:0s, $iteration:1, $direction:normal, $fill-mode:both, $play-state:running ) {
	-webkit-animation: $name $duration $timing $delay $iteration $direction $fill-mode $play-state;
	animation: $name $duration $timing $delay $iteration $direction $fill-mode $play-state;
}
// @include animation(slide_line 1.0s ease 3s);

@mixin fontsize($size: 24, $base: 16) {
	font-size: $size + px;
	font-size: ($size / $base) * 1rem;
}

@mixin justify {
	text-align: justify;
	text-justify: inter-ideograph;
}

@mixin full-width() {
	margin-left: calc((100vw - 100%) / -2);
	margin-right: calc((100vw - 100%) / -2);
	max-width: auto;  // IE対策
	max-width: initial;
}

@mixin full-width-left() {
	margin-left: calc((100vw - 100%) / -2);
	max-width: auto;  // IE対策
	max-width: initial;
}

@mixin full-width-right() {
	margin-right: calc((100vw - 100%) / -2);
	max-width: auto;  // IE対策
	max-width: initial;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
// @include flex-wrap;

@mixin col($nam,$col-pd) {
  width: 100% * $nam / 12;
  padding:0 $col-pd;
}
// @include col(6, 20px);

@mixin gradient($startColor, $endColor, $orientation: vertical) {
	background: $startColor;
	@if $orientation == vertical {
		background: linear-gradient(to bottom, #{$startColor} 0%,  #{$endColor} 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$startColor}', endColorstr=' #{$endColor}', GradientType=0);
	} @else if $orientation == horizontal {
		background: linear-gradient(to right, #{$startColor} 0%,  #{$endColor} 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$startColor}', endColorstr=' #{$endColor}', GradientType=1);
	}
}

@mixin gradient-text($startColor, $endColor, $orientation: horizontal) {
	color: $startColor;
	display: inline-block;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	@if $orientation == vertical {
		background: linear-gradient(to bottom, #{$startColor} 0%,  #{$endColor} 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$startColor}', endColorstr=' #{$endColor}', GradientType=0);
	} @else if $orientation == horizontal {
		background: linear-gradient(to right, #{$startColor} 0%,  #{$endColor} 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$startColor}', endColorstr=' #{$endColor}', GradientType=1);
	}
}
