/* module.scss
--------------------------------------------------------------------------------------- */
@import "mixin", "base";

.categoryNews-sec {
	position: relative;
	padding: 50px 0 35px;

	@include mq-min(s) {
		padding: 100px 0 65px;
	}

	&.activity-news {
		padding: 20px 0 15px;

		@include mq-min(s) {
			padding: 50px 0 15px;
		}
	}

	&__inner {
		position: relative;
		z-index: 0;
		display: flex;
		padding: 1px 5.85% 25px;
		width: 100%;

		@include mq-min(s) {
			margin: 0 auto;
			padding: 1px 40px 50px;
			max-width: 1680px;
		}

		&::before {
			content: "";
			flex: none;
			display: block;
			width: 7%;

			@include mq-min(s) {
				width: 40px;
			}

			@media screen and (min-width: 1331px) {
				display: none;
			}
		}

		&::after {
			content: "";
			position: absolute;
			z-index: -1;
			top: 0;
			left: 5.85%;
			background: $white_color;
			margin-right: calc((100vw - 100%) / -2);
			width: calc((100vw - 100%) / 2 + 100% - 5.85%);
			height: 100%;

			@include mq-min(s) {
				left: 40px;
				margin-right: 0;
				width: calc(100% - 40px);
			}

			@include mq-min(sm) {
				margin-right: calc((100vw - 100%) / -2);
				width: calc((100vw - 100%) / 2 + 100% - 40px);
			}
		}
	}

	.container {
		padding: 0;
		max-width: 1200px;
	}

	h3 {
		margin-bottom: 0 !important;

		@include mq-min(s) {
			margin-bottom: 0 !important;
		}
	}

	&__list {
		margin: 0 !important;
	}

	&__item {
		border-bottom: 1px solid #ccc;
		padding: 25px 0 !important;

		@include mq-min(s) {
			padding: 35px 0 !important;
		}

		&:last-child {
			border-bottom: none;
		}

		&::before {
			display: none !important;
		}

		.link,
		.no-link {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			border-left: 2px solid $primary_color;
			padding: 0 20px;

			@include mq-min(s) {
				border-left-width: 3px;
				padding: 5px 0 5px 30px;
			}
		}

		.link {
			transition: all 0.2s ease;

			&:hover {
				.title {
					@include mq-min(s) {
						text-decoration: underline;
					}
				}
			}
		}

		.date {
			display: block;
			color: $primary_color;
			margin: 0 !important;
			font-family: $en_font;
			font-weight: 700;
			line-height: 20px;
			letter-spacing: 0.08em;

			@include fontsize(12);

			@include mq-min(s) {
				line-height: 28px;

				@include fontsize(16);
			}
		}

		.tag {
			display: flex;
			flex-wrap: wrap;
			margin: -5px 0 0 10px;

			@include mq-min(s) {
				margin-left: 40px;
			}

			span {
				display: flex;
				justify-content: center;
				align-items: center;
				background: $text2_color;
				color: $white_color;
				margin: 5px 10px 0 0;
				padding: 2px 7px;
				height: 20px;

				@include fontsize(11.5);

				@include mq-min(s) {
					padding-bottom: 3px;
					width: 100px;
					height: 28px;

					@include fontsize(14);
				}
			}
		}

		.title {
			border-bottom: none !important;
			margin: 5px 0 0 !important;
			padding: 0 !important;
			width: 100%;
			font-weight: 400;
			line-height: (38 / 24);

			@include fontsize(12);

			@include mq-min(s) {
				margin: 10px 0 0 !important;

				@include fontsize(16);
			}

			&::before {
				display: none !important;
			}
		}
	}
}
