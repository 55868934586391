.new-wpContent {
	.activity-list {
		display: flex;
		flex-wrap: wrap;
		margin: 30px -5px -15px;

		@include mq-min(s) {
			margin: 60px -15px -30px;
		}

		&__card {
			position: relative;
			display: flex;
			align-items: stretch;
			margin-bottom: 30px;

			@include col(6, 5px);

			@include mq-min(s) {
				margin-bottom: 60px;

				@include col(4, 15px);
			}

			.image {
				position: relative;
				z-index: 1;
				align-self: flex-start;
				width: percentage(340 / 380);
				overflow: hidden;
			}

			.text-wrap {
				position: relative;
				z-index: 0;
				background: $white_color;
				margin-top: 40%;
				margin-left: percentage(-300 / 380);
				padding: 30% 15px 55px;
				width: percentage(340 / 380);
				box-shadow: 1px 3px 30px 3px rgba($black_color, 0.1);

				@include mq-min(s) {
					padding: 30% 30px 105px;
					min-height: 360px;
					box-shadow: 2.5px 6.5px 72px 7px rgba($black_color, 0.1);
				}

				&:hover {
					.btn {
						@include mq-min(s) {
							background: $primary_color;
						}

						&::before {
							@include mq-min(s) {
								border-top-color: $white_color;
								border-right-color: $white_color;
							}
						}
					}
				}

				a {
					position: absolute;
					bottom: 15px;
					left: 0;
					right: 0;
					margin: 0 auto;
					width: 30px;
					height: 30px;
					border-radius: 50%;

					@include mq-min(s) {
						bottom: 0;
						left: 0;
						right: auto;
						width: 100%;
						height: 100%;
						border-radius: 0;
					}
				}
			}

			.title {
				display: block;
				color: $text2_color;
				margin: 0 0 5px;
				padding: 0;
				font-weight: 700;
				line-height: 1.4;
				letter-spacing: 0.08em;

				@include fontsize(16);

				@include mq-min(s) {
					margin-bottom: 10px;

					@include fontsize(22);
				}

				&::before {
					display: none;
				}
			}

			.text {
				margin: 0;
				line-height: (38 / 24);

				@include fontsize(12);

				@include mq-min(s) {
					line-height: (30 / 16);

					@include fontsize(16);
				}
			}

			.btn {
				position: absolute;
				bottom: 15px;
				left: 0;
				right: 0;
				margin: auto;
				display: block;
				background: $white_color;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				box-shadow: 1px 3px 30px 3px rgba($black_color, 0.1);
				transition: all 0.2s ease;

				@include mq-min(s) {
					position: absolute;
					bottom: 35px;
					width: 60px;
					height: 60px;
					box-shadow: 2.5px 6.5px 72px 7px rgba($black_color, 0.1);
				}

				&::before {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 2px;
					border-top: 2px solid $text_color;
					border-right: 2px solid $text_color;
					margin: auto;
					width: 7px;
					height: 7px;
					transform: rotate(45deg);
					transition: all 0.2s ease;

					@include mq-min(s) {
						border-top-width: 3px;
						border-right-width: 3px;
						width: 9px;
						height: 9px;
					}
				}
			}
		}
	}
}
