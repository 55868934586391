/* school/event の追加SCSS */
.new-wpContent {
	.event-list {
		position: relative;
		padding: 50px 0 0;

		@include mq-min(s) {
			padding: 100px 0 0;
		}

		&__item {
			display: flex;
			align-items: flex-start;
			margin-bottom: 15px;

			@include mq-min(s) {
				margin-bottom: 50px;
			}

			&:last-child {
				margin-bottom: 0;
			}

			figure {
				position: relative;
				z-index: 1;
				margin-right: -45%;
				width: 55%;

				@include mq-min(s) {
					margin-right: percentage(-210 / 1200);
					width: percentage(360 / 1200);
				}
			}

			.inner {
				background-color: $white_color;
				margin-top: 50px;
				padding: 31.5% 5% 5.5% 20px;
				width: 90%;

				@include mq-min(s) {
					margin-top: 65px;
					padding: 45px 35px 55px;
					padding-left: percentage(280 / 1200);
					min-height: 250px;
					width: percentage(1050 / 1200);
				}
			}

			h5 {
				font-weight: 700;
				line-height: 1.35;
				margin-bottom: 7px;

				@include fontsize(20);

				@include mq-min(s) {
					margin-bottom: 12px;

					@include fontsize(28);
				}
			}

			ul,
			li {
				margin: 0;
			}
		}
	}
}
