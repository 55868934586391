.new-wpContent {
	.outline-philosophy-box {
		background: $white_color;
		padding: 40px 20px 50px;

		@include mq-min(s) {
			padding: 80px 45px 100px;
		}

		&__tit {
			padding: 0;
			margin: 0 0 20px;
			color: $text2_color;
			font-family: $mincho_font;
			font-weight: 400;
			text-align: center;
			line-height: 1.35;
			letter-spacing: 0.08em;

			@include fontsize(32);

			@include mq-min(s) {
				margin-bottom: 40px;

				@include fontsize(50);
			}

			&::before {
				display: none;
			}

			span {
				position: relative;
				z-index: 0;
				display: inline-block;

				&::before {
					content: "";
					position: absolute;
					z-index: -2;
					top: 60%;
					left: -0.7em;
					background: $text2_color;
					width: calc(100% + 1.4em);
					height: 3px;
					transform: translateY(-50%);
				}

				&::after {
					content: "";
					position: absolute;
					z-index: -1;
					top: 0;
					left: -0.4em;
					background: $white_color;
					width: calc(100% + 0.8em);
					height: 100%;
				}
			}
		}

		&__lead {
			text-align: center;
			margin: 10px 0 25px;
			line-height: (30 / 16);

			@include fontsize(12);

			@include mq-min(s) {
				margin: 20px 0 45px;

				@include fontsize(16);
			}
		}

		&__list {
			counter-reset: number 0;
			margin: 0 auto;

			@include mq-min(s) {
				max-width: 865px;
			}
		}

		&__item {
			position: relative;
			z-index: 0;
			color: $text2_color;
			font-family: $mincho_font;
			padding: 0 0 5px 35px;
			margin: 0 0 15px;
			line-height: 1.5;

			@include fontsize(20);

			@include mq-min(s) {
				padding-left: 80px;
				margin: 0 0 30px;

				@include fontsize(30);
			}

			&::before {
				counter-increment: number 1;
				content: counter(number);
				position: absolute;
				top: 0;
				left: 0;
				background: none;
				width: auto;
				height: auto;
			}

			&::after {
				content: "";
				position: absolute;
				bottom: -0.2em;
				left: 25px;
				z-index: -1;
				border-bottom: 1px solid #ccc;
				border-left: 1px solid #ccc;
				width: calc(100% - 25px);
				height: 100%;

				@include mq-min(s) {
					left: 55px;
					width: calc(100% - 55px);
				}
			}
		}
	}

	.outline-history {
		&__list {
			border-top: 1px solid #ccc;
			margin-top: 40px;

			@include mq-min(s) {
				margin-top: 80px;
			}
		}

		&__item {
			display: flex;
			align-items: flex-start;
			border-bottom: 1px solid #ccc;
			padding: 10px 0;

			@include mq-min(s) {
				padding: 20px 0;
			}

			dt,
			dd {
				line-height: (30 / 16);

				@include fontsize(12);

				@include mq-min(s) {
					@include fontsize(16);
				}
			}

			dt {
				border-right: 1px solid #ccc;
				width: 95px;
				flex: none;
				text-align: center;
				font-weight: 700;
				padding: 5px 0;

				@include mq-min(s) {
					width: 165px;
				}
			}

			dd {
				padding: 5px 10px 5px 15px;

				@include mq-min(s) {
					padding: 5px 30px 5px 50px;
				}
			}
		}
	}

	.outline-merit {
		position: relative;
		z-index: 0;
		padding: 30px 0 0;

		@include mq-min(s) {
			padding: 120px 0 0;
		}

		&::before {
			content: "";
			position: absolute;
			z-index: -1;
			top: 138px;
			left: 5.85%;
			right: 5.85%;
			background: $white_color;
			margin: auto;
			max-width: 1600px;
			height: calc(100% - 138px);

			@include mq-min(s) {
				left: 40px;
				right: 40px;
				top: 230px;
				height: calc(100% - 230px);
			}
		}

		&::after {
			content: "";
			position: absolute;
			z-index: -1;
			top: 138px;
			right: 0;
			background: $white_color;
			width: 50%;
			height: calc(100% - 138px);

			@include mq-min(s) {
				top: 230px;
				height: calc(100% - 230px);
			}
		}

		.container {
			position: relative;

			@include mq-min(s) {
				padding: 0 40px 0 80px;
				max-width: 1320px;
			}

			@media screen and (min-width: 1331px) {
				padding: 0 60px;
				max-width: 1320px;
			}
		}

		.course-tag {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -6px;

			@include mq-min(s) {
				flex-wrap: nowrap;
			}

			p {
				margin: 0;
				padding: 0 6px;
				width: 50%;

				@include mq-min(s) {
					width: 25%;
				}

				&.active {
					span {
						background: $white_color;
					}
				}

				span {
					display: flex;
					justify-content: center;
					align-items: center;
					border-top: 2px solid $primary_color;
					background: transparent;
					height: 54px;
					line-height: 1.4;
					font-weight: 700;

					@include fontsize(13);

					@include mq-min(s) {
						height: 110px;

						@include fontsize(20);
					}
				}
			}
		}

		.course-tag-box {
			@include mq-max(s) {
				padding-left: 7%;
			}

			&__inner {
				display: none;
				padding-bottom: 50px;

				@include mq-min(s) {
					padding-bottom: 115px;
				}

				&.active {
					display: block;
				}
			}
		}

		&__list {
			margin: 40px 0;

			@include mq-min(s) {
				display: flex;
				margin: 60px -25px;
				padding-top: 100px;
			}
		}

		&__item {
			margin: 0;
			padding: 0;

			@include mq-min(s) {
				width: 33.3334%;
				margin: 0;
				padding: 0 25px;
			}

			&:nth-of-type(2) {
				margin-top: 30px;

				@include mq-min(s) {
					margin: 0;
					transform: translateY(-50px);
				}
			}

			&:nth-of-type(3) {
				margin-top: 30px;

				@include mq-min(s) {
					margin: 0;
					transform: translateY(-100px);
				}

				.card {
					&::before,
					&::after {
						display: none;
					}
				}
			}

			&::before {
				display: none;
			}

			.card {
				position: relative;
				z-index: 1;
				border: 1px solid #ccc;
				border-left: none;
				border-radius: 0 5px 5px 0;
				padding: 25px 20px 35px;

				@include mq-min(s) {
					padding: 35px 30px 50px;
					border-radius: 0 10px 10px 0;
					height: 100%;
				}

				@include mq-min(sm) {
					padding: 50px 45px 70px;
				}

				&::before,
				&::after {
					content: "";
					position: absolute;
					top: 100%;
					left: 0;
					right: 0;
					margin: auto;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 10px 15px 0 15px;

					@include mq-min(s) {
						top: 0;
						bottom: 0;
						left: 100%;
						right: auto;
						border-width: 30px 0 30px 15px;
					}
				}

				&::before {
					z-index: -1;
					border-color: $white_color transparent transparent transparent;

					@include mq-min(s) {
						border-color: transparent transparent transparent $white_color;
					}
				}

				&::after {
					z-index: -2;
					transform: scale(1.1);
					transform-origin: top;
					border-color: #ccc transparent transparent transparent;

					@include mq-min(s) {
						border-color: transparent transparent transparent #ccc;
						transform-origin: left;
					}
				}
			}

			.tit-en {
				position: absolute;
				top: -1em;
				left: 0;
				z-index: 1;
				color: $primary_color;
				font-family: $en_font;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 0.08em;
				margin: 0;

				@include fontsize(12);

				@include mq-min(s) {
					@include fontsize(15);
				}

				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;
					background: $white_color;
					width: calc(100% + 15px);
					height: 100%;

					@include mq-min(s) {
						width: calc(100% + 20px);
					}
				}
			}

			.icon {
				margin: 0 0 10px;
				text-align: center;

				@include mq-min(s) {
					margin: 0 0 17px;
				}
			}

			.tit-jp {
				color: $text2_color;
				margin: 0 0 15px;
				font-weight: 700;
				letter-spacing: 0.08em;
				text-align: center;
				line-height: 1.35;

				@include fontsize(16);

				@include mq-min(s) {
					margin: 0 0 25px;

					@include fontsize(24);
				}
			}
		}
	}

	/* ------- message ------- */
	.message-heading-wrap {
		h2 {
			@include mq-min(s) {
				margin: 0 45px 0 0;
				width: 255px;
			}
		}

		.message-sig {
			margin-top: 15px;
			text-align: right;

			@include mq-min(s) {
				margin-top: 5px;
			}

			span {
				font-size: 140%;
			}
		}
	}

	.message-image {
		margin: auto;
		width: percentage(1000 / 1200);
	}
}
