.new-wpContent {
	.m-contact-lead {
		position: relative;
		padding: 45px 0 0;

		@include mq-min(s) {
			padding: 120px 0 0;
		}
	}

	.m-form-area {
		position: relative;
		z-index: 0;
		margin-top: 50px;
		padding: 30px 0 50px;

		@include mq-min(s) {
			margin-top: 90px;
			padding: 60px 0 115px;
		}

		&::before {
			content: "";
			position: absolute;
			z-index: -1;
			top: 0;
			left: 5.85%;
			right: 5.85%;
			background: $white_color;
			margin: auto;
			max-width: 1600px;
			height: 100%;

			@include mq-min(s) {
				left: 40px;
				right: 40px;
			}
		}

		&::after {
			content: "";
			position: absolute;
			z-index: -1;
			top: 0;
			right: 0;
			background: $white_color;
			width: 50%;
			height: 100%;
		}

		.container {
			position: relative;

			@include mq-min(s) {
				padding: 0 40px 0 80px;
				max-width: 1320px;
			}

			@media screen and (min-width: 1331px) {
				padding: 0 60px;
				max-width: 1320px;
			}
		}

		&__body {
			padding-left: 7%;

			@include mq-min(s) {
				padding-left: 0;
			}
		}

		.form-fir-p {
			text-align: right;
			color: $primary_color;
			margin: 0;
		}
	}
}

.form1row {
	padding: 10px 0;

	@include mq-min(s) {
		padding: 20px 0;
	}
}

.form1row:last-child {
	border-bottom: none;
}

.form1row .innerflex {
	@include mq-min(s) {
		display: flex;
		align-items: center;
	}

	&.flex-start {
		@include mq-min(s) {
			align-items: flex-start;
		}

		.left-td {
			@include mq-min(s) {
				padding-top: 15px;
			}
		}
	}
}

.innerflex {
	.left-td {
		width: 100%;
		margin-bottom: 10px;

		@include mq-min(s) {
			width: 260px;
			margin-bottom: 0;
		}

		.label {
			font-size: 13px;
			display: block;

			@include mq-min(s) {
				font-size: 16px;
			}
		}
	}
}

.label .hissu {
	color: $primary_color;
	font-size: 11px;
	display: inline-block;
	transform: translate(2px, -1px);
}

.left-td .col-aka {
	color: $primary_color;
}

.right-borbotombox {
	border-bottom: 1px solid #ddd;
	padding-bottom: 25px;

	.f-r-ttl01 {
		font-size: 20px;
		color: $primary_color;
		font-weight: bold;
		margin-bottom: 12px;
	}

	.f-r-ttl01--more2 {
		margin-top: 40px;
	}

	.f-r-ttl02 {
		font-size: 18px;
		font-weight: bold;
		margin-top: 25px;
		margin-bottom: 8px;
	}

	.f-r-ttl03 {
		font-size: 16px;
		font-weight: bold;
		margin-top: 8px;
		margin-bottom: 8px;
	}
}

.right-borbotombox:last-child {
	border-bottom: none;
}

.f-r-subjectFlex {
	display: flex;
	flex-wrap: wrap;

	.f-r-subjectItem {
		font-size: 13px;
		padding: 0 4px;

		@include mq-min(s) {
			font-size: 16px;
		}
	}
}

.right-td .sexradiowrap {
	font-size: 13px;

	@include mq-min(s) {
		font-size: 16px;
	}
}

span.wpcf7-list-item.first {
	margin-left: 0;
}

.right-td .inp-txt input {
	width: 100%;
	height: 100%;
	padding: 5px;
	border: 1px solid #ddd;
	background-color: #f8f8f8;
	box-sizing: border-box;
	display: inline-block;
	font-size: 16px;

	@include mq-min(s) {
		padding: 20px 15px;
	}
}

.right-td .inp-txt textarea {
	border: 1px solid #ddd;
	background-color: #f8f8f8;
	width: 100%;
	height: 100%;
	padding: 5 10px;
	box-sizing: border-box;
	display: inline-block;
	font-size: 16px;
	resize: none;
	min-height: 150px;

	@include mq-min(s) {
		padding: 20px 15px;
		min-height: 300px;
	}
}

.right-td {
	width: 100%;

	@include mq-min(s) {
		width: calc(100% - 260px);
	}

	.selectwrap {
		border: 1px solid #ddd;
		background-color: #f8f8f8;
		width: 280px;
		position: relative;
	}

	.selectwrap::before {
		content: "";
		display: block;
		position: absolute;
		z-index: 2;
		right: 15px;
		bottom: calc(50% - 2px);
		width: 12px;
		height: 12px;
		border: 2px solid;
		border-color: $primary_color $primary_color transparent transparent;
		transform: rotate(135deg);
	}
}

.right-td .selectwrap select {
	width: 100%;
	height: 100%;
	padding: 5px 10px;
	box-sizing: border-box;
	display: inline-block;
	font-size: 16px;

	@include mq-min(s) {
		padding: 20px 15px;
	}
}

.right-td .gradscoolSelect {
	margin-top: 20px;

	@include mq-min(s) {
		display: flex;
	}

	.gradscoolSelect__label {
		font-size: 13px;
		width: 100px;
		margin-bottom: 6px;

		@include mq-min(s) {
			font-size: 16px;
			margin-bottom: 0;
		}
	}
}

.gradscoolSelect {
	.gradscoolSelect__select {
		border: 1px solid #ddd;
		background-color: #f8f8f8;
		width: 280px;
		position: relative;
	}

	.gradscoolSelect__select::before {
		transform: rotate(135deg);
		content: "";
		display: block;
		position: absolute;
		z-index: 2;
		right: 15px;
		bottom: calc(50% - 2px);
		width: 12px;
		height: 12px;
		border: 2px solid;
		border-color: $primary_color $primary_color transparent transparent;
	}
}

.right-td .gradscoolSelect__select select {
	width: 100%;
	height: 100%;
	padding: 5px;
	box-sizing: border-box;
	display: inline-block;
	font-size: 16px;

	@include mq-min(s) {
		padding: 10px;
	}
}

.new-wpContent .form-ls-p {
	text-align: center;
	margin: 40px 0;
}

.form-l-buttonwrap {
	margin-top: 30px;

	@include mq-min(s) {
		display: flex;
		justify-content: center;
		margin-top: 50px;
	}

	& > div {
		margin: 5px 0;
		width: 100%;

		@include mq-min(s) {
			margin: 0 10px;
			max-width: 370px;
		}
	}
}

.right-td .textarea-caption {
	font-size: 16px;
	margin: 30px 0 10px;
}

.right-td .textarea-top {
	font-size: 14px;
	margin-top: 5px;
}

.gradu1rowwrap {
	font-size: 16px;
	margin-top: 16px;

	.gradu1rowInput {
		border: 1px solid #ddd;
		background-color: #f8f8f8;
		width: 70px;
		display: inline-block;
	}

	.gradu1rowInput2 {
		border: 1px solid #ddd;
		background-color: #f8f8f8;
		width: 200px;
		display: inline-block;
	}
}

.gradu1rowwrap .gradu1rowInput input {
	width: 100%;
	height: 100%;
	padding: 1px;
}

.gradu1rowwrap .gradu1rowSelectwrap {
	display: inline-block;
	border: 1px solid #ddd;
	background-color: #f8f8f8;
	width: 120px;
	position: relative;
}

.gradu1rowwrap .gradu1rowSelectwrap::before {
	content: "";
	display: block;
	position: absolute;
	z-index: 2;
	right: 8px;
	bottom: calc(50% - 2px);
	width: 8px;
	height: 8px;
	border: 1px solid;
	border-color: $primary_color $primary_color transparent transparent;
	transform: rotate(135deg);
	pointer-events: none;
}

.gradu1rowwrap .gradu1rowSelectwrap select {
	width: 100%;
	height: 100%;
	padding: 1px;
}

.inputInlineblockWrap {
	font-size: 16px;
}

.inputInlineblockWrap + .inputInlineblockWrap {
	margin-top: 8px;
}

.inputInlineblockWrap .inputInlineblock {
	display: inline-block;
	border: 1px solid #ddd;
	background-color: #f8f8f8;
	width: 180px;
}

.inputInlineblockWrap .inputInlineblock input {
	width: 100%;
	height: 100%;
	padding: 1px;
}

.formgakubuTtl {
	background-color: #eee;
	font-size: 16px;
	font-weight: bold;
	padding: 3px 10px;
	margin-top: 30px;
}

.ttl-underline {
	padding: 6px 0;
	margin-bottom: 6px;
	font-size: 14px;
	border-bottom: 1px dashed #ddd;
	letter-spacing: 0.08em;
}

.formgakkaTtl {
	font-size: 14px;
	margin: 6px 0;
	letter-spacing: 0.08em;
}

div.wpcf7-validation-errors {
	font-size: 16px;
	color: red;
	background-color: #f7e700;
}

div.wpcf7 .screen-reader-response[role*="alert"] {
	position: relative;
	clip: unset;
	height: unset;
	width: unset;
	margin: 80px 0 0;
	padding: 10px;
	border: 2px solid $primary_color;
	text-align: center;
	font-size: 20px;
}

div.wpcf7 .screen-reader-response > ul {
	display: none;
}

div.wpcf7-mail-sent-ok {
	display: none;
}

.hogosyasankaninzuuwrap {
	display: inline-block;
	border: 1px solid #ddd;
	background-color: #f8f8f8;
	width: 80px;
}

.hogosyasankaninzuuwrap input {
	width: 100%;
	padding: 1px;
}

.mw_wp_form_confirm .confirm-none {
	display: none;
}

.mw_wp_form_input .confirm-block {
	display: none;
}

.mw_wp_form_confirm .confirm-block {
	display: block;
}

.mw_wp_form_confirm .inp-txt {
	font-size: 16px;
}

.mw_wp_form .horizontal-item {
	display: inline-block;
	margin: 0 10px 0 0 !important;
	padding: 5px 0;

	@include mq-min(s) {
		padding: 5px 0;
	}
}

.form-captcha {
	margin-top: 30px;
	text-align: center;

	@include mq-min(s) {
		margin-top: 50px;
	}
}
