@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Noto+Serif+JP:wght@500&family=Roboto+Condensed:wght@700&display=swap');

/* setting.scss
--------------------------------------------------------------------------------------- */
@import "mixin", "base";

body {
	position: relative;
	color: $text_color;
	font-family: $jp_font;
	font-weight: 400;
	line-height: 1.5;
	height: 100%;
	width: 100%;
	letter-spacing: 0.05em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@include fontsize(16);

	@include mq-min(s) {
		min-width: 992px;

		@include fontsize(12);
	}

	.modaal-outer-wrapper {
		overflow: hidden;
	}

	.modaal-gallery-prev::before {
		margin: 16px 0 0;
	}

	.modaal-gallery-prev::after {
		margin: -16px 0 0;
	}

	.modaal-gallery-prev-inner {
		margin-right: 30px;
	}

	.modaal-gallery-next::before {
		margin: -16px 0 0;
	}

	.modaal-gallery-next::after {
		margin: 16px 0 0;
	}

	.modaal-gallery-next-inner {
		margin-left: 30px;
	}

	.modaal-gallery-control {
		height: 78px;
	}

	.modaal-gallery-control::before,
	.modaal-gallery-control::after {
		width: 2px;
		height: 46px;
		border-radius: 2px;
	}

	.modaal-gallery-control:focus,
	.modaal-gallery-control:hover {
		background: transparent;

		&::before,
		&::after {
			background: $white_color;
		}
	}

	.modaal-close {
		position: absolute;
		top: -45px;
		right: 0;
		width: 35px;
		height: 35px;

		&::before,
		&::after {
			top: 0;
			left: 17px;
			width: 2px;
			height: 35px;
			border-radius: 2px;
		}

		&:focus,
		&:hover {
			background: transparent;

			&::before,
			&::after {
				background: $white_color;
			}
		}
	}

	@media only screen and (max-width: 1140px) {
		.modaal-gallery-prev {
			left: auto;
			right: calc(100% - 25px);

			&::before {
				margin: 7px 0 0;
			}

			&::after {
				margin: -7px 0 0;
			}
		}

		.modaal-gallery-prev-inner {
			margin-right: 0;
		}

		.modaal-gallery-next {
			left: calc(100% - 25px);
			right: auto;

			&::before {
				margin: -7px 0 0;
			}

			&::after {
				margin: 7px 0 0;
			}
		}

		.modaal-gallery-next-inner {
			margin-left: 0;
		}

		.modaal-gallery-control {
			top: 50%;
			bottom: auto;
			background: rgba($secondary_color, .75);
			height: 50px;
			border-radius: 0;
			transform: translateY(-50%);
		}

		.modaal-gallery-control::before,
		.modaal-gallery-control::after {
			height: 20px;
		}

		.modaal-gallery-control:focus,
		.modaal-gallery-control:hover {
			background: rgba($secondary_color, .75);
		}

		.modaal-close {
			top: -35px;
			width: 25px;
			height: 25px;

			&::before,
			&::after {
				left: 12px;
				height: 25px;
			}
		}
	}

	.modaal-gallery-label {
		display: block;
		margin-top: 15px;

		@include fontsize(12);

		@include mq-min(s) {
			@include fontsize(16);
		}
	}
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: top;
}

a {
	color: $text-color;
}

a[href^="tel:"] {
	cursor: default;
}

// layout
main {
	padding-top: 58px;
	overflow-x: hidden;

	@include mq-min(s) {
		padding-top: 0;
	}
}

.container {
	padding: 0 5.85%;
	width: 100%;

	@include mq-min(s) {
		margin: 0 auto;
		padding: 0 40px;
		max-width: 1280px;
	}

	&.wide {
		@include mq-max(s) {
			padding: 0 3.5%;
		}
	}
}

.container-l {
	padding: 0 5.85%;
	width: 100%;

	@include mq-min(s) {
		margin: 0 auto;
		padding: 0 40px;
		max-width: 1480px;
	}

	&.wide {
		@include mq-max(s) {
			padding: 0 3.5%;
		}
	}
}

.container-xl {
	padding: 0 5.85%;
	width: 100%;

	@include mq-min(s) {
		margin: 0 auto;
		padding: 0 40px;
		max-width: 1680px;
	}

	&.wide {
		@include mq-max(s) {
			padding: 0 3.5%;
		}
	}
}
