.new-wpContent {
	/* ------- sorter ------- */
	.m-sorter {
		padding: 45px 0 0;
		margin-bottom: -20px;

		@include mq-min(s) {
			padding: 120px 0 0;
			margin-bottom: -20px;
		}

		&__inner {
			position: relative;
			display: flex;
			justify-content: flex-end;
		}

		&__block {
			position: relative;
			width: 48.5%;
			max-width: 320px;
			margin-left: 3%;

			@include mq-min(s) {
				width: 370px;
				margin-left: 20px;
			}

			&:first-child {
				margin-left: 0;
			}
		}

		&__box {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $white_color;
			width: 100%;
			height: 48px;
			margin: 0;
			padding-right: 10px;
			cursor: pointer;
			border-radius: 24px;
			box-shadow: 2px 4px 36px 4px rgba(0, 0, 0, 0.08);

			@include fontsize(12);

			@include mq-min(s) {
				height: 80px;
				border-radius: 40px;
				box-shadow: 2px 7px 72px 7px rgba(0, 0, 0, 0.08);

				@include fontsize(16);
			}

			&::before {
				position: absolute;
				top: 0;
				right: 12px;
				bottom: 0;
				width: 6px;
				height: 6px;
				margin: auto;
				border-bottom: 2px solid $text_color;
				border-left: 2px solid $text_color;
				transform: rotate(-45deg);
				content: "";

				@include mq-min(s) {
					top: -4px;
					right: 30px;
					width: 8px;
					height: 8px;
					border-bottom-width: 3px;
					border-left-width: 3px;
				}
			}
		}

		&__list {
			position: absolute;
			top: 58px;
			right: 0;
			z-index: 1;
			display: none;
			margin: 0;
			padding: 5px 15px;
			width: 100%;
			background: $white_color;
			border-radius: 24px;
			box-shadow: 2px 4px 36px 4px rgba(0, 0, 0, 0.08);

			@include mq-min(s) {
				top: 90px;
				padding: 10px 45px;
				border-radius: 40px;
				box-shadow: 2px 7px 72px 7px rgba(0, 0, 0, 0.08);
			}

			& > li {
				width: 100%;

				&:nth-child(n + 2) {
					border-top: 1px solid #ccc;
				}

				&::before {
					display: none;
				}

				a {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					width: 100%;
					height: 48px;
					padding: 5px 0;
					transition: 0.2s;
					font-feature-settings: "palt";

					@include fontsize(12);

					@include mq-min(s) {
						height: 70px;

						@include fontsize(16);
					}

					&:hover {
						@include mq-min(s) {
							color: $primary_color;
						}
					}
				}
			}
		}
	}

	/* ------- pager ------- */
	.wp-pagenavi {
		margin: 30px 0 0;
		display: flex;
		justify-content: center;
		align-items: center;

		@include mq-min(s) {
			margin-top: 60px;
		}

		a,
		span {
			display: flex;
			justify-content: center;
			align-items: center;
			color: $text2_color;
			width: 28px;
			height: 28px;
			margin: 0 3px;
			padding: 0;
			font-family: $en_font;
			border: none;
			border-radius: 50%;
			background: $white_color;

			@include fontsize(12);

			@include mq-min(s) {
				width: 70px;
				height: 70px;
				margin: 0 7px;

				@include fontsize(16);
			}
		}

		a.nextpostslink,
		a.previouspostslink {
			position: relative;

			&::before {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				width: 6px;
				height: 6px;
				border-top: 2px solid $text2_color;
				border-right: 2px solid $text2_color;

				@include mq-min(s) {
					width: 8px;
					height: 8px;
				}
			}
		}

		a.nextpostslink {
			&::before {
				transform: rotate(45deg);
			}
		}

		a.previouspostslink {
			&::before {
				transform: rotate(45deg) scale(-1, -1);
			}
		}

		a.first,
		a.last {
			position: relative;
			background-color: transparent;
			width: 15px;
			height: 15px;

			@include mq-min(s) {
				width: 20px;
				height: 20px;
			}
		}

		a.first {
			&::before,
			&::after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto 0;
				width: 6px;
				height: 6px;
				border-top: 1px solid #111;
				border-right: 1px solid #111;
				transform: rotate(225deg);

				@include mq-min(s) {
					width: 8px;
					height: 8px;
				}
			}

			&::before {
				right: 5px;

				@include mq-min(s) {
					right: 6px;
				}
			}

			&::after {
				right: 2px;

				@include mq-min(s) {
					right: 0;
				}
			}
		}

		a.last {
			&::before,
			&::after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				width: 6px;
				height: 6px;
				border-top: 1px solid #111;
				border-right: 1px solid #111;
				transform: rotate(45deg);

				@include mq-min(s) {
					width: 8px;
					height: 8px;
				}
			}

			&::before {
				left: 2px;

				@include mq-min(s) {
					left: 0;
				}
			}

			&::after {
				left: 5px;

				@include mq-min(s) {
					left: 6px;
				}
			}
		}
	}

	.news-pageback {
		margin: 30px 0 0;
		display: flex;
		justify-content: center;
		align-items: center;

		@include mq-min(s) {
			margin-top: 60px;
		}
	}

	.entry-title {
		position: relative;
		border-bottom: 1px solid #ccc;
		margin: 15px 0 40px;
		padding: 0 0 15px;
		font-weight: 700;
		text-align: center;
		letter-spacing: 0.08em;

		@include fontsize(22);

		@include mq-min(s) {
			border-bottom-width: 2px;
			margin: 30px 0 75px;
			padding: 0 0 30px;
			line-height: 2;

			@include fontsize(36);
		}
	}
}
