@charset "UTF-8";
/* _reset.scss
--------------------------------------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Noto+Serif+JP:wght@500&family=Roboto+Condensed:wght@700&display=swap");
@import '~aos/dist/aos.css';
html {
  box-sizing: border-box;
  overflow-y: scroll;
  /* All browsers without overlaying scrollbars */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  /* iOS 8+ */
}

*,
::before,
::after {
  background-repeat: no-repeat;
  /* Set `background-repeat: no-repeat` to all elements and pseudo elements */
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit;
  /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}

article, aside, dialog, figure, footer, header,
hgroup, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th {
  text-align: left;
}

q::before, q::after {
  content: "";
}

object, embed {
  vertical-align: top;
}

hr, legend {
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
}

img, abbr, acronym, fieldset {
  border: 0;
}

ul, ol {
  list-style-type: none;
}

/* link
--------------------------------------------------------------------------------------- */
a {
  background-color: transparent;
  /* Remove the gray background on active links in IE 10 */
  -webkit-text-decoration-skip: objects;
  /* Remove gaps in links underline in iOS 8+ and Safari 8+ */
  text-decoration: none;
}

a:active,
a:hover {
  outline-width: 0;
  /* Remove the outline when hovering in all browsers */
}

a:hover {
  text-decoration: none;
}

/* sub sup
--------------------------------------------------------------------------------------- */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* form
--------------------------------------------------------------------------------------- */
input {
  border-radius: 0;
}

/* Apply cursor pointer to button elements */
button,
[type=button],
[type=reset],
[type=submit],
[role=button] {
  cursor: pointer;
}

/* Replace pointer cursor in disabled elements */
[disabled] {
  cursor: default;
}

[type=number] {
  width: auto;
  /* Firefox 36+ */
}

[type=search] {
  -webkit-appearance: textfield;
  /* Safari 8+ */
  outline-offset: -2px;
  /* Correct the outline style in Safari */
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
  /* Safari 8 */
}

textarea {
  overflow: auto;
  /* Internet Explorer 11+ */
  resize: vertical;
  /* Specify textarea resizability */
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  /* Specify font inheritance of form elements */
}

optgroup {
  font-weight: bold;
  /* Restore the font weight unset by the previous rule. */
}

button {
  overflow: visible;
  /* Address `overflow` set to `hidden` in IE 8/9/10/11 */
}

/* Remove inner padding and border in Firefox 4+ */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}

/* Replace focus style removed in the border reset above */
button:-moz-focusring,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  /* Correct the inability to style clickable types in iOS */
}

button,
select {
  text-transform: none;
  /* Firefox 40+, Internet Explorer 11- */
}

/* Remove the default button styling in all browsers */
button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
}

/* Style select like a standard input */
select {
  -moz-appearance: none;
  /* Firefox 36+ */
  -webkit-appearance: none;
  /* Chrome 41+ */
}

select::-ms-expand {
  display: none;
  /* Internet Explorer 11+ */
}

select::-ms-value {
  color: currentColor;
  /* Internet Explorer 11+ */
}

legend {
  border: 0;
  /* Correct `color` not being inherited in IE 8/9/10/11 */
  color: inherit;
  /* Correct the color inheritance from `fieldset` elements in IE */
  display: table;
  /* Correct the text wrapping in Edge and IE */
  max-width: 100%;
  /* Correct the text wrapping in Edge and IE */
  white-space: normal;
  /* Correct the text wrapping in Edge and IE */
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* Correct the inability to style clickable types in iOS and Safari */
  font: inherit;
  /* Change font properties to `inherit` in Chrome and Safari */
}

/* setting.scss
--------------------------------------------------------------------------------------- */
body {
  position: relative;
  color: #222;
  font-family: "Noto Sans JP", "Hiragino Sans", YuGothic, "Yu Gothic", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  height: 100%;
  width: 100%;
  letter-spacing: 0.05em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  body {
    min-width: 992px;
    font-size: 12px;
    font-size: 0.75rem;
  }
}
body .modaal-outer-wrapper {
  overflow: hidden;
}
body .modaal-gallery-prev::before {
  margin: 16px 0 0;
}
body .modaal-gallery-prev::after {
  margin: -16px 0 0;
}
body .modaal-gallery-prev-inner {
  margin-right: 30px;
}
body .modaal-gallery-next::before {
  margin: -16px 0 0;
}
body .modaal-gallery-next::after {
  margin: 16px 0 0;
}
body .modaal-gallery-next-inner {
  margin-left: 30px;
}
body .modaal-gallery-control {
  height: 78px;
}
body .modaal-gallery-control::before,
body .modaal-gallery-control::after {
  width: 2px;
  height: 46px;
  border-radius: 2px;
}
body .modaal-gallery-control:focus,
body .modaal-gallery-control:hover {
  background: transparent;
}
body .modaal-gallery-control:focus::before, body .modaal-gallery-control:focus::after,
body .modaal-gallery-control:hover::before,
body .modaal-gallery-control:hover::after {
  background: #fff;
}
body .modaal-close {
  position: absolute;
  top: -45px;
  right: 0;
  width: 35px;
  height: 35px;
}
body .modaal-close::before, body .modaal-close::after {
  top: 0;
  left: 17px;
  width: 2px;
  height: 35px;
  border-radius: 2px;
}
body .modaal-close:focus, body .modaal-close:hover {
  background: transparent;
}
body .modaal-close:focus::before, body .modaal-close:focus::after, body .modaal-close:hover::before, body .modaal-close:hover::after {
  background: #fff;
}
@media only screen and (max-width: 1140px) {
  body .modaal-gallery-prev {
    left: auto;
    right: calc(100% - 25px);
  }
  body .modaal-gallery-prev::before {
    margin: 7px 0 0;
  }
  body .modaal-gallery-prev::after {
    margin: -7px 0 0;
  }
  body .modaal-gallery-prev-inner {
    margin-right: 0;
  }
  body .modaal-gallery-next {
    left: calc(100% - 25px);
    right: auto;
  }
  body .modaal-gallery-next::before {
    margin: -7px 0 0;
  }
  body .modaal-gallery-next::after {
    margin: 7px 0 0;
  }
  body .modaal-gallery-next-inner {
    margin-left: 0;
  }
  body .modaal-gallery-control {
    top: 50%;
    bottom: auto;
    background: rgba(196, 68, 5, 0.75);
    height: 50px;
    border-radius: 0;
    transform: translateY(-50%);
  }
  body .modaal-gallery-control::before,
body .modaal-gallery-control::after {
    height: 20px;
  }
  body .modaal-gallery-control:focus,
body .modaal-gallery-control:hover {
    background: rgba(196, 68, 5, 0.75);
  }
  body .modaal-close {
    top: -35px;
    width: 25px;
    height: 25px;
  }
  body .modaal-close::before, body .modaal-close::after {
    left: 12px;
    height: 25px;
  }
}
body .modaal-gallery-label {
  display: block;
  margin-top: 15px;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  body .modaal-gallery-label {
    font-size: 16px;
    font-size: 1rem;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

a {
  color: #222;
}

a[href^="tel:"] {
  cursor: default;
}

main {
  padding-top: 58px;
  overflow-x: hidden;
}
@media screen and (min-width: 768px) {
  main {
    padding-top: 0;
  }
}

.container {
  padding: 0 5.85%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .container {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1280px;
  }
}
@media screen and (max-width: 767px) {
  .container.wide {
    padding: 0 3.5%;
  }
}

.container-l {
  padding: 0 5.85%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .container-l {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1480px;
  }
}
@media screen and (max-width: 767px) {
  .container-l.wide {
    padding: 0 3.5%;
  }
}

.container-xl {
  padding: 0 5.85%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .container-xl {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1680px;
  }
}
@media screen and (max-width: 767px) {
  .container-xl.wide {
    padding: 0 3.5%;
  }
}

@media screen and (min-width: 768px) {
  [data-aos-delay-item]:nth-of-type(1) {
    transition-delay: 0.15s;
  }
}

@media screen and (min-width: 768px) {
  [data-aos-delay-item]:nth-of-type(2) {
    transition-delay: 0.3s;
  }
}

@media screen and (min-width: 768px) {
  [data-aos-delay-item]:nth-of-type(3) {
    transition-delay: 0.45s;
  }
}

@media screen and (min-width: 768px) {
  [data-aos-delay-item]:nth-of-type(4) {
    transition-delay: 0.6s;
  }
}

@media screen and (min-width: 768px) {
  [data-aos-delay-item]:nth-of-type(5) {
    transition-delay: 0.75s;
  }
}

@media screen and (min-width: 768px) {
  [data-aos-delay-item]:nth-of-type(6) {
    transition-delay: 0.9s;
  }
}

@media screen and (min-width: 768px) {
  [data-aos-delay-item]:nth-of-type(7) {
    transition-delay: 1.05s;
  }
}

@media screen and (min-width: 768px) {
  [data-aos-delay-item]:nth-of-type(8) {
    transition-delay: 1.2s;
  }
}

html:not(.no-js) [data-aos=zoom-in] {
  transform: scale(0.85);
}

html:not(.no-js) [data-aos=fade-up] {
  transform: translate3d(0, 30px, 0);
}

/* header.scss
--------------------------------------------------------------------------------------- */
.g-header {
  position: fixed;
  z-index: 75;
  width: 100%;
  min-width: 100%;
}
@media screen and (min-width: 768px) {
  .g-header {
    min-width: 1000px;
  }
}
@media screen and (min-width: 1281px) {
  .g-header {
    position: absolute;
    padding-top: 34px;
  }
}
@media screen and (min-width: 1281px) {
  .g-header.fixed {
    position: fixed;
    top: -34px;
  }
}
.g-header-top {
  position: relative;
  background: #fff;
  height: 58px;
}
@media screen and (min-width: 768px) {
  .g-header-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
  }
}
@media screen and (min-width: 1281px) {
  .g-header-top {
    background: rgba(221, 75, 3, 0.8);
    top: 34px;
  }
}
.g-header-top .logo {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 3.5%;
  width: 190px;
  transform: translateY(-50%);
}
@media screen and (min-width: 768px) {
  .g-header-top .logo {
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 300px;
    height: 100%;
    transform: translateY(0);
  }
}
@media screen and (min-width: 992px) {
  .g-header-top .logo {
    width: 330px;
  }
}
.g-header-top .logo img {
  width: 180px;
}
@media screen and (min-width: 768px) {
  .g-header-top .logo img {
    width: 282px;
  }
}
.g-header .gnav {
  position: relative;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
@media screen and (min-width: 1281px) {
  .g-header .gnav {
    position: static;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}
.g-header .gnav.js-open {
  position: fixed;
  z-index: 100;
  top: 58px;
  left: 0;
  width: 100%;
  height: calc(100% - 55px);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
@media screen and (min-width: 768px) {
  .g-header .gnav.js-open {
    top: 75px;
    min-width: 1000px;
    height: calc(100% - 75px);
  }
}
.g-header .gnav.js-open .gnav-wrap {
  opacity: 1;
  visibility: visible;
  overflow: auto;
}
.g-header .gnav-wrap {
  position: absolute;
  left: 0;
  background: rgba(196, 68, 5, 0.9);
  padding-bottom: 85px;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
}
@media screen and (min-width: 1281px) {
  .g-header .gnav-wrap {
    position: static;
    background: transparent;
    padding-bottom: 0;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transition: none;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav-top {
    position: absolute;
    top: 34px;
    right: 0;
    padding: 0 192px 0 300px;
    width: 100%;
    height: 75px;
  }
}
@media screen and (min-width: 1481px) {
  .g-header .gnav-top {
    padding: 0 150px 0 350px;
  }
}
.g-header .gnav__list {
  display: block;
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__list {
    display: flex;
    justify-content: flex-end;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
    width: 100%;
    height: 100%;
  }
}
.g-header .gnav__item {
  position: relative;
  border-bottom: 1px solid #fff;
  line-height: 1.25;
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__item {
    position: static;
    border-left: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: none;
  }
}
@media screen and (min-width: 768px) {
  .g-header .gnav__item:hover .gnav__text {
    opacity: 0.7;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__item:hover .gnav__text {
    opacity: 1;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__item:hover .gnav__text::before {
    opacity: 1;
    transition: all 0.3s ease;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__item:hover .gnav__second-list-wrap {
    z-index: 1;
    height: auto;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__item:hover .gnav__second-list-wrap::before {
    opacity: 1;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__item:hover .gnav__second-list {
    padding: 55px 40px 50px;
  }
}
.g-header .gnav__item .switch {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  width: 44px;
  height: 50px;
}
@media screen and (min-width: 768px) {
  .g-header .gnav__item .switch {
    height: 60px;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__item .switch {
    display: none;
  }
}
.g-header .gnav__item .switch::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 13px;
  background: #fff;
  width: 17px;
  height: 2px;
}
@media screen and (min-width: 768px) {
  .g-header .gnav__item .switch::before {
    right: 20px;
  }
}
.g-header .gnav__item .switch::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  background: #fff;
  margin-top: -7px;
  width: 2px;
  height: 17px;
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .g-header .gnav__item .switch::after {
    right: 27px;
  }
}
.g-header .gnav__item .switch.active::after {
  opacity: 0;
  transform: rotate(-90deg);
}
.g-header .gnav__text {
  position: relative;
  z-index: 2;
  display: block;
  color: #fff;
  padding: 0 30px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0.1em;
  white-space: nowrap;
  transition: all 0.2s ease;
  font-size: 14px;
  font-size: 0.875rem;
}
@media screen and (min-width: 768px) {
  .g-header .gnav__text {
    line-height: 60px;
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__text {
    padding: 0 20px;
    min-width: 104px;
    text-align: center;
    line-height: 75px;
    letter-spacing: 0.08em;
  }
}
@media screen and (min-width: 1481px) {
  .g-header .gnav__text {
    padding: 0 30px;
    min-width: 124px;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__text::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 4px;
    opacity: 0;
    transition: 0;
  }
}
.g-header .gnav__second-list-wrap {
  position: relative;
  z-index: 1;
  display: none;
  background: #dd4b03;
  padding: 10px 0;
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__second-list-wrap {
    position: absolute;
    z-index: -100;
    top: 100%;
    left: 0;
    display: block;
    background: rgba(196, 68, 5, 0.9);
    padding: 0;
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__second-list-wrap::before {
    content: "";
    position: fixed;
    z-index: -1;
    top: 34px;
    left: 0;
    width: 100%;
    height: 75px;
    opacity: 0;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__second-list {
    margin: 0 auto;
    padding: 60px 40px 55px;
    max-width: 1680px;
    transition: all 0.3s ease-out;
    display: flex;
    flex-wrap: wrap;
  }
}
.g-header .gnav__second-item {
  position: relative;
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__second-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.45);
    margin: 0 0 20px 2.5%;
    padding: 0 0 0 25px;
    width: 23.125%;
  }
}
@media screen and (min-width: 1481px) {
  .g-header .gnav__second-item {
    padding: 0 0 0 35px;
  }
}
@media screen and (min-width: 768px) {
  .g-header .gnav__second-item:nth-child(4n+1) {
    margin-left: 0;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__second-item::before {
    content: "";
    position: absolute;
    top: calc(0.7em + 20px);
    left: 0;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    margin: auto;
    width: 9px;
    height: 9px;
    transform: rotate(45deg) translate3d(0, 0, 0);
  }
}
.g-header .gnav__second-item .switch2 {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  width: 44px;
  height: 40px;
}
@media screen and (min-width: 768px) {
  .g-header .gnav__second-item .switch2 {
    height: 50px;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__second-item .switch2 {
    display: none;
  }
}
.g-header .gnav__second-item .switch2::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 13px;
  background: #fff;
  width: 17px;
  height: 2px;
}
@media screen and (min-width: 768px) {
  .g-header .gnav__second-item .switch2::before {
    right: 20px;
  }
}
.g-header .gnav__second-item .switch2::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  background: #fff;
  margin-top: -7px;
  width: 2px;
  height: 17px;
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .g-header .gnav__second-item .switch2::after {
    right: 27px;
  }
}
.g-header .gnav__second-item .switch2.active::after {
  opacity: 0;
  transform: rotate(-90deg);
}
.g-header .gnav__second-text {
  position: relative;
  display: block;
  color: #fff;
  margin-left: 30px;
  padding: 0 15px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.1em;
  white-space: nowrap;
  transition: all 0.2s ease;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .g-header .gnav__second-text {
    line-height: 50px;
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__second-text {
    margin-left: 0;
    padding: 20px 0;
    line-height: 1.5;
    letter-spacing: 0.08em;
    font-size: 16px;
    font-size: 1rem;
  }
}
@media screen and (min-width: 1481px) {
  .g-header .gnav__second-text {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.g-header .gnav__second-text.text-link::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 18px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  margin: auto;
  width: 10px;
  height: 10px;
  transform: rotate(45deg) translate3d(0, 0, 0);
}
@media screen and (min-width: 768px) {
  .g-header .gnav__second-text.text-link::before {
    right: 25px;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__second-text.text-link::before {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .g-header .gnav__second-text:hover {
    opacity: 0.7;
  }
}
.g-header .gnav__third-list-wrap {
  position: relative;
  z-index: 1;
  display: none;
  background: #9d0e30;
  padding: 5px 0;
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__third-list-wrap {
    background: transparent;
    display: block;
    margin: 5px 0 25px;
    padding: 0;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__third-item:nth-child(n+2) {
    margin-top: 10px;
  }
}
.g-header .gnav__third-text {
  position: relative;
  display: block;
  color: #fff;
  margin-left: 42px;
  padding: 0 15px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.1em;
  white-space: nowrap;
  transition: all 0.2s ease;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .g-header .gnav__third-text {
    line-height: 50px;
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__third-text {
    margin-left: 0;
    padding: 5px 0;
    line-height: 1.3;
    letter-spacing: 0.08em;
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
.g-header .gnav__third-text::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 18px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  margin: auto;
  width: 10px;
  height: 10px;
  transform: rotate(45deg) translate3d(0, 0, 0);
}
@media screen and (min-width: 768px) {
  .g-header .gnav__third-text::before {
    right: 25px;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gnav__third-text::before {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .g-header .gnav__third-text:hover {
    opacity: 0.7;
  }
}
.g-header .gnav-bottom {
  position: relative;
}
@media screen and (min-width: 1281px) {
  .g-header .gnav-bottom {
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    width: 100%;
    height: 34px;
  }
}
.g-header .gnav-aside {
  position: relative;
}
@media screen and (min-width: 1281px) {
  .g-header .gnav-aside {
    display: none;
  }
}
.g-header .gnav-aside__list {
  display: flex;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.g-header .gnav-aside__item {
  border-right: 1px solid #fff;
  width: 5000%;
}
.g-header .gnav-aside__item:last-child {
  border-right: none;
}
.g-header .gnav-aside__item a {
  display: block;
  padding: 16px 5px;
  height: 100%;
}
.g-header .gnav-aside__item a > span {
  display: block;
  text-align: center;
}
.g-header .gnav-aside__item a > span img {
  width: 28px;
}
@media screen and (min-width: 768px) {
  .g-header .gnav-aside__item a > span img {
    width: 36px;
  }
}
.g-header .gnav-aside__item a > span .text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-top: 5px;
  min-height: 2.75em;
  line-height: 1.4166666667;
  letter-spacing: 0;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .g-header .gnav-aside__item a > span .text {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.g-header .gsubnav {
  padding: 25px 30px;
}
@media screen and (min-width: 768px) {
  .g-header .gsubnav {
    padding: 35px 30px;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gsubnav {
    padding: 0;
  }
}
.g-header .gsubnav__list {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .g-header .gsubnav__list {
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gsubnav__list {
    justify-content: flex-end;
    height: 34px;
  }
}
.g-header .gsubnav__item {
  position: relative;
  margin: 7px 0;
  padding-left: 15px;
  width: 50%;
}
@media screen and (min-width: 768px) {
  .g-header .gsubnav__item {
    margin: 0 24px;
    padding-left: 20px;
    width: auto;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gsubnav__item {
    padding-left: 0;
  }
}
.g-header .gsubnav__item::before {
  content: "";
  position: absolute;
  top: 0.7em;
  left: 0;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  margin: auto;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translate3d(0, 0, 0);
}
@media screen and (min-width: 768px) {
  .g-header .gsubnav__item::before {
    top: 0.6em;
  }
}
@media screen and (min-width: 1281px) {
  .g-header .gsubnav__item::before {
    display: none;
  }
}
.g-header .gsubnav__item a {
  color: #fff;
  font-weight: 500;
  transition: all 0.2s ease;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .g-header .gsubnav__item a {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media screen and (min-width: 768px) {
  .g-header .gsubnav__item a:hover {
    opacity: 0.7;
  }
}

#gnav__map {
  display: none;
}
@media screen and (min-width: 1281px) {
  #gnav__map {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 96px;
    background: #c44405;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 75px;
    cursor: pointer;
  }
}
@media screen and (min-width: 1481px) {
  #gnav__map {
    right: 75px;
    width: 75px;
  }
}
#gnav__map .icon-pin {
  display: block;
  text-align: center;
}
@media screen and (min-width: 768px) {
  #gnav__map .icon-pin img {
    width: 32px;
  }
}
#gnav__map .icon-pin .text {
  display: none;
}
@media screen and (min-width: 768px) {
  #gnav__map .icon-pin .text {
    display: block;
    color: #fff;
    margin-top: 5px;
    line-height: 1;
    font-size: 14px;
    font-size: 0.875rem;
  }
}

#gnav__search {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 58px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  #gnav__search {
    right: 96px;
    width: 96px;
    height: 75px;
  }
}
@media screen and (min-width: 1281px) {
  #gnav__search {
    right: 0;
  }
}
@media screen and (min-width: 1481px) {
  #gnav__search {
    width: 75px;
  }
}
#gnav__search .icon-search {
  display: block;
  text-align: center;
}
#gnav__search .icon-search img {
  width: 28px;
}
@media screen and (min-width: 768px) {
  #gnav__search .icon-search img {
    width: 32px;
  }
}
#gnav__search .icon-search .text {
  display: none;
}
@media screen and (min-width: 1281px) {
  #gnav__search .icon-search .text {
    display: block;
    color: #fff;
    margin-top: 5px;
    padding-left: 0.3em;
    line-height: 1;
    letter-spacing: 0.3em;
    font-size: 14px;
    font-size: 0.875rem;
  }
}
#gnav__search .icon-close {
  position: relative;
  display: none;
  width: 22px;
  height: 19px;
}
@media screen and (min-width: 768px) {
  #gnav__search .icon-close {
    width: 30px;
    height: 30px;
  }
}
#gnav__search .icon-close::before, #gnav__search .icon-close::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #fff;
  margin: 0 auto;
  width: 100%;
  height: 2px;
}
#gnav__search .icon-close::before {
  transform: translateY(-50%) rotate(45deg);
}
#gnav__search .icon-close::after {
  transform: translateY(-50%) rotate(-45deg);
}
#gnav__search.active .icon-search {
  display: none;
}
#gnav__search.active .icon-close {
  display: block;
}

#gnav__search-form {
  position: absolute;
  z-index: 105;
  top: 100%;
  right: 0;
  display: none;
  background: transparent;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
}
@media screen and (min-width: 768px) {
  #gnav__search-form {
    max-width: 671px;
  }
}
#gnav__search-form .gsc-search-box .gsc-input {
  padding-right: 0;
}
#gnav__search-form .gsc-input-box {
  margin-right: -5px;
  padding: 0;
  border: 5px solid #f5f4f3;
  border-radius: 0;
}
#gnav__search-form .gsib_a {
  display: flex;
  align-items: center;
  padding: 5px 50px 5px 3.5%;
  height: 48px;
}
@media screen and (min-width: 768px) {
  #gnav__search-form .gsib_a {
    padding: 5px 80px 5px 30px;
    height: 65px;
  }
}
#gnav__search-form .gsst_b {
  position: absolute;
  top: 50%;
  right: 15px;
  padding: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  transform: translateY(-50%);
}
#gnav__search-form .gsst_a {
  padding: 2px 4px;
  width: 100%;
  height: 100%;
}
#gnav__search-form .gsc-input {
  position: relative;
  background-position: 0 0.25em !important;
  font-weight: 500;
  letter-spacing: 0.08em;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  #gnav__search-form .gsc-input {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
#gnav__search-form .gsc-search-button-v2 {
  position: relative;
  background: #f5f4f3;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  width: 58px;
  height: 58px;
  outline: none;
}
@media screen and (min-width: 768px) {
  #gnav__search-form .gsc-search-button-v2 {
    width: 75px;
    height: 75px;
  }
}
#gnav__search-form .gsc-search-button-v2::before {
  content: "";
  display: block;
  background-image: url(../img/common/icon_search.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
  width: 28px;
  height: 28px;
}
@media screen and (min-width: 768px) {
  #gnav__search-form .gsc-search-button-v2::before {
    width: 32px;
    height: 32px;
  }
}
#gnav__search-form .gsc-search-button-v2 svg {
  display: none;
}
#gnav__search-form.show {
  pointer-events: all;
}

.btn-menu {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  background: #dd4b03;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  outline: none;
}
@media screen and (min-width: 768px) {
  .btn-menu {
    width: 96px;
    height: 75px;
  }
}
@media screen and (min-width: 1281px) {
  .btn-menu {
    display: none;
  }
}
.btn-menu span {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 23px;
  height: 17px;
  z-index: 10;
}
.btn-menu span i {
  position: absolute;
  left: 0;
  background-color: #fff;
  margin: 0 auto;
  width: 100%;
  height: 1px;
  transition: all 0.3s ease;
}
.btn-menu span i:nth-last-of-type(1) {
  top: 0;
  transform: translate3d(0, 0, 0);
}
.btn-menu span i:nth-last-of-type(2) {
  top: 8px;
  width: 18px;
}
.btn-menu span i:nth-last-of-type(3) {
  top: auto;
  bottom: 0;
  transform: translate3d(0, 0, 0);
}
.btn-menu.js-open span i:nth-last-of-type(1) {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.btn-menu.js-open span i:nth-last-of-type(2) {
  width: 0;
  visibility: hidden;
  opacity: 0;
}
.btn-menu.js-open span i:nth-last-of-type(3) {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

/* footer.scss
--------------------------------------------------------------------------------------- */
.g-footer {
  position: relative;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .g-footer .container {
    padding: 0;
  }
}
.g-footer-top {
  position: relative;
  background: #dd4b03;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .g-footer-top {
    padding: 30px 0;
  }
}
.g-footer-school .list {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .g-footer-school .list {
    flex-wrap: nowrap;
  }
}
.g-footer-school .item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  width: 50%;
}
@media screen and (min-width: 768px) {
  .g-footer-school .item {
    border-bottom: none;
    margin-left: 3.334%;
    width: auto;
  }
}
@media screen and (min-width: 768px) {
  .g-footer-school .item:first-child {
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .g-footer-school .item:nth-child(odd) {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }
}
@media screen and (max-width: 767px) {
  .g-footer-school .item:last-child {
    border-bottom: none;
  }
}
.g-footer-school .item a {
  position: relative;
  display: block;
  color: #fff;
  padding: 14px 12px;
  font-weight: 500;
  line-height: 1.8;
  transition: all 0.2s ease;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .g-footer-school .item a {
    display: inline;
    padding: 0;
    font-size: 16px;
    font-size: 1rem;
  }
}
.g-footer-school .item a[target=_blank]::after {
  content: "";
  display: inline-block;
  background-image: url(../img/common/icon_window2_w.svg);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5px;
  width: 10px;
  height: 10px;
}
@media screen and (min-width: 768px) {
  .g-footer-school .item a[target=_blank]::after {
    margin-left: 7px;
    width: 14px;
    height: 14px;
  }
}
@media screen and (min-width: 768px) {
  .g-footer-school .item a:hover {
    opacity: 0.7;
  }
}
.g-footer-middle {
  position: relative;
  padding: 30px 0;
}
@media screen and (min-width: 768px) {
  .g-footer-middle {
    padding: 75px 0;
  }
}
.g-footer-middle .social {
  display: flex;
  justify-content: center;
  align-items: center;
}
.g-footer-middle .social a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dd4b03;
  color: #fff;
  width: 35px;
  height: 35px;
  margin: 0 5px;
  overflow: hidden;
  border-radius: 50%;
  letter-spacing: -0.035em;
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .g-footer-middle .social a {
    margin: 0 7px;
    width: 50px;
    height: 50px;
  }
}
.g-footer-middle .social a i {
  font-size: 15px;
  font-size: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .g-footer-middle .social a i {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}
@media screen and (min-width: 768px) {
  .g-footer-middle .social a:hover {
    background: rgba(221, 75, 3, 0.7);
  }
}
.g-footer-logo {
  margin-bottom: 10px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .g-footer-logo {
    margin-bottom: 30px;
  }
}
.g-footer-logo img {
  width: 202px;
}
@media screen and (min-width: 768px) {
  .g-footer-logo img {
    width: 369px;
  }
}
.g-footer-text {
  color: #222;
  margin: 0 auto;
  max-width: 300px;
  text-align: center;
  line-height: 1.5833333333;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .g-footer-text {
    max-width: 100%;
    line-height: 1.5;
    font-size: 16px;
    font-size: 1rem;
  }
}
.g-footer-text span {
  display: inline-block;
}
.g-footer-text .post {
  margin-right: 1em;
}
@media screen and (min-width: 768px) {
  .g-footer-text .address {
    margin-right: 2em;
  }
}
.g-footer-bottom {
  position: relative;
  background: #f2f1ef;
  padding: 20px 0 0;
}
@media screen and (min-width: 768px) {
  .g-footer-bottom {
    padding: 25px 0;
  }
}
.g-footer-bottom .container {
  position: relative;
}
.g-footer-nav {
  margin: auto;
  max-width: 250px;
}
@media screen and (min-width: 768px) {
  .g-footer-nav {
    max-width: 100%;
  }
}
.g-footer-nav .list {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .g-footer-nav .list {
    justify-content: flex-start;
  }
}
.g-footer-nav .item {
  position: relative;
  margin: 5px 12px;
}
@media screen and (min-width: 768px) {
  .g-footer-nav .item {
    margin: 0 0 0 31px;
  }
}
@media screen and (min-width: 1081px) {
  .g-footer-nav .item {
    margin-left: 41px;
  }
}
@media screen and (min-width: 768px) {
  .g-footer-nav .item:first-child {
    margin-left: 0;
  }
}
.g-footer-nav .item:first-child::before {
  display: none;
}
@media screen and (max-width: 767px) {
  .g-footer-nav .item:nth-of-type(3)::before {
    display: none;
  }
}
.g-footer-nav .item::before {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 0;
  left: -12px;
  background: #cdccc9;
  margin: auto;
  width: 1px;
  height: 10px;
}
@media screen and (min-width: 768px) {
  .g-footer-nav .item::before {
    top: 3px;
    left: -16px;
    width: 1px;
    height: 14px;
  }
}
@media screen and (min-width: 1081px) {
  .g-footer-nav .item::before {
    left: -21px;
  }
}
.g-footer-nav .item a {
  position: relative;
  color: #919191;
  font-weight: 500;
  transition: all 0.2s ease;
  font-size: 11px;
  font-size: 0.6875rem;
}
@media screen and (min-width: 768px) {
  .g-footer-nav .item a {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media screen and (min-width: 768px) {
  .g-footer-nav .item a:hover {
    opacity: 0.7;
  }
}
.g-footer .copyright {
  color: #cdccc9;
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
  letter-spacing: 0.08em;
  font-size: 10px;
  font-size: 0.625rem;
}
@media screen and (max-width: 767px) {
  .g-footer .copyright {
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    height: 64px;
  }
}
@media screen and (min-width: 768px) {
  .g-footer .copyright {
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 767px) {
  .g-footer .copyright::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: calc((100vw - 100%) / -2);
    background: #fff;
    width: calc((100vw - 100%) / 2 + 100%);
    height: 100%;
  }
}

.pagetop {
  position: absolute;
  z-index: 25;
  right: 12px;
  bottom: 42px;
  width: 45px;
  height: 45px;
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .pagetop {
    right: 0;
    top: 0;
    bottom: auto;
    width: 88px;
    height: 88px;
  }
}
.pagetop a {
  position: relative;
  display: block;
  background: #222;
  color: #fff;
  width: 100%;
  height: 100%;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.pagetop a::before {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  margin: auto;
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .pagetop a::before {
    top: 10px;
    width: 15px;
    height: 15px;
  }
}
@media screen and (min-width: 768px) {
  .pagetop a:hover::before {
    top: 5px;
  }
}

.side-fixed-bnr {
  display: none;
}
@media screen and (min-width: 992px) {
  .side-fixed-bnr {
    position: fixed;
    z-index: 50;
    bottom: 20px;
    right: 30px;
    display: block;
  }
}
.side-fixed-bnr__item.book a {
  height: 90px;
}
.side-fixed-bnr__item.book a span .text {
  font-size: 24px;
  font-size: 1.5rem;
}
.side-fixed-bnr__item.campus {
  margin-top: 30px;
}
.side-fixed-bnr__item.campus a {
  height: 90px;
}
.side-fixed-bnr__item.campus a span .text {
  font-size: 14px;
  font-size: 0.875rem;
}
.side-fixed-bnr__item a {
  position: relative;
  display: flex;
  align-items: center;
  background: #dd4b03;
  border-left: 4px solid #fff;
  padding-left: 30px;
  width: 270px;
  transition: all 0.2s ease;
}
.side-fixed-bnr__item a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  margin: auto;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .side-fixed-bnr__item a::before {
    right: 20px;
    border-top-width: 3px;
    border-right-width: 3px;
    width: 9px;
    height: 9px;
  }
}
.side-fixed-bnr__item a span {
  display: flex;
  align-items: center;
}
.side-fixed-bnr__item a span img {
  width: 35px;
}
.side-fixed-bnr__item a span .text {
  color: #fff;
  margin-left: 20px;
  font-weight: 700;
  text-align: left;
  line-height: 1.6;
  letter-spacing: 0.15em;
}
@media screen and (min-width: 768px) {
  .side-fixed-bnr__item a:hover {
    background: #fc6317;
  }
}
.side-fixed-bnr__item a:hover::before {
  right: 17px;
}

/* module.scss
--------------------------------------------------------------------------------------- */
.categoryNews-sec {
  position: relative;
  padding: 50px 0 35px;
}
@media screen and (min-width: 768px) {
  .categoryNews-sec {
    padding: 100px 0 65px;
  }
}
.categoryNews-sec.activity-news {
  padding: 20px 0 15px;
}
@media screen and (min-width: 768px) {
  .categoryNews-sec.activity-news {
    padding: 50px 0 15px;
  }
}
.categoryNews-sec__inner {
  position: relative;
  z-index: 0;
  display: flex;
  padding: 1px 5.85% 25px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .categoryNews-sec__inner {
    margin: 0 auto;
    padding: 1px 40px 50px;
    max-width: 1680px;
  }
}
.categoryNews-sec__inner::before {
  content: "";
  flex: none;
  display: block;
  width: 7%;
}
@media screen and (min-width: 768px) {
  .categoryNews-sec__inner::before {
    width: 40px;
  }
}
@media screen and (min-width: 1331px) {
  .categoryNews-sec__inner::before {
    display: none;
  }
}
.categoryNews-sec__inner::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 5.85%;
  background: #fff;
  margin-right: calc((100vw - 100%) / -2);
  width: calc((100vw - 100%) / 2 + 100% - 5.85%);
  height: 100%;
}
@media screen and (min-width: 768px) {
  .categoryNews-sec__inner::after {
    left: 40px;
    margin-right: 0;
    width: calc(100% - 40px);
  }
}
@media screen and (min-width: 992px) {
  .categoryNews-sec__inner::after {
    margin-right: calc((100vw - 100%) / -2);
    width: calc((100vw - 100%) / 2 + 100% - 40px);
  }
}
.categoryNews-sec .container {
  padding: 0;
  max-width: 1200px;
}
.categoryNews-sec h3 {
  margin-bottom: 0 !important;
}
@media screen and (min-width: 768px) {
  .categoryNews-sec h3 {
    margin-bottom: 0 !important;
  }
}
.categoryNews-sec__list {
  margin: 0 !important;
}
.categoryNews-sec__item {
  border-bottom: 1px solid #ccc;
  padding: 25px 0 !important;
}
@media screen and (min-width: 768px) {
  .categoryNews-sec__item {
    padding: 35px 0 !important;
  }
}
.categoryNews-sec__item:last-child {
  border-bottom: none;
}
.categoryNews-sec__item::before {
  display: none !important;
}
.categoryNews-sec__item .link,
.categoryNews-sec__item .no-link {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-left: 2px solid #dd4b03;
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .categoryNews-sec__item .link,
.categoryNews-sec__item .no-link {
    border-left-width: 3px;
    padding: 5px 0 5px 30px;
  }
}
.categoryNews-sec__item .link {
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .categoryNews-sec__item .link:hover .title {
    text-decoration: underline;
  }
}
.categoryNews-sec__item .date {
  display: block;
  color: #dd4b03;
  margin: 0 !important;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.08em;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .categoryNews-sec__item .date {
    line-height: 28px;
    font-size: 16px;
    font-size: 1rem;
  }
}
.categoryNews-sec__item .tag {
  display: flex;
  flex-wrap: wrap;
  margin: -5px 0 0 10px;
}
@media screen and (min-width: 768px) {
  .categoryNews-sec__item .tag {
    margin-left: 40px;
  }
}
.categoryNews-sec__item .tag span {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #333;
  color: #fff;
  margin: 5px 10px 0 0;
  padding: 2px 7px;
  height: 20px;
  font-size: 11.5px;
  font-size: 0.71875rem;
}
@media screen and (min-width: 768px) {
  .categoryNews-sec__item .tag span {
    padding-bottom: 3px;
    width: 100px;
    height: 28px;
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.categoryNews-sec__item .title {
  border-bottom: none !important;
  margin: 5px 0 0 !important;
  padding: 0 !important;
  width: 100%;
  font-weight: 400;
  line-height: 1.5833333333;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .categoryNews-sec__item .title {
    margin: 10px 0 0 !important;
    font-size: 16px;
    font-size: 1rem;
  }
}
.categoryNews-sec__item .title::before {
  display: none !important;
}

/* parts.scss
--------------------------------------------------------------------------------------- */
.clr {
  clear: both;
}

.u-m-i-n {
  display: inline;
}
@media screen and (min-width: 1081px) {
  .u-m-i-n {
    display: none;
  }
}

.u-m-n-i {
  display: none;
}
@media screen and (min-width: 1081px) {
  .u-m-n-i {
    display: inline;
  }
}

.u-s-i-n {
  display: inline;
}
@media screen and (min-width: 768px) {
  .u-s-i-n {
    display: none;
  }
}

.u-s-n-i {
  display: none;
}
@media screen and (min-width: 768px) {
  .u-s-n-i {
    display: inline;
  }
}

.u-s-n-b {
  display: none;
}
@media screen and (min-width: 768px) {
  .u-s-n-b {
    display: block;
  }
}

.m-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin: auto;
  padding: 5px 35px;
  width: 80vw;
  max-width: 370px;
  height: 50px;
  border-radius: 25px;
  box-shadow: 1px 3px 30px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .m-btn {
    padding: 5px 50px;
    width: 100%;
    height: 80px;
    border-radius: 40px;
    box-shadow: 2.5px 6.5px 72px 7px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .m-btn:hover {
    background: #dd4b03;
    color: #fff;
  }
}
.m-btn.arw::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  border-top: 2px solid #222;
  border-right: 2px solid #222;
  margin: auto;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .m-btn.arw::after {
    right: 30px;
    border-top-width: 3px;
    border-right-width: 3px;
    width: 9px;
    height: 9px;
  }
}
@media screen and (min-width: 768px) {
  .m-btn.arw:hover::after {
    border-top-color: #fff;
    border-right-color: #fff;
  }
}

.new-wpContent .tl-anchor {
  position: relative;
}
.new-wpContent .tl-anchor__list {
  border-bottom: 1px solid #ccc;
  margin: 55px 0;
  padding-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .tl-anchor__list {
    margin: 90px 0;
    padding-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
  }
}
.new-wpContent .tl-anchor__item {
  margin: 10px 0;
  padding-left: 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .tl-anchor__item {
    margin: 17px 45px 17px 0;
  }
}
.new-wpContent .tl-anchor__item::before {
  display: none;
}
.new-wpContent .tl-anchor__item a {
  position: relative;
  display: inline-block;
  padding-left: 17px;
  line-height: 1.75;
  letter-spacing: 0.08em;
  font-size: 14px;
  font-size: 0.875rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .tl-anchor__item a {
    padding-left: 25px;
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.new-wpContent .tl-anchor__item a::before {
  content: "";
  position: absolute;
  top: 0.55em;
  left: 2px;
  border-bottom: 2px solid #dd4b03;
  border-right: 2px solid #dd4b03;
  margin: auto;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translate3d(0, 0, 0);
  transition: border-color 0.2s ease;
}
@media screen and (min-width: 768px) {
  .new-wpContent .tl-anchor__item a::before {
    border-bottom-width: 3px;
    border-right-width: 3px;
    width: 9px;
    height: 9px;
  }
}

.new-wpContent .ttc-point .icon img,
.new-wpContent .cs-point .icon img {
  max-width: 40%;
}

.new-wpContent .ttc-about__list {
  margin: 10px 0 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .ttc-about__list {
    margin: 20px 0 30px;
  }
}
.new-wpContent .ttc-about__item {
  position: relative;
  background-color: #fff;
  margin-bottom: 15px;
  padding: 20px 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .ttc-about__item {
    margin-bottom: 20px;
    padding: 47px 50px;
  }
}
.new-wpContent .ttc-about__item:last-child {
  margin-bottom: 0;
}
.new-wpContent .ttc-about__item dt {
  color: #dd4b03;
  margin-bottom: 7px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  letter-spacing: 0.08em;
  font-size: 15px;
  font-size: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .ttc-about__item dt {
    margin-bottom: 15px;
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.new-wpContent .ttc-about__item dd {
  color: #222;
  line-height: 1.8666666667;
  font-size: 11.5px;
  font-size: 0.71875rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .ttc-about__item dd {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

.notfound-box {
  margin-top: 75px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .notfound-box {
    margin-top: 150px;
  }
}
.notfound-box .m-btn-wrap {
  margin-top: 35px;
}
@media screen and (min-width: 768px) {
  .notfound-box .m-btn-wrap {
    margin-top: 75px;
  }
}

.front {
  position: relative;
  background: #f8f8f8;
  display: block;
}
.front::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #f5f4f3;
  width: 36.5%;
  height: 100%;
}

.front-mainv {
  position: relative;
  z-index: 0;
  width: 100%;
  height: calc(100vh - 58px);
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .front-mainv {
    height: 100vh;
  }
}
.front-mainv iframe {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.front-mainv #yt-vol {
  position: absolute;
  z-index: 2;
  top: 15px;
  right: 7px;
}
@media screen and (min-width: 768px) {
  .front-mainv #yt-vol {
    top: 120px;
    right: 12px;
  }
}
.front-mainv #yt-vol > div {
  padding: 5px;
  width: 40px;
  text-align: center;
  line-height: 1;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .front-mainv #yt-vol > div {
    width: 50px;
  }
}
.front-mainv #yt-vol > div i {
  color: #dd4b03;
  font-size: 24px;
  font-size: 1.5rem;
}
@media screen and (min-width: 768px) {
  .front-mainv #yt-vol > div i {
    font-size: 36px;
    font-size: 2.25rem;
  }
}
.front-mainv #yt-vol .icon-vol-on {
  display: none;
}
.front-mainv #yt-vol .icon-vol-off {
  display: block;
}
.front-mainv #yt-vol.vol-on .icon-vol-on {
  display: block;
}
.front-mainv #yt-vol.vol-on .icon-vol-off {
  display: none;
}

.front-flash-news {
  position: relative;
}
.front-flash-news__block {
  background: #fff;
}
@media screen and (min-width: 768px) {
  .front-flash-news__block {
    display: flex;
  }
}
.front-flash-news__title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  background: #333;
  color: #fff;
  width: 100%;
  height: 45px;
  font-weight: 700;
  line-height: 1;
  font-size: 15px;
  font-size: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .front-flash-news__title {
    width: 300px;
    height: auto;
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.front-flash-news__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7px 15px;
  min-height: 60px;
}
@media screen and (min-width: 768px) {
  .front-flash-news__list {
    flex: 1 1 auto;
    padding: 40px 85px;
    min-height: 120px;
  }
}
.front-flash-news__item a {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
  padding: 5px 0;
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .front-flash-news__item a {
    flex-wrap: nowrap;
    margin: 10px 0;
  }
}
@media screen and (min-width: 768px) {
  .front-flash-news__item a:hover .title {
    text-decoration: underline;
  }
}
.front-flash-news__item .date {
  display: block;
  color: #333;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.08em;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .front-flash-news__item .date {
    line-height: 28px;
    font-size: 16px;
    font-size: 1rem;
  }
}
.front-flash-news__item .title {
  margin-top: 5px;
  width: 100%;
  font-weight: 400;
  line-height: 1.5833333333;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .front-flash-news__item .title {
    margin: 0 0 0 55px;
    font-size: 16px;
    font-size: 1rem;
  }
}

.front-pickup {
  position: relative;
  padding: 40px 0 35px;
}
@media screen and (min-width: 768px) {
  .front-pickup {
    padding: 175px 0 85px;
  }
}
@media screen and (min-width: 768px) {
  .front-pickup .container {
    max-width: 1730px;
  }
}
.front-pickup__title {
  position: relative;
  z-index: 1;
  color: #333;
  margin-bottom: -15px;
  font-weight: 700;
  text-align: center;
  line-height: 1.25;
  letter-spacing: 0.08em;
  font-size: 25px;
  font-size: 1.5625rem;
}
@media screen and (min-width: 768px) {
  .front-pickup__title {
    margin-bottom: -30px;
    font-size: 40px;
    font-size: 2.5rem;
  }
}
.front-pickup__list {
  position: relative;
  z-index: 0;
  background: #fff;
  padding: 37.5px 5.75%;
}
@media screen and (min-width: 768px) {
  .front-pickup__list {
    padding: 100px 65px 85px;
  }
}
.front-pickup__list::before {
  content: "";
  position: absolute;
  top: calc(100% - 52px);
  left: -10px;
  background-image: url(../img/front/pickup_title_en.png);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  width: 180px;
  height: 104px;
}
@media screen and (min-width: 768px) {
  .front-pickup__list::before {
    top: -2.5%;
    left: 50%;
    margin-left: 270px;
    width: 234.5px;
    height: 135px;
    transform: translate(-50%, -50%);
  }
}
@media screen and (min-width: 1281px) {
  .front-pickup__list::before {
    margin-left: 390px;
    width: 469px;
    height: 270px;
  }
}
.front-pickup__item {
  position: relative;
  background: #fff;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .front-pickup__item {
    margin-bottom: 20px;
  }
}
.front-pickup__item:last-child {
  margin-bottom: 0;
}
.front-pickup__item a {
  position: relative;
  display: block;
  transition: all 0.2s ease;
}
.front-pickup__item a::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 52px 52px;
  border-color: transparent transparent #000 transparent;
}
@media screen and (min-width: 768px) {
  .front-pickup__item a::before {
    border-width: 0 0 84px 84px;
  }
}
.front-pickup__item a::after {
  content: "";
  position: absolute;
  bottom: 12px;
  right: 13px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  margin: auto;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
}
@media screen and (min-width: 768px) {
  .front-pickup__item a::after {
    bottom: 20px;
    right: 25px;
    border-top-width: 3px;
    border-right-width: 3px;
    width: 9px;
    height: 9px;
  }
}
@media screen and (min-width: 768px) {
  .front-pickup__item a:hover {
    opacity: 0.7;
  }
}

.front-outline {
  position: relative;
  padding: 35px 0 80px;
}
@media screen and (min-width: 768px) {
  .front-outline {
    padding: 85px 0;
  }
}
.front-outline__title {
  display: none;
}
.front-outline__item {
  position: relative;
  z-index: 0;
  display: flex;
}
@media screen and (min-width: 768px) {
  .front-outline__item::before {
    content: "";
    position: absolute;
    top: 105px;
    right: 0;
    z-index: -1;
    background: #fff;
    width: 91.6666666667%;
    height: calc(100% - 105px);
  }
}
.front-outline .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 66.6666666667%;
}
@media screen and (min-width: 768px) {
  .front-outline .image {
    position: relative;
    margin-bottom: 140px;
    width: 57.2916666667%;
  }
}
.front-outline .catch {
  position: absolute;
  top: 0;
  right: 6.75%;
  width: 67.5px;
}
@media screen and (min-width: 768px) {
  .front-outline .catch {
    top: -45px;
    right: 4.5%;
    width: 135px;
  }
}
@media screen and (min-width: 768px) {
  .front-outline .text-wrap {
    margin-top: 105px;
    padding: 160px 40px 80px 80px;
    width: 42.7083333333%;
  }
}
.front-outline .text {
  position: relative;
  margin-left: 9.3333333333%;
  margin-bottom: 30px;
  padding: 55% 45px 40px;
  line-height: 2.0833333333;
  width: 90.6666666667%;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .front-outline .text {
    background: transparent;
    margin: 0 0 10%;
    padding: 0;
    width: 100%;
    max-width: 545px;
    line-height: 3.75;
    font-size: 16px;
    font-size: 1rem;
  }
}
.front-outline .text::before {
  content: "";
  position: absolute;
  top: 95px;
  right: 0;
  z-index: -1;
  background: #fff;
  width: 100%;
  height: calc(100% - 95px);
}
@media screen and (min-width: 768px) {
  .front-outline .text::before {
    display: none;
  }
}
.front-outline .m-btn {
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .front-outline .m-btn {
    margin: 0;
  }
}

.front-course {
  position: relative;
  padding: 55px 0 55px;
}
@media screen and (min-width: 768px) {
  .front-course {
    padding: 115px 0 85px;
  }
}
.front-course__title {
  position: relative;
  z-index: 0;
  color: #333;
  margin-bottom: 60px;
  font-weight: 700;
  text-align: center;
  line-height: 1.25;
  letter-spacing: 0.08em;
  font-size: 25px;
  font-size: 1.5625rem;
}
@media screen and (min-width: 768px) {
  .front-course__title {
    margin-bottom: 95px;
    font-size: 40px;
    font-size: 2.5rem;
  }
}
.front-course__title::before {
  content: "";
  position: absolute;
  top: 15%;
  left: 44%;
  z-index: -1;
  background-image: url(../img/front/course_title_en.png);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  width: 169px;
  height: 99px;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 768px) {
  .front-course__title::before {
    top: 50%;
    left: 50%;
    width: 338px;
    height: 197px;
  }
}
.front-course__block {
  background: #fff;
  margin-left: 5.85%;
  padding: 35px 0 50px;
}
@media screen and (min-width: 768px) {
  .front-course__block {
    background: transparent;
    margin-left: 0;
    padding: 0;
  }
}
.front-course .catch {
  position: absolute;
  top: 70px;
  right: 4.75%;
  z-index: 2;
  width: 88px;
}
@media screen and (min-width: 768px) {
  .front-course .catch {
    top: -85px;
    right: 4.5%;
    width: 218px;
  }
}
.front-course__item {
  position: relative;
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .front-course__item {
    display: flex;
    align-items: flex-end;
    margin-bottom: 90px;
  }
}
.front-course__item:nth-child(odd) {
  flex-direction: row-reverse;
}
@media screen and (min-width: 768px) {
  .front-course__item:nth-child(odd) .image {
    margin-left: -52.0833333333%;
  }
}
@media screen and (min-width: 768px) {
  .front-course__item:nth-child(odd) .text-wrap-inner {
    padding: 55px 16% 35px 10%;
  }
}
@media screen and (min-width: 1281px) {
  .front-course__item:nth-child(odd) .text-wrap-inner {
    padding: 55px 16% 35px 10%;
  }
}
@media screen and (min-width: 768px) {
  .front-course__item:nth-child(even) .image {
    margin-right: -52.0833333333%;
  }
}
@media screen and (min-width: 768px) {
  .front-course__item:nth-child(even) .text-wrap-inner {
    margin-left: 40.7692307692%;
    padding: 55px 9% 35px 17%;
  }
}
@media screen and (min-width: 1281px) {
  .front-course__item:nth-child(even) .text-wrap-inner {
    padding: 55px 9% 35px 17%;
  }
}
.front-course__item:last-child {
  margin-bottom: 0;
}
.front-course__item .image {
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
  padding-left: 7%;
}
@media screen and (min-width: 768px) {
  .front-course__item .image {
    margin-bottom: 100px;
    padding-left: 0;
    width: 52.0833333333%;
  }
}
.front-course__item .image > div {
  overflow: hidden;
}
.front-course__item .image img {
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media screen and (min-width: 768px) {
  .front-course__item .image.active img {
    transform: scale(1.05);
  }
}
.front-course__item .text-wrap {
  position: relative;
  z-index: 0;
  padding: 0 7%;
}
@media screen and (min-width: 768px) {
  .front-course__item .text-wrap {
    margin: 0 auto;
    padding: 0 40px;
    width: 100%;
    max-width: 1380px;
  }
}
.front-course__item .text-wrap-inner {
  position: relative;
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .front-course__item .text-wrap-inner {
    background: #fff;
    width: 59.2307692308%;
    min-height: 340px;
  }
}
@media screen and (min-width: 1281px) {
  .front-course__item .text-wrap-inner {
    min-height: 390px;
  }
}
@media screen and (min-width: 768px) {
  .front-course__item .text-wrap-inner:hover .btn {
    background: #dd4b03;
  }
}
@media screen and (min-width: 768px) {
  .front-course__item .text-wrap-inner:hover .btn::before {
    border-top-color: #fff;
    border-right-color: #fff;
  }
}
.front-course__item .text-wrap-inner a {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 43px;
  height: 43px;
  border-radius: 50%;
}
@media screen and (min-width: 768px) {
  .front-course__item .text-wrap-inner a {
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
.front-course__item .title {
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .front-course__item .title {
    margin-bottom: 40px;
  }
}
.front-course__item .title .jp {
  display: block;
  color: #333;
  font-weight: 700;
  line-height: 1.35;
  letter-spacing: 0.08em;
  font-size: 19px;
  font-size: 1.1875rem;
}
@media screen and (min-width: 768px) {
  .front-course__item .title .jp {
    font-size: 34px;
    font-size: 2.125rem;
  }
}
.front-course__item .title .en {
  display: block;
  color: #dd4b03;
  margin-top: 10px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  font-size: 10px;
  font-size: 0.625rem;
}
@media screen and (min-width: 768px) {
  .front-course__item .title .en {
    margin-top: 12px;
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
.front-course__item .text {
  margin-bottom: 10px;
  line-height: 1.5833333333;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .front-course__item .text {
    margin-bottom: 40px;
    line-height: 1.875;
    font-size: 16px;
    font-size: 1rem;
  }
}
.front-course__item .btn {
  position: relative;
  display: block;
  background: #fff;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  box-shadow: 1px 3px 30px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .front-course__item .btn {
    width: 86px;
    height: 86px;
    box-shadow: 2.5px 6.5px 72px 7px rgba(0, 0, 0, 0.1);
  }
}
.front-course__item .btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 2px;
  border-top: 2px solid #222;
  border-right: 2px solid #222;
  margin: auto;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .front-course__item .btn::before {
    border-top-width: 3px;
    border-right-width: 3px;
    width: 9px;
    height: 9px;
  }
}

.front-news {
  position: relative;
  padding: 55px 0 65px;
}
@media screen and (min-width: 768px) {
  .front-news {
    padding: 90px 0 100px;
  }
}
.front-news .container {
  position: relative;
}
.front-news__title {
  position: relative;
  z-index: 0;
  color: #333;
  margin-bottom: 45px;
  font-weight: 700;
  text-align: center;
  line-height: 1.25;
  letter-spacing: 0.08em;
  font-size: 25px;
  font-size: 1.5625rem;
}
@media screen and (min-width: 768px) {
  .front-news__title {
    position: absolute;
    top: 0;
    left: 40px;
    margin-bottom: 25px;
    font-size: 40px;
    font-size: 2.5rem;
  }
}
.front-news__title::before {
  content: "";
  position: absolute;
  top: 15%;
  left: 45%;
  z-index: -1;
  background-image: url(../img/front/news_title_en.png);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  width: 138px;
  height: 100px;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 768px) {
  .front-news__title::before {
    width: 276px;
    height: 200px;
  }
}
.front-news .news-tag-nav {
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .front-news .news-tag-nav {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 60px;
  }
}
.front-news .news-tag-nav .tag {
  display: flex;
  flex-wrap: wrap;
}
.front-news .news-tag-nav .tag span {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #222;
  color: #333;
  margin: 0 -1px -1px 0;
  padding: 2px 7px;
  width: 25%;
  min-width: 60px;
  height: 40px;
  line-height: 1;
  white-space: nowrap;
  transition: all 0.2s ease;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .front-news .news-tag-nav .tag span {
    padding: 5px 25px;
    width: auto;
    min-width: 100px;
    height: 60px;
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
.front-news .news-tag-nav .tag span.active {
  background: #333;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .front-news .news-tag-nav .tag span:hover {
    background: #333;
    color: #fff;
  }
}
.front-news__body {
  position: relative;
}
.front-news .tag-box {
  position: relative;
}
.front-news .tag-box__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s, visibility 0s ease 0.5s;
  opacity: 0;
  visibility: hidden;
}
.front-news .tag-box__inner.active {
  position: static;
  transition-delay: 0s;
  opacity: 1;
  visibility: visible;
}
.front-news__list {
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .front-news__list {
    margin-bottom: 35px;
  }
}
.front-news__item {
  border-bottom: 1px solid #ccc;
  padding: 25px 0;
}
@media screen and (min-width: 768px) {
  .front-news__item {
    padding: 35px 0;
  }
}
.front-news__item:last-child {
  border-bottom: none;
}
.front-news__item .link,
.front-news__item .no-link {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-left: 2px solid #dd4b03;
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .front-news__item .link,
.front-news__item .no-link {
    flex-wrap: nowrap;
    border-left-width: 3px;
    padding: 5px 0 5px 30px;
  }
}
.front-news__item .link {
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .front-news__item .link:hover .title {
    text-decoration: underline;
  }
}
.front-news__item .date {
  display: block;
  color: #dd4b03;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.08em;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .front-news__item .date {
    line-height: 28px;
    font-size: 16px;
    font-size: 1rem;
  }
}
.front-news__item .tag {
  margin-left: 10px;
}
@media screen and (min-width: 768px) {
  .front-news__item .tag {
    margin-left: 40px;
  }
}
.front-news__item .tag span {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #333;
  color: #fff;
  padding: 2px 7px;
  height: 20px;
  font-size: 11.5px;
  font-size: 0.71875rem;
}
@media screen and (min-width: 768px) {
  .front-news__item .tag span {
    padding-bottom: 3px;
    width: 100px;
    height: 28px;
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.front-news__item .title {
  margin-top: 5px;
  width: 100%;
  font-weight: 400;
  line-height: 1.5833333333;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .front-news__item .title {
    margin: 0 0 0 55px;
    font-size: 16px;
    font-size: 1rem;
  }
}

.front-learnmore {
  position: relative;
  background-image: url(../img/front/learnmore_bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0;
}
@media screen and (min-width: 768px) {
  .front-learnmore {
    padding: 85px 0;
  }
}
@media screen and (min-width: 992px) {
  .front-learnmore {
    padding: 115px 0;
  }
}
.front-learnmore__main-block {
  margin-bottom: 35px;
}
@media screen and (min-width: 768px) {
  .front-learnmore__main-block {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 992px) {
  .front-learnmore__main-block {
    margin-bottom: 65px;
  }
}
@media screen and (min-width: 768px) {
  .front-learnmore__main-list {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
  }
}
.front-learnmore__main-item {
  margin-bottom: 12.5px;
}
@media screen and (min-width: 768px) {
  .front-learnmore__main-item {
    margin-bottom: 0;
    width: 33.3333333333%;
    padding: 0 15px;
  }
}
.front-learnmore__main-item:last-child {
  margin-bottom: 0;
}
.front-learnmore__main-item .image {
  margin-bottom: calc(-9.5% + -50px);
  width: 34.5%;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .front-learnmore__main-item .image {
    margin-bottom: -90px;
    width: 89.4736842105%;
  }
}
.front-learnmore__main-item .image.active img {
  transform: scale(1.05);
}
.front-learnmore__main-item .image img {
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.front-learnmore__main-item .title-wrap {
  background: #fff;
  margin-left: 10%;
  width: 90%;
}
@media screen and (min-width: 768px) {
  .front-learnmore__main-item .title-wrap {
    margin-left: 10.5263157895%;
    width: 89.4736842105%;
  }
}
.front-learnmore__main-item .title-wrap a {
  display: block;
  padding: 9.5% 5% 9.5% 33.5%;
}
@media screen and (min-width: 768px) {
  .front-learnmore__main-item .title-wrap a {
    padding: 115px 35px 30px;
  }
}
.front-learnmore__main-item .title .jp {
  display: block;
  color: #333;
  font-weight: 700;
  line-height: 1.35;
  letter-spacing: 0.08em;
  font-size: 17px;
  font-size: 1.0625rem;
}
@media screen and (min-width: 768px) {
  .front-learnmore__main-item .title .jp {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.front-learnmore__main-item .title .en {
  display: block;
  color: #dd4b03;
  margin-top: 3px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  letter-spacing: 0.08em;
  font-size: 10px;
  font-size: 0.625rem;
}
@media screen and (min-width: 768px) {
  .front-learnmore__main-item .title .en {
    margin-top: 8px;
    font-size: 11px;
    font-size: 0.6875rem;
  }
}
.front-learnmore__sub-block {
  background: rgba(255, 255, 255, 0.5);
  padding: 25px 20px;
}
@media screen and (min-width: 768px) {
  .front-learnmore__sub-block {
    padding: 40px 35px;
  }
}
@media screen and (min-width: 992px) {
  .front-learnmore__sub-block {
    padding: 50px 55px;
  }
}
@media screen and (min-width: 768px) {
  .front-learnmore__sub-list {
    margin: 0 -25px;
    display: flex;
    flex-wrap: wrap;
  }
}
.front-learnmore__sub-item {
  margin-top: 12.5px;
}
@media screen and (min-width: 768px) {
  .front-learnmore__sub-item {
    margin-top: 30px;
    width: 33.3333333333%;
    padding: 0 25px;
  }
}
.front-learnmore__sub-item:nth-of-type(1) {
  margin-top: 0;
}
@media screen and (min-width: 768px) {
  .front-learnmore__sub-item:nth-of-type(2), .front-learnmore__sub-item:nth-of-type(3) {
    margin-top: 0;
  }
}
.front-learnmore__sub-item a {
  position: relative;
  display: block;
  background: #fff;
  border-left: 1px solid #dd4b03;
  padding: 15px 0 15px 20px;
  box-shadow: 1px 3px 30px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .front-learnmore__sub-item a {
    border-left-width: 2px;
    padding: 33px 50px 33px 35px;
    box-shadow: 2.5px 6.5px 72px 7px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-size: 1rem;
  }
}
.front-learnmore__sub-item a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  margin: auto;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .front-learnmore__sub-item a::before {
    right: 30px;
    border-top-width: 3px;
    border-right-width: 3px;
    width: 9px;
    height: 9px;
  }
}
@media screen and (min-width: 768px) {
  .front-learnmore__sub-item a:hover {
    color: #dd4b03;
  }
}
.front-learnmore__sub-item a:hover::before {
  border-top-color: #dd4b03;
  border-right-color: #dd4b03;
  right: 27px;
}

/* ==========================
		bottomPageMainv
============================= */
.bottomPageMainv {
  position: relative;
  /* ------- enrollment/ ------- */
  /* ------- employment/ ------- */
  /* ------- application ------- */
  /* ------- openschool ------- */
  /* ------- contact ------- */
  /* ------- document-request ------- */
  /* ------- recruitment ------- */
  /* ------- document-request ------- */
  /* ------- privacy ------- */
  /* ------- term ------- */
  /* ------- hyouka ------- */
}
.bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/hanyo/mv.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
}
@media screen and (min-width: 768px) {
  .bottomPageMainv__img {
    height: 620px;
  }
}
.bottomPageMainv.noMainv .bottomPageMainv__img {
  background: none;
  height: 120px;
}
@media screen and (min-width: 768px) {
  .bottomPageMainv.noMainv .bottomPageMainv__img {
    height: 360px;
  }
}
.bottomPageMainv.outline .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/outline/mv.jpg");
}
.bottomPageMainv.course .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/course/mv.jpg");
}
.bottomPageMainv.activity .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/activity/mv.jpg");
}
.bottomPageMainv.schoollife .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/schoollife/mv.jpg");
}
.bottomPageMainv.future .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/future/mv.jpg");
}
.bottomPageMainv.for-alumni .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/alumni/mv.jpg");
}
.bottomPageMainv.for-prospective .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/prospective/mv.jpg");
}
.bottomPageMainv.for-students .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/students/mv.jpg");
}
.page-id-35995 .bottomPageMainv .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/future/enrollment/mv.jpg");
}

.page-id-35993 .bottomPageMainv .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/future/employment/mv.jpg");
}

.page-id-36222 .bottomPageMainv .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/application/mv.jpg");
}

.page-id-36236 .bottomPageMainv .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/application/openschool/mv.jpg");
}

.page-id-32051 .bottomPageMainv .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/contact/mv.jpg");
}

.page-id-36307 .bottomPageMainv .bottomPageMainv__img,
.page-id-36315 .bottomPageMainv .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/request/mv.jpg");
}

.page-id-36319 .bottomPageMainv .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/recruitment/mv.jpg");
}

.page-id-31479 .bottomPageMainv .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/request/mv.jpg");
}

.page-id-31479 .bottomPageMainv .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/privacy/mv.jpg");
}

.page-id-31477 .bottomPageMainv .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/term/mv.jpg");
}

.page-id-36292 .bottomPageMainv .bottomPageMainv__img {
  background-image: url("/hs/wp-content/themes/ghs/img/hyouka/mv.jpg");
}

.bottomPageMainv__block {
  position: absolute;
  bottom: -90px;
  left: 0;
  right: 0;
  z-index: 2;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 20px 15px 30px;
  width: 80%;
  min-height: 180px;
}
@media screen and (min-width: 768px) {
  .bottomPageMainv__block {
    padding: 30px 25px 50px;
    width: 600px;
    min-height: 280px;
  }
}
.bottomPageMainv__ttl {
  margin-bottom: 10px;
  font-family: "Noto Serif JP", serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.08em;
  font-size: 28px;
  font-size: 1.75rem;
}
@media screen and (min-width: 768px) {
  .bottomPageMainv__ttl {
    margin-bottom: 25px;
    font-size: 50px;
    font-size: 3.125rem;
  }
}

/* ==========================
	bottomPageBreadlist
============================= */
.bottomPageBread > span {
  display: block;
  margin: 0 -0.25em;
  line-height: 1.35;
  letter-spacing: 0;
}
@media screen and (min-width: 768px) {
  .bottomPageBread > span {
    margin: 0 -0.8em;
  }
}
.bottomPageBread > span > span {
  color: #848484;
  font-weight: 500;
  line-height: 1.7142857143;
  letter-spacing: 0.08em;
  font-size: 10px;
  font-size: 0.625rem;
}
@media screen and (min-width: 768px) {
  .bottomPageBread > span > span {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.bottomPageBread > span > span span.breadcrumb_last, .bottomPageBread > span > span a {
  margin: 0 0.25em;
}
@media screen and (min-width: 768px) {
  .bottomPageBread > span > span span.breadcrumb_last, .bottomPageBread > span > span a {
    margin: 0 0.8em;
  }
}
.bottomPageBread > span > span span.breadcrumb_last {
  color: #dd4b03;
}
.bottomPageBread > span > span a {
  color: #848484;
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .bottomPageBread > span > span a:hover {
    color: #dd4b03;
  }
}

/* ==========================
	Gray Back
============================= */
.grayBack {
  position: relative;
  background: #f8f8f8;
  padding: 90px 0 55px;
}
@media screen and (min-width: 768px) {
  .grayBack {
    padding-bottom: 135px;
  }
}
.grayBack::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: #f5f4f3;
  width: 36.5%;
  height: 100%;
}

/* ==========================
	White Back
============================= */
.whiteBack {
  position: relative;
  padding: 0 3.5%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .whiteBack {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1280px;
  }
}
.whiteBack-container {
  position: relative;
  z-index: 1;
  background: #fff;
  padding: 1px 25px 45px;
}
@media screen and (min-width: 768px) {
  .whiteBack-container {
    padding: 1px 120px 75px;
  }
}

/* ==========================
		WP CONTENT
============================= */
.new-wpContent section {
  position: relative;
}
.new-wpContent .img-tit-link {
  position: relative;
  padding: 50px 0 35px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link {
    padding: 100px 0;
  }
}
.new-wpContent .img-tit-link.img-tit-link:last-child {
  padding-bottom: 0;
}
.new-wpContent .img-tit-link__item {
  position: relative;
  z-index: 0;
  display: flex;
  margin-bottom: 50px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link__item {
    margin-bottom: 100px;
  }
}
.new-wpContent .img-tit-link__item:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link__item::before {
    content: "";
    position: absolute;
    top: 105px;
    right: 0;
    z-index: -1;
    background: #fff;
    width: 91.6666666667%;
    height: calc(100% - 105px);
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link__item:nth-child(odd)::before {
    right: 0;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link__item:nth-child(odd) .text-wrap {
    padding-left: 30px;
  }
}
@media screen and (min-width: 1281px) {
  .new-wpContent .img-tit-link__item:nth-child(odd) .text-wrap {
    padding-left: 80px;
  }
}
@media screen and (max-width: 767px) {
  .new-wpContent .img-tit-link__item:nth-child(odd) .text {
    padding: 55% 22.5% 40px 35px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link__item:nth-child(odd) .text {
    max-width: 410px;
    padding-right: 50px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link__item:nth-child(even) {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link__item:nth-child(even)::before {
    left: 0;
  }
}
.new-wpContent .img-tit-link__item:nth-child(even) .image {
  left: auto;
  right: 0;
}
.new-wpContent .img-tit-link__item:nth-child(even) .title {
  left: 7%;
  right: auto;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link__item:nth-child(even) .title {
    left: auto;
    right: 61%;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link__item:nth-child(even) .text-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 160px 80px 80px 40px;
  }
}
@media screen and (max-width: 767px) {
  .new-wpContent .img-tit-link__item:nth-child(even) .text {
    margin-left: 0;
    margin-right: 9.3333333333%;
    padding: 55% 35px 40px 22.5%;
  }
}
@media screen and (max-width: 767px) {
  .new-wpContent .img-tit-link__item:nth-child(even) .text::before {
    left: 0;
    right: auto;
  }
}
.new-wpContent .img-tit-link .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 66.6666666667%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link .image {
    position: relative;
    margin-bottom: 125px;
    width: 57.2916666667%;
  }
}
.new-wpContent .img-tit-link .title {
  position: absolute;
  top: 10px;
  right: 6.75%;
  margin: 0;
  writing-mode: vertical-rl;
  vertical-align: top;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link .title {
    top: 15px;
    right: 4.5%;
  }
}
.new-wpContent .img-tit-link .title .jp {
  line-height: 1.35;
}
.new-wpContent .img-tit-link .title .en {
  margin: 2px 0.6em 0 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link .title .en {
    margin-right: 1.1em;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link .text-wrap {
    margin-top: 105px;
    padding: 160px 40px 80px 80px;
    width: 42.7083333333%;
  }
}
.new-wpContent .img-tit-link .text {
  position: relative;
  margin-left: 9.3333333333%;
  margin-bottom: 30px;
  line-height: 2.0833333333;
  width: 90.6666666667%;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link .text {
    background: transparent;
    margin: 0 0 8.5%;
    padding: 0 15px 0 0;
    width: 100%;
    max-width: 375px;
    line-height: 3.75;
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent .img-tit-link .text::before {
  content: "";
  position: absolute;
  top: 95px;
  right: 0;
  z-index: -1;
  background: #fff;
  width: 100%;
  height: calc(100% - 95px);
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link .text::before {
    display: none;
  }
}
.new-wpContent .img-tit-link .m-btn {
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link .m-btn {
    margin: 0;
  }
}
.new-wpContent .img-tit-link2 {
  position: relative;
  z-index: 0;
  padding: 50px 0 35px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link2 {
    padding: 100px 0;
  }
}
@media screen and (max-width: 767px) {
  .new-wpContent .img-tit-link2:last-child {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link2:last-child {
    margin-bottom: -100px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link2::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 180px;
    left: 40px;
    right: 40px;
    background: #fff;
    margin: auto;
    max-width: 1600px;
    height: calc(100% - 280px);
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link2::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 180px;
    right: 0;
    background: #fff;
    width: 50%;
    height: calc(100% - 280px);
  }
}
.new-wpContent .img-tit-link2 .container {
  position: relative;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link2 .container {
    padding: 0 40px 0 80px;
    max-width: 1320px;
  }
}
@media screen and (min-width: 1331px) {
  .new-wpContent .img-tit-link2 .container {
    padding: 0 60px;
    max-width: 1320px;
  }
}
.new-wpContent .img-tit-link2__list {
  position: relative;
  z-index: 0;
  margin-left: 5.85%;
  padding-bottom: 50px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link2__list {
    margin: 0 -20px;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 992px) {
  .new-wpContent .img-tit-link2__list {
    margin: 0 -40px;
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 767px) {
  .new-wpContent .img-tit-link2__list::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 50px;
    right: 0;
    background: #fff;
    width: 100%;
    height: calc(100% - 50px);
  }
}
.new-wpContent .img-tit-link2__item {
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link2__item {
    margin-bottom: 40px;
    width: 50%;
    padding: 0 20px;
  }
}
@media screen and (min-width: 992px) {
  .new-wpContent .img-tit-link2__item {
    margin-bottom: 80px;
    width: 50%;
    padding: 0 40px;
  }
}
@media screen and (max-width: 767px) {
  .new-wpContent .img-tit-link2__item:last-child {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link2__item a:hover img {
    transform: scale(1.05);
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link2__item a:hover .title::after {
    right: 3px;
    border-top-color: #dd4b03;
    border-right-color: #dd4b03;
  }
}
.new-wpContent .img-tit-link2__item .image {
  position: relative;
  padding-left: 7%;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link2__item .image {
    padding-left: 0;
  }
}
.new-wpContent .img-tit-link2__item .image.border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
}
.new-wpContent .img-tit-link2__item .image img {
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.new-wpContent .img-tit-link2__item .title {
  position: relative;
  border-bottom: 1px solid #ccc;
  margin: 0 7%;
  padding: 20px 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link2__item .title {
    margin: 0;
    padding: 25px 5px 30px;
  }
}
.new-wpContent .img-tit-link2__item .title::before {
  display: none;
}
.new-wpContent .img-tit-link2__item .title::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 3px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  margin: auto;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link2__item .title::after {
    right: 6px;
    border-top-width: 3px;
    border-right-width: 3px;
    width: 9px;
    height: 9px;
  }
}
.new-wpContent .img-tit-link2__item .title .jp {
  display: block;
  font-weight: 500;
  line-height: 1.35;
  font-size: 18px;
  font-size: 1.125rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link2__item .title .jp {
    font-size: 26px;
    font-size: 1.625rem;
  }
}
.new-wpContent .img-tit-link2__item .title .en {
  display: block;
  color: #dd4b03;
  margin-top: 5px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  font-size: 10px;
  font-size: 0.625rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .img-tit-link2__item .title .en {
    margin-top: 8px;
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
.new-wpContent .m-heading-wrap {
  margin-bottom: 35px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-heading-wrap {
    display: flex;
    margin: 105px 0 80px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-heading-wrap h2 {
    flex: none;
    margin: 0 45px 0 0;
    width: 400px;
  }
}
.new-wpContent .m-heading-wrap p {
  margin: 0;
  line-height: 2.0833333333;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-heading-wrap p {
    line-height: 3.75;
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent h2 {
  margin: 55px 0 35px;
}
@media screen and (min-width: 768px) {
  .new-wpContent h2 {
    margin: 105px 0 80px;
  }
}
.new-wpContent h2 .jp {
  display: block;
  font-weight: 700;
  line-height: 1.7;
  letter-spacing: 0.08em;
  font-size: 25px;
  font-size: 1.5625rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent h2 .jp {
    line-height: 2;
    font-size: 40px;
    font-size: 2.5rem;
  }
}
.new-wpContent h2 .en {
  display: block;
  color: #dd4b03;
  margin-top: 5px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  font-size: 10px;
  font-size: 0.625rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent h2 .en {
    margin-top: 10px;
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
.new-wpContent h3 {
  position: relative;
  border-bottom: 1px solid #ccc;
  margin: 35px 0 20px;
  padding: 0 0 15px 35px;
  font-weight: 700;
  letter-spacing: 0.08em;
  font-size: 22px;
  font-size: 1.375rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent h3 {
    border-bottom-width: 2px;
    margin: 75px 0 45px;
    padding: 0 0 30px 60px;
    line-height: 2;
    font-size: 36px;
    font-size: 2.25rem;
  }
}
.new-wpContent h3::before {
  content: "";
  position: absolute;
  top: 0.7em;
  left: 0;
  background: #dd4b03;
  width: 20px;
  height: 3px;
}
@media screen and (min-width: 768px) {
  .new-wpContent h3::before {
    top: 1em;
    width: 28px;
    height: 5px;
  }
}
.new-wpContent h4 {
  position: relative;
  padding-bottom: 10px;
  margin: 25px 0;
  font-weight: 700;
  letter-spacing: 0.08em;
  font-size: 18px;
  font-size: 1.125rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent h4 {
    padding-bottom: 15px;
    margin: 35px 0;
    line-height: 2;
    font-size: 28px;
    font-size: 1.75rem;
  }
}
.new-wpContent h4::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 15px;
  background-color: #dd4b03;
}
@media screen and (min-width: 768px) {
  .new-wpContent h4::before {
    height: 3px;
    width: 20px;
  }
}
.new-wpContent figure {
  margin: 10px 0 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent figure {
    margin: 20px 0 30px;
  }
}
@media screen and (max-width: 767px) {
  .new-wpContent figure img {
    width: 100%;
  }
}
.new-wpContent figure figcaption {
  position: relative;
  margin-top: 7px;
  line-height: 2.125;
  letter-spacing: 0.08em;
  font-size: 10px;
  font-size: 0.625rem;
}
@media screen and (max-width: 767px) {
  .new-wpContent figure figcaption {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent figure figcaption {
    margin-top: 10px;
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.new-wpContent p {
  margin: 10px 0 15px;
  line-height: 2.125;
  letter-spacing: 0.08em;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent p {
    margin: 20px 0 30px;
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent p:last-child {
  margin-bottom: 0;
}
.new-wpContent p::after::after {
  content: "";
  display: block;
  clear: both;
}
.new-wpContent p > .img-fl {
  display: block;
  margin: 0 auto 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent p > .img-fl {
    display: inline;
    float: left;
    margin: 10px 50px 50px 0;
  }
}
.new-wpContent p > .img-fr {
  display: block;
  margin: 0 auto 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent p > .img-fr {
    display: inline;
    float: right;
    margin: 10px 0 50px 50px;
  }
}
.new-wpContent p > a {
  color: #dd4b03;
  text-decoration: underline;
}
@media screen and (min-width: 768px) {
  .new-wpContent p > a:hover {
    text-decoration: none;
  }
}
.new-wpContent p > a.iblocklink {
  position: relative;
  display: inline-block;
  margin-right: 1.25em;
  line-height: 1.25;
  vertical-align: middle;
}
.new-wpContent p > a.pdflink {
  position: relative;
  display: inline-block;
  margin-right: 1.25em;
  line-height: 1.25;
  vertical-align: middle;
}
.new-wpContent p > a.pdflink::after {
  content: "";
  display: inline-block;
  background-image: url("/hs/wp-content/themes/ghs/img/common/icon_pdf.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 5px;
  width: 12px;
  height: 15px;
  vertical-align: text-bottom;
}
@media screen and (min-width: 768px) {
  .new-wpContent p > a.pdflink::after {
    width: 15px;
    height: 18px;
  }
}
.new-wpContent p > a.doclink {
  position: relative;
  display: inline-block;
  margin-right: 1.25em;
  line-height: 1.25;
  vertical-align: middle;
}
.new-wpContent p > a.doclink::after {
  content: "";
  display: inline-block;
  background-image: url("/hs/wp-content/themes/ghs/img/common/icon_doc.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 5px;
  width: 12px;
  height: 15px;
  vertical-align: text-bottom;
}
@media screen and (min-width: 768px) {
  .new-wpContent p > a.doclink::after {
    width: 15px;
    height: 18px;
  }
}
.new-wpContent p > a.windowlink {
  position: relative;
  display: inline-block;
  margin-right: 1.25em;
  line-height: 1.25;
  vertical-align: middle;
}
.new-wpContent p > a.windowlink::after {
  content: "";
  display: inline-block;
  background-image: url("/hs/wp-content/themes/ghs/img/common/icon_window.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 5px;
  width: 12px;
  height: 12px;
  vertical-align: middle;
}
@media screen and (min-width: 768px) {
  .new-wpContent p > a.windowlink::after {
    width: 15px;
    height: 15px;
  }
}
.new-wpContent p > b {
  font-weight: 700;
}
.new-wpContent p > span.red {
  color: #dd4b03;
}
.new-wpContent p > span.large {
  font-size: 16px;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent p > span.large {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.new-wpContent p.tal {
  text-align: left;
}
.new-wpContent p.tac {
  text-align: center;
}
.new-wpContent p.tar {
  text-align: right;
}
.new-wpContent hr {
  display: block;
  border: none;
  background: #ccc;
  width: 100%;
  height: 1px;
  margin: 40px 0 30px;
}
@media screen and (min-width: 768px) {
  .new-wpContent hr {
    margin: 80px 0 60px;
  }
}
.new-wpContent ul {
  margin: 10px 0 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent ul {
    margin: 20px 0 30px;
  }
}
.new-wpContent ul > li {
  position: relative;
  padding-left: 1.25em;
  line-height: 1.75;
  margin: 5px 0;
  letter-spacing: 0.08em;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent ul > li {
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent ul > li::before {
  content: "";
  display: block;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #dd4b03;
  position: absolute;
  top: 8px;
  left: 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent ul > li::before {
    height: 8px;
    width: 8px;
    top: 12px;
  }
}
.new-wpContent ul > li > p {
  margin: 0;
  line-height: 1.75;
}
.new-wpContent ol {
  margin: 10px 0 15px;
  counter-reset: number 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent ol {
    margin: 20px 0 30px;
  }
}
.new-wpContent ol > li {
  position: relative;
  margin: 5px 0;
  padding-left: 1.75em;
  line-height: 1.75;
  letter-spacing: 0.08em;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent ol > li {
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent ol > li::before {
  counter-increment: number 1;
  content: counter(number) ".";
  position: absolute;
  top: 0;
  left: 0;
  color: #dd4b03;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.08em;
}
@media screen and (min-width: 768px) {
  .new-wpContent .row1 {
    margin: 0 -20px;
    display: flex;
    flex-wrap: wrap;
  }
}
.new-wpContent .row2 {
  margin: 0 -5px;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .new-wpContent .row2 {
    margin: 0 -15px;
  }
}
.new-wpContent span.newitem {
  display: inline-block;
  font-weight: 700;
  background-color: #dd4b03;
  color: #fff;
  margin-left: 5px;
  width: 36px;
  text-align: center;
  line-height: 20px;
  vertical-align: middle;
  transform: translateY(-0.2em);
  font-size: 10px;
  font-size: 0.625rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent span.newitem {
    width: 70px;
    line-height: 32px;
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
.new-wpContent .box01 {
  border: 2px solid rgba(221, 75, 3, 0.25);
  padding: 20px 20px;
  margin: 15px 0 20px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .box01 {
    border-width: 4px;
    margin: 30px 0 40px;
    padding: 40px 40px;
  }
}
.new-wpContent .box01 .row {
  margin: -10px 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .box01 .row {
    display: flex;
    flex-wrap: wrap;
  }
}
.new-wpContent .box01 .row p {
  margin: 10px 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .box01 .row p {
    margin: 10px 40px 10px 0;
  }
}
.new-wpContent .box01 dt {
  margin-bottom: 5px;
  font-weight: 700;
  letter-spacing: 0.08em;
  font-size: 15px;
  font-size: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .box01 dt {
    margin-bottom: 10px;
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.new-wpContent .box01 p {
  margin-top: 0;
  font-size: 11.5px;
  font-size: 0.71875rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .box01 p {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
.new-wpContent .box01 ul:last-child,
.new-wpContent .box01 ol:last-child {
  margin-bottom: 0;
}
.new-wpContent .imgandtxt {
  margin-top: 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .imgandtxt {
    margin-top: 20px;
  }
}
.new-wpContent .imgandtxt .row1 .col-6 {
  width: 100%;
  padding: 0 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .imgandtxt .row1 .col-6 {
    width: 50%;
    padding: 0 20px;
  }
}
.new-wpContent .imgandtxt .row2 .col-3 {
  width: 50%;
  padding: 0 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .imgandtxt .row2 .col-3 {
    width: 25%;
    padding: 0 15px;
  }
}
.new-wpContent .imgandtxt .row2 .col-4 {
  width: 50%;
  padding: 0 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .imgandtxt .row2 .col-4 {
    width: 33.3333333333%;
    padding: 0 15px;
  }
}
.new-wpContent .imgandtxt__item01 {
  margin-bottom: 30px;
  width: 50%;
  padding: 0 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .imgandtxt__item01 {
    margin-bottom: 55px;
    width: 33.3333333333%;
    padding: 0 15px;
  }
}
.new-wpContent .imgandtxt__item01 > div {
  border-bottom: 2px solid #dd4b03;
  padding-bottom: 10px;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .imgandtxt__item01 > div {
    padding-bottom: 20px;
  }
}
.new-wpContent .imgandtxt__item01 figure {
  margin: 0 0 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .imgandtxt__item01 figure {
    margin: 0 0 20px;
  }
}
.new-wpContent .imgandtxt__item01 h6 {
  font-weight: 700;
  line-height: 1.3636363636;
  margin: 10px 0;
  font-size: 15px;
  font-size: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .imgandtxt__item01 h6 {
    margin: 20px 0;
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.new-wpContent .imgandtxt__item01 p {
  margin: 5px 0;
  line-height: 1.8666666667;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .imgandtxt__item01 p {
    margin: 10px 0;
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent .sbs {
  margin: 10px 0 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .sbs {
    display: flex;
    margin: 20px 0 30px;
  }
}
.new-wpContent .sbs.reverse {
  flex-direction: row-reverse;
}
@media screen and (min-width: 768px) {
  .new-wpContent .sbs.reverse figure {
    margin: 10px 0 0 50px;
  }
}
.new-wpContent .sbs figure {
  margin: 0 0 20px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .sbs figure {
    flex: 0 0 auto;
    margin: 10px 50px 0 0;
  }
}
.new-wpContent .sbs .txtbox {
  width: 100%;
}
.new-wpContent .sbs p {
  margin: 0;
}
.new-wpContent .category-board {
  margin-top: 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .category-board {
    margin-top: 20px;
  }
}
.new-wpContent .category-board .inner {
  background-color: #fff;
  border-top: 2px solid #dd4b03;
  padding: 10px 15px 15px;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .category-board .inner {
    border-top-width: 3px;
    padding: 10px 35px 30px;
  }
}
.new-wpContent .category-board h6 {
  font-weight: 700;
  line-height: 1.3636363636;
  margin: 10px 0 5px;
  font-size: 16px;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .category-board h6 {
    margin: 20px 0 -5px;
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.new-wpContent .category-board ul {
  margin-bottom: 0;
}
.new-wpContent .category-board > .row1 .category-board__item {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .category-board > .row1 .category-board__item {
    margin-bottom: 30px;
    width: 50%;
    padding: 0 20px;
  }
}
@media screen and (max-width: 767px) {
  .new-wpContent .category-board > .row1 .category-board__item:last-child {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .category-board.type2 .category-board__item {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .category-board.type2 .category-board__item .row {
    display: flex;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .category-board.type2 .category-board__item ul.col-6 {
    margin-left: 40px;
    width: calc(50% - 20px);
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .category-board.type2 .category-board__item ul.col-4 {
    margin-left: 15px;
    width: calc(33.3334% - 20px);
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .category-board.type2 .category-board__item ul:first-child {
    margin-left: 0;
  }
}
.new-wpContent .tablewrap {
  position: relative;
  margin: 35px 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .tablewrap {
    margin: 50px 0;
  }
}
.new-wpContent .tablewrap table {
  margin: 0;
}
@media screen and (max-width: 640px) {
  .new-wpContent .tablewrap__inner {
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
}
@media screen and (max-width: 767px) {
  .new-wpContent .tablewrap__inner > table {
    min-width: 560px;
  }
}
.new-wpContent .tablewrap__inner::-webkit-scrollbar {
  height: 5px;
}
.new-wpContent .tablewrap__inner::-webkit-scrollbar-track {
  background: #eee;
}
.new-wpContent .tablewrap__inner::-webkit-scrollbar-thumb {
  background: #b1b1b1;
}
.new-wpContent .tablewrap .note-scroll {
  position: absolute;
  bottom: 100%;
  left: 0;
  margin: 0 0 5px;
  font-size: 10px;
  font-size: 0.625rem;
}
@media screen and (min-width: 641px) {
  .new-wpContent .tablewrap .note-scroll {
    display: none;
  }
}
.new-wpContent .tablewrap .note {
  margin: 10px 0 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .tablewrap .note {
    margin: 15px 0 0;
  }
}
.new-wpContent .tablewrap .note li {
  margin: 0.5em 0;
  padding-left: 0;
  line-height: 1.5;
  letter-spacing: 0.08em;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .tablewrap .note li {
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent .tablewrap .note li:last-child {
  margin-bottom: 0;
}
.new-wpContent .tablewrap .note li::before {
  display: none;
}
@media screen and (min-width: 1281px) {
  .new-wpContent .table-w-wrap {
    display: flex;
  }
}
@media screen and (min-width: 1281px) {
  .new-wpContent .table-w-wrap-l {
    margin-right: 20px;
    width: 580px;
  }
}
@media screen and (min-width: 1281px) {
  .new-wpContent .table-w-wrap-r {
    margin-left: 20px;
    width: 580px;
  }
}
.new-wpContent table {
  width: 100%;
  margin: 35px 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent table {
    margin: 50px 0;
  }
}
.new-wpContent table.small th,
.new-wpContent table.small td {
  padding: 12px 5px;
  font-size: 10px;
  font-size: 0.625rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent table.small th,
.new-wpContent table.small td {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.new-wpContent table.small th p,
.new-wpContent table.small td p {
  font-size: 10px;
  font-size: 0.625rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent table.small th p,
.new-wpContent table.small td p {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.new-wpContent table.small th li,
.new-wpContent table.small td li {
  font-size: 10px;
  font-size: 0.625rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent table.small th li,
.new-wpContent table.small td li {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.new-wpContent table.small th li::before,
.new-wpContent table.small td li::before {
  top: 0.75em;
}
@media screen and (min-width: 768px) {
  .new-wpContent table.small th li::before,
.new-wpContent table.small td li::before {
    top: 0.75em;
  }
}
.new-wpContent table.small th li > p,
.new-wpContent table.small td li > p {
  line-height: 1.75;
}
@media screen and (min-width: 768px) {
  .new-wpContent table.small th {
    padding: 20px 10px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent table.small td {
    padding: 20px 15px;
  }
}
.new-wpContent table tr:last-child td {
  border-bottom: none;
}
.new-wpContent table tr.close th,
.new-wpContent table tr.close td {
  position: relative;
}
.new-wpContent table tr.close th:first-child::before,
.new-wpContent table tr.close td:first-child::before {
  left: 0;
  width: 100%;
}
.new-wpContent table tr.close th::before {
  content: "";
  position: absolute;
  top: 0;
  left: -1px;
  background: rgba(0, 0, 0, 0.5);
  width: calc(100% + 1px);
  height: 100%;
}
.new-wpContent table tr.close th.borderBottomWhite::before {
  height: calc(100% + 1px);
}
.new-wpContent table tr.close td::before {
  content: "";
  position: absolute;
  top: 0;
  left: -1px;
  background: rgba(0, 0, 0, 0.5);
  width: calc(100% + 1px);
  height: calc(100% + 1px);
}
.new-wpContent table tr.close td.borderBottomNone::before {
  height: 100%;
}
.new-wpContent table tr.close td.borderLeft::before {
  left: -1px;
  width: calc(100% + 1px);
}
.new-wpContent table tr.close td.closeTxt::after {
  content: "受付終了";
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 560px;
  height: 100%;
  font-weight: 700;
  font-size: 15px;
  font-size: 0.9375rem;
}
@media screen and (min-width: 641px) {
  .new-wpContent table tr.close td.closeTxt::after {
    width: 93vw;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent table tr.close td.closeTxt::after {
    left: -40px;
    width: 992px;
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 992px) {
  .new-wpContent table tr.close td.closeTxt::after {
    width: 100vw;
    max-width: 1280px;
  }
}
.new-wpContent table tr.close:last-child td::before {
  height: 100%;
}
.new-wpContent table th,
.new-wpContent table td {
  vertical-align: middle;
  line-height: 1.5;
  letter-spacing: 0.08em;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent table th,
.new-wpContent table td {
    padding: 30px 25px;
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent table th:nth-last-of-type(1),
.new-wpContent table td:nth-last-of-type(1) {
  border-right: none;
}
.new-wpContent table th p,
.new-wpContent table td p {
  margin: 0 0 10px;
  line-height: 1.5;
}
.new-wpContent table th {
  background-color: #e5e5e5;
  border-right: 1px solid #fff;
  padding: 17px 10px;
  text-align: center;
}
.new-wpContent table th.borderBottomWhite {
  border-bottom: 1px solid #fff;
}
.new-wpContent table th.borderRightWhite {
  border-right: 1px solid #fff;
}
.new-wpContent table td {
  background-color: #fff;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 17px 15px;
}
.new-wpContent table td.borderBottomNone {
  border-bottom: none;
}
.new-wpContent table td.borderLeft {
  border-left: 1px solid #e5e5e5;
}
.new-wpContent table td.borderRight {
  border-right: 1px solid #e5e5e5;
}
.new-wpContent table td.bgColor {
  background-color: #f1f0f0;
}
.new-wpContent table td ul:last-child {
  margin: 0 0 -10px;
}
.new-wpContent .wp-img-box {
  margin-top: 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-img-box {
    margin-top: 20px;
  }
}
.new-wpContent .wp-img-box .row1 .col-6 {
  width: 100%;
  padding: 0 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-img-box .row1 .col-6 {
    width: 50%;
    padding: 0 20px;
  }
}
.new-wpContent .wp-img-box .row2 .col-3 {
  width: 50%;
  padding: 0 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-img-box .row2 .col-3 {
    width: 25%;
    padding: 0 15px;
  }
}
.new-wpContent .wp-img-box .row2 .col-4 {
  width: 50%;
  padding: 0 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-img-box .row2 .col-4 {
    width: 33.3333333333%;
    padding: 0 15px;
  }
}
.new-wpContent .wp-img-box__item01 {
  margin-bottom: 10px;
  width: 50%;
  padding: 0 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-img-box__item01 {
    margin-bottom: 30px;
    width: 33.3333333333%;
    padding: 0 15px;
  }
}
.new-wpContent .wp-img-box__item01 figure {
  margin: 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-img-box__item01 figure {
    margin: 0;
  }
}
.new-wpContent .wp-mov-box {
  margin: 20px 0 35px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-mov-box {
    margin: 20px 0 50px;
  }
}
.new-wpContent .wp-mov-box:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-mov-box .row1 .col-6 {
    width: 50%;
    padding: 0 20px;
  }
}
.new-wpContent .wp-mov-box .row2 .col-4 {
  width: 50%;
  padding: 0 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-mov-box .row2 .col-4 {
    width: 33.3333333333%;
    padding: 0 15px;
  }
}
.new-wpContent .wp-mov-box__item {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-mov-box__item {
    margin-bottom: 0;
  }
}
.new-wpContent .wp-mov-box__item:last-child {
  margin-bottom: 0;
}
.new-wpContent .wp-mov-box__item .wp-mov {
  padding-top: 56.25%;
  position: relative;
}
.new-wpContent .wp-mov-box__item .wp-mov iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.new-wpContent .wp-mov-box__item p {
  margin: 10px 0;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-mov-box__item p {
    margin: 10px 0;
  }
}
.new-wpContent .wp-map {
  position: relative;
  margin: 35px 0;
  padding-top: 56.25%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-map {
    margin: 50px 0;
    padding-top: 35%;
  }
}
.new-wpContent .wp-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.new-wpContent .wp-modal-g-box {
  margin-top: 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-modal-g-box {
    margin-top: 20px;
  }
}
.new-wpContent .wp-modal-g-box__item {
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
  width: 50%;
  padding: 0 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-modal-g-box__item {
    margin-bottom: 40px;
    width: 33.3333333333%;
    padding: 0 15px;
  }
}
.new-wpContent .wp-modal-g-box a {
  display: block;
  height: 100%;
  transition: all 0.2s ease;
}
.new-wpContent .wp-modal-g-box a figure {
  position: relative;
}
.new-wpContent .wp-modal-g-box a figure::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background-image: url(../img/common/icon_search_w.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 2;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-modal-g-box a figure::before {
    bottom: 6px;
    right: 7px;
    width: 28px;
    height: 28px;
  }
}
.new-wpContent .wp-modal-g-box a figure::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background-color: #dd4b03;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-modal-g-box a figure::after {
    width: 40px;
    height: 40px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-modal-g-box a:hover figure img {
    transform: scale(1.05);
  }
}
.new-wpContent .wp-modal-g-box figure {
  margin: 0;
  width: 100%;
  overflow: hidden;
}
.new-wpContent .wp-modal-g-box figure img {
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.new-wpContent .wp-modal-g-box .txtbox {
  position: relative;
  margin-top: 7px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-modal-g-box .txtbox {
    margin-top: 10px;
  }
}
.new-wpContent .wp-modal-g-box p {
  margin: 0;
  line-height: 1.5;
}
.new-wpContent .m-btn-wrap {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn-wrap {
    margin-bottom: 45px;
  }
}
.new-wpContent .m-btn-wrap .row {
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn-wrap .row {
    flex-direction: row;
    margin: 0 -15px -30px;
  }
}
@media screen and (max-width: 767px) {
  .new-wpContent .m-btn-wrap .row.row-c {
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn-wrap .row.row-c {
    justify-content: center;
  }
}
.new-wpContent .m-btn-wrap .row .m-btn {
  margin: 0 0 15px;
  max-width: 380px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn-wrap .row .m-btn {
    margin-bottom: 30px;
    margin-left: 1.2195121951%;
    margin-right: 1.2195121951%;
  }
}
@media screen and (max-width: 767px) {
  .new-wpContent .m-btn-wrap .row .m-btn:last-child {
    margin-bottom: 0;
  }
}
.new-wpContent .m-btn-wrap .row .m-btn2 {
  margin: 0 0 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn-wrap .row .m-btn2 {
    margin-bottom: 30px;
    margin-left: 1.2195121951%;
    margin-right: 1.2195121951%;
  }
}
@media screen and (max-width: 767px) {
  .new-wpContent .m-btn-wrap .row .m-btn2:last-child {
    margin-bottom: 0;
  }
}
.new-wpContent .m-btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  margin: auto;
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn::after {
    right: 30px;
  }
}
.new-wpContent .m-btn.window::after {
  background-image: url("/hs/wp-content/themes/ghs/img/common/icon_window.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.window::after {
    width: 15px;
    height: 15px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.window:hover::after {
    background-image: url("/hs/wp-content/themes/ghs/img/common/icon_window_w.svg");
  }
}
.new-wpContent .m-btn.dl::after {
  background-image: url("/hs/wp-content/themes/ghs/img/common/icon_dl.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.dl::after {
    width: 15px;
    height: 15px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.dl:hover::after {
    background-image: url("/hs/wp-content/themes/ghs/img/common/icon_dl_w.svg");
  }
}
.new-wpContent .m-btn.doc::after {
  background-image: url("/hs/wp-content/themes/ghs/img/common/icon_doc.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px;
  height: 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.doc::after {
    width: 15px;
    height: 18px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.doc:hover::after {
    background-image: url("/hs/wp-content/themes/ghs/img/common/icon_doc_w.svg");
  }
}
.new-wpContent .m-btn.xls::after {
  background-image: url("/hs/wp-content/themes/ghs/img/common/icon_xls.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px;
  height: 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.xls::after {
    width: 15px;
    height: 18px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.xls:hover::after {
    background-image: url("/hs/wp-content/themes/ghs/img/common/icon_xls_w.svg");
  }
}
.new-wpContent .m-btn.pdf::after {
  background-image: url("/hs/wp-content/themes/ghs/img/common/icon_pdf.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px;
  height: 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.pdf::after {
    width: 15px;
    height: 18px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.pdf:hover::after {
    background-image: url("/hs/wp-content/themes/ghs/img/common/icon_pdf_w.svg");
  }
}
.new-wpContent .m-btn.long {
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.long {
    width: 100%;
  }
}
.new-wpContent .m-btn.big {
  margin: 15px auto 0;
  padding: 5px 35px;
  max-width: 100%;
  height: 70px;
  border-radius: 35px;
  font-size: 16px;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.big {
    margin: 30px auto 0;
    padding: 5px 50px;
    width: 100%;
    height: 120px;
    border-radius: 60px;
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.new-wpContent .m-btn.big::after {
  right: 20px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.big::after {
    right: 35px;
  }
}
.new-wpContent .m-btn.big.arw::after {
  width: 10px;
  height: 10px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.big.arw::after {
    width: 13px;
    height: 13px;
  }
}
.new-wpContent .m-btn.big.window::after {
  width: 14px;
  height: 14px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.big.window::after {
    width: 17px;
    height: 17px;
  }
}
.new-wpContent .m-btn.big.dl::after {
  width: 15px;
  height: 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.big.dl::after {
    width: 18px;
    height: 18px;
  }
}
.new-wpContent .m-btn.big.doc::after {
  width: 15px;
  height: 18px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.big.doc::after {
    width: 18px;
    height: 22px;
  }
}
.new-wpContent .m-btn.big.xls::after {
  width: 15px;
  height: 18px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.big.xls::after {
    width: 18px;
    height: 22px;
  }
}
.new-wpContent .m-btn.big.pdf::after {
  width: 15px;
  height: 18px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn.big.pdf::after {
    width: 18px;
    height: 22px;
  }
}
.new-wpContent .m-btn2 {
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  border-left: 1px solid #dd4b03;
  margin: 15px auto 0;
  padding: 15px 30px 15px 20px;
  width: 100%;
  max-width: 380px;
  box-shadow: 1px 3px 30px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2 {
    border-left-width: 2px;
    margin: 30px auto 0;
    padding: 33px 50px 33px 35px;
    box-shadow: 2.5px 6.5px 72px 7px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent .m-btn2::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  margin: auto;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2::after {
    right: 25px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2:hover {
    color: #dd4b03;
  }
}
.new-wpContent .m-btn2.arw::after {
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.arw::after {
    border-top-width: 3px;
    border-right-width: 3px;
    width: 9px;
    height: 9px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.arw:hover::after {
    border-top-color: #dd4b03;
    border-right-color: #dd4b03;
  }
}
.new-wpContent .m-btn2.window::after {
  background-image: url("/hs/wp-content/themes/ghs/img/common/icon_window2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.window::after {
    width: 14px;
    height: 14px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.window:hover::after {
    background-image: url("/hs/wp-content/themes/ghs/img/common/icon_window2_o.svg");
  }
}
.new-wpContent .m-btn2.dl::after {
  background-image: url("/hs/wp-content/themes/ghs/img/common/icon_dl.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.dl::after {
    width: 15px;
    height: 15px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.dl:hover::after {
    background-image: url("/hs/wp-content/themes/ghs/img/common/icon_dl_o.svg");
  }
}
.new-wpContent .m-btn2.doc::after {
  background-image: url("/hs/wp-content/themes/ghs/img/common/icon_doc.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px;
  height: 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.doc::after {
    width: 15px;
    height: 18px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.doc:hover::after {
    background-image: url("/hs/wp-content/themes/ghs/img/common/icon_doc_o.svg");
  }
}
.new-wpContent .m-btn2.xls::after {
  background-image: url("/hs/wp-content/themes/ghs/img/common/icon_xls.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px;
  height: 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.xls::after {
    width: 15px;
    height: 18px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.xls:hover::after {
    background-image: url("/hs/wp-content/themes/ghs/img/common/icon_xls_o.svg");
  }
}
.new-wpContent .m-btn2.pdf::after {
  background-image: url("/hs/wp-content/themes/ghs/img/common/icon_pdf.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px;
  height: 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.pdf::after {
    width: 15px;
    height: 18px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.pdf:hover::after {
    background-image: url("/hs/wp-content/themes/ghs/img/common/icon_pdf_o.svg");
  }
}
.new-wpContent .m-btn2.long {
  width: 100%;
  max-width: 100%;
}
.new-wpContent .m-btn2.big {
  padding-right: 40px;
  max-width: 100%;
  min-height: 65px;
  font-weight: 700;
  letter-spacing: 0.08em;
  font-size: 16px;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.big {
    padding-right: 70px;
    min-height: 130px;
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.new-wpContent .m-btn2.big::after {
  right: 20px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.big::after {
    right: 35px;
  }
}
.new-wpContent .m-btn2.big.arw::after {
  width: 10px;
  height: 10px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.big.arw::after {
    width: 13px;
    height: 13px;
  }
}
.new-wpContent .m-btn2.big.window::after {
  width: 14px;
  height: 14px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.big.window::after {
    width: 17px;
    height: 17px;
  }
}
.new-wpContent .m-btn2.big.dl::after {
  width: 15px;
  height: 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.big.dl::after {
    width: 18px;
    height: 18px;
  }
}
.new-wpContent .m-btn2.big.doc::after {
  width: 15px;
  height: 18px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.big.doc::after {
    width: 18px;
    height: 22px;
  }
}
.new-wpContent .m-btn2.big.xls::after {
  width: 15px;
  height: 18px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.big.xls::after {
    width: 18px;
    height: 22px;
  }
}
.new-wpContent .m-btn2.big.pdf::after {
  width: 15px;
  height: 18px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2.big.pdf::after {
    width: 18px;
    height: 22px;
  }
}
.new-wpContent .m-btn2 .thumb {
  display: block;
  margin-right: 15px;
  width: 80px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-btn2 .thumb {
    margin-right: 45px;
    width: 150px;
  }
}
.new-wpContent .wp-point-box {
  margin: 10px 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-point-box {
    margin: 20px 0;
  }
}
.new-wpContent .wp-point-box .row2 {
  counter-reset: number 0;
}
.new-wpContent .wp-point-box .row2 .col-2_5 {
  margin-bottom: 15px;
  width: 50%;
  padding: 0 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-point-box .row2 .col-2_5 {
    margin-bottom: 25px;
    width: 20%;
    padding: 0 15px;
  }
}
.new-wpContent .wp-point-box .row2 .col-2_5::before {
  left: 5px;
  width: calc(100% - 10px);
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-point-box .row2 .col-2_5::before {
    left: 15px;
    width: calc(100% - 30px);
  }
}
.new-wpContent .wp-point-box .row2 .col-3 {
  margin-bottom: 15px;
  width: 50%;
  padding: 0 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-point-box .row2 .col-3 {
    margin-bottom: 25px;
    width: 25%;
    padding: 0 15px;
  }
}
.new-wpContent .wp-point-box .row2 .col-3::before {
  left: 5px;
  width: calc(100% - 10px);
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-point-box .row2 .col-3::before {
    left: 15px;
    width: calc(100% - 30px);
  }
}
.new-wpContent .wp-point-box .row2 .col-4 {
  margin-bottom: 15px;
  width: 50%;
  padding: 0 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-point-box .row2 .col-4 {
    margin-bottom: 25px;
    width: 33.3333333333%;
    padding: 0 15px;
  }
}
.new-wpContent .wp-point-box .row2 .col-4::before {
  left: 5px;
  width: calc(100% - 10px);
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-point-box .row2 .col-4::before {
    left: 15px;
    width: calc(100% - 30px);
  }
}
.new-wpContent .wp-point-box__item {
  position: relative;
  z-index: 0;
}
.new-wpContent .wp-point-box__item::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  background: #fff;
  height: 100%;
}
.new-wpContent .wp-point-box .txtbox {
  background-color: #fff;
  padding: 15px 10px;
  border-top: 2px solid #dd4b03;
  position: relative;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-point-box .txtbox {
    border-top-width: 3px;
    padding: 30px 10px;
  }
}
.new-wpContent .wp-point-box .icon {
  padding: 5px 0 12px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-point-box .icon {
    padding: 10px 0 35px;
  }
}
.new-wpContent .wp-point-box dt {
  padding: 7px 0;
}
.new-wpContent .wp-point-box dt::before {
  counter-increment: number 1;
  content: "POINT." counter(number);
  display: block;
  color: #dd4b03;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.08em;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-point-box dt::before {
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent .wp-point-box dd {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222;
  min-height: 3.75em;
  font-weight: bold;
  line-height: 1.3636363636;
  font-size: 15px;
  font-size: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-point-box dd {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.new-wpContent .wp-figcap-box {
  margin: 20px 0 35px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-figcap-box {
    margin: 20px 0 50px;
  }
}
.new-wpContent .wp-figcap-box:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-figcap-box .row1 .col-6 {
    width: 50%;
    padding: 0 20px;
  }
}
.new-wpContent .wp-figcap-box .row2 .col-4 {
  width: 50%;
  padding: 0 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-figcap-box .row2 .col-4 {
    width: 33.3333333333%;
    padding: 0 15px;
  }
}
.new-wpContent .wp-figcap-box__item {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-figcap-box__item {
    margin-bottom: 0;
  }
}
.new-wpContent .wp-figcap-box__item:last-child {
  margin-bottom: 0;
}
.new-wpContent .wp-figcap-box__item figure {
  position: relative;
  margin: 0;
  width: 100%;
}
.new-wpContent .wp-figcap-box__item p {
  margin: 10px 0;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-figcap-box__item p {
    margin: 10px 0;
  }
}
.new-wpContent .wp-guide-box-l {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-guide-box-l {
    margin-top: 30px;
  }
}
.new-wpContent .wp-guide-box-l__item {
  background: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-guide-box-l__item {
    margin-bottom: 50px;
    padding: 40px 60px;
  }
}
.new-wpContent .wp-guide-box-l figure {
  margin: 0 !important;
  width: 37.2549019608%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-guide-box-l figure {
    width: 41.6666666667%;
  }
}
.new-wpContent .wp-guide-box-l .txtbox {
  position: relative;
  margin-left: 3.9215686275%;
  width: 58.8235294118%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-guide-box-l .txtbox {
    margin-left: 4.6296296296%;
    width: 53.7037037037%;
  }
}
.new-wpContent .wp-guide-box-l h6 {
  color: #222;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 15px;
  font-size: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-guide-box-l h6 {
    margin-bottom: 25px;
    font-size: 28px;
    font-size: 1.75rem;
  }
}
.new-wpContent .wp-guide-box-l p {
  margin: 0 !important;
}
.new-wpContent .wp-guide-box {
  margin: 20px 0 30px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-guide-box {
    margin: 30px 0 50px;
  }
}
.new-wpContent .wp-guide-box .row1 .col-6 {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-guide-box .row1 .col-6 {
    margin-bottom: 50px;
    width: 50%;
    padding: 0 20px;
  }
}
.new-wpContent .wp-guide-box__item {
  position: relative;
  z-index: 0;
}
.new-wpContent .wp-guide-box__item .inner {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 20px 15px;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-guide-box__item .inner {
    padding: 30px 35px;
  }
}
.new-wpContent .wp-guide-box figure {
  margin: 0 !important;
  width: 37.2549019608%;
}
.new-wpContent .wp-guide-box .txtbox {
  position: relative;
  margin-left: 3.9215686275%;
  width: 58.8235294118%;
}
.new-wpContent .wp-guide-box h6 {
  color: #222;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 15px;
  font-size: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-guide-box h6 {
    margin-bottom: 25px;
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.new-wpContent .wp-guide-box p {
  margin: 0 !important;
}
.new-wpContent .capmpus-event-cal__item {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .capmpus-event-cal__item {
    margin-bottom: 60px;
  }
}
.new-wpContent .capmpus-event-cal__item:last-child {
  margin-bottom: 0;
}
.new-wpContent .capmpus-event-cal__item:last-child::before {
  display: none;
}
.new-wpContent .capmpus-event-cal__item::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 31px;
  background: #ddd;
  width: 2px;
  height: calc(100% + 35px);
}
@media screen and (min-width: 768px) {
  .new-wpContent .capmpus-event-cal__item::before {
    left: 74px;
    width: 3px;
    height: calc(100% + 60px);
  }
}
.new-wpContent .capmpus-event-cal__item .month {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  background: #dd4b03;
  color: #fff;
  width: 64px;
  height: 64px;
  font-weight: 700;
  line-height: 1.15;
  letter-spacing: 0.08em;
  font-size: 24px;
  font-size: 1.5rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .capmpus-event-cal__item .month {
    width: 150px;
    height: 150px;
    font-size: 40px;
    font-size: 2.5rem;
  }
}
.new-wpContent .capmpus-event-cal__item .month span {
  display: block;
  margin-top: 0.4em;
  font-size: 60%;
}
.new-wpContent .capmpus-event-cal__item .card {
  border-top: 2px solid #dd4b03;
  background: #fff;
  margin-left: 15px;
  padding: 7px 25px 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .capmpus-event-cal__item .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-width: 4px;
    margin-left: 50px;
    padding: 25px 60px;
  }
}
.new-wpContent .capmpus-event-cal__item .list {
  margin: 0 0 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .capmpus-event-cal__item .list {
    margin-bottom: 0;
    width: 51.1363636364%;
  }
}
.new-wpContent .capmpus-event-cal__item .img {
  margin: 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .capmpus-event-cal__item .img {
    margin-left: 3.4090909091%;
    width: 45.4545454545%;
  }
}
.new-wpContent .gcal {
  text-align: center;
}
.new-wpContent .gcal iframe {
  width: 100%;
  height: 400px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .gcal iframe {
    height: 500px;
  }
}
@media screen and (min-width: 992px) {
  .new-wpContent .gcal iframe {
    height: 600px;
  }
}
.new-wpContent .undergraduate-box {
  margin: -15px 0 10px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .undergraduate-box {
    margin: -25px -10px 45px;
    display: flex;
    flex-wrap: wrap;
  }
}
.new-wpContent .undergraduate-box > .sitemap__block {
  margin-bottom: 25px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .undergraduate-box > .sitemap__block {
    margin-bottom: 30px;
    padding: 0 10px;
    width: 50%;
  }
}
.new-wpContent .sitemap__block {
  margin-bottom: 35px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .sitemap__block {
    margin-bottom: 75px;
  }
}
.new-wpContent .sitemap__block .undergraduate-ttl {
  position: relative;
  padding: 0 0 7px;
  margin: 0 0 15px;
  font-weight: 400;
  letter-spacing: 0.08em;
  font-size: 14px;
  font-size: 0.875rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .sitemap__block .undergraduate-ttl {
    padding: 0 0 12px;
    margin: 0 0 20px;
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.new-wpContent .sitemap__block .undergraduate-ttl::before {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  background-color: #ccc;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
}
.new-wpContent .sitemap__block .undergraduate-ttl.yellow::before {
  background: #dbc157;
}
.new-wpContent .sitemap__block .undergraduate-ttl.yellow a::before {
  border-top-color: #dbc157;
  border-right-color: #dbc157;
}
.new-wpContent .sitemap__block .undergraduate-ttl.orange::before {
  background: #e18900;
}
.new-wpContent .sitemap__block .undergraduate-ttl.orange a::before {
  border-top-color: #e18900;
  border-right-color: #e18900;
}
.new-wpContent .sitemap__block .undergraduate-ttl.blue::before {
  background: #58c1cd;
}
.new-wpContent .sitemap__block .undergraduate-ttl.blue a::before {
  border-top-color: #58c1cd;
  border-right-color: #58c1cd;
}
.new-wpContent .sitemap__block .undergraduate-ttl.purple::before {
  background: #b8589d;
}
.new-wpContent .sitemap__block .undergraduate-ttl.purple a::before {
  border-top-color: #b8589d;
  border-right-color: #b8589d;
}
.new-wpContent .sitemap__block .undergraduate-ttl.green::before {
  background: #00af84;
}
.new-wpContent .sitemap__block .undergraduate-ttl.green a::before {
  border-top-color: #00af84;
  border-right-color: #00af84;
}
.new-wpContent .sitemap__block .undergraduate-ttl.pink::before {
  background: #ed7986;
}
.new-wpContent .sitemap__block .undergraduate-ttl.pink a::before {
  border-top-color: #ed7986;
  border-right-color: #ed7986;
}
.new-wpContent .sitemap__block .undergraduate-ttl a {
  position: relative;
  padding-left: 20px;
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .new-wpContent .sitemap__block .undergraduate-ttl a {
    padding-left: 25px;
  }
}
.new-wpContent .sitemap__block .undergraduate-ttl a::before {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0;
  border-top: 2px solid #dd4b03;
  border-right: 2px solid #dd4b03;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .new-wpContent .sitemap__block .undergraduate-ttl a::before {
    top: 0.55em;
    border-top-width: 3px;
    border-right-width: 3px;
    width: 9px;
    height: 9px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .sitemap__block .undergraduate-ttl a:hover {
    opacity: 0.7;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .sitemap__list {
    margin: 0 -10px;
    display: flex;
    flex-wrap: wrap;
  }
}
.new-wpContent .sitemap__item {
  padding: 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .sitemap__item {
    padding: 0 10px;
    width: 50%;
  }
}
.new-wpContent .sitemap__item::before {
  display: none;
}
.new-wpContent .sitemap__item a {
  position: relative;
  display: block;
  padding: 5px 0 5px 20px;
  letter-spacing: 0.08em;
  transition: all 0.2s ease;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .sitemap__item a {
    padding: 0 0 0 25px;
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent .sitemap__item a::before {
  content: "";
  position: absolute;
  top: calc(0.5em + 5px);
  left: 0;
  border-top: 2px solid #dd4b03;
  border-right: 2px solid #dd4b03;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .new-wpContent .sitemap__item a::before {
    top: 0.65em;
    border-top-width: 3px;
    border-right-width: 3px;
    width: 9px;
    height: 9px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .sitemap__item a:hover {
    opacity: 0.7;
  }
}
.new-wpContent .arowlink {
  position: relative;
  padding-left: 18px;
}
.new-wpContent .arowlink::before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 7px;
  height: 7px;
  border-top: 2px solid #dd4b03;
  border-right: 2px solid #dd4b03;
  transform: rotate(45deg);
}
.new-wpContent .sec-search-results {
  margin-top: 55px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .sec-search-results {
    margin-top: 105px;
  }
}
.new-wpContent .sec-search-results__area {
  background: #fff;
  padding: 16px 10px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .sec-search-results__area {
    padding: 40px 80px 60px;
  }
}
.new-wpContent .sec-search-results table {
  margin: 0;
}
.new-wpContent .sec-search-results table td {
  border: none;
  padding: 0;
}
.new-wpContent .obogNav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.new-wpContent .obogNav__item {
  display: block;
  border: 1px solid #dd4b03;
  font-size: 13px;
  color: #dd4b03;
  padding: 7px 10px;
  margin-bottom: 8px;
  width: 49%;
  background-color: #fff;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .new-wpContent .obogNav__item {
    width: auto;
    width: 31.6666666667%;
    font-size: 17px;
    margin-bottom: 0;
    padding: 28px 14px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .obogNav__item:hover {
    color: #fff;
    background-color: #dd4b03;
  }
}
.new-wpContent .obogInterviewbox {
  background-color: #fff;
  padding: 1px 16px 16px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .obogInterviewbox {
    padding: 24px 70px 70px 70px;
  }
}
.new-wpContent .obogInterviewbox:nth-child(n+2) {
  margin-top: 45px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .obogInterviewbox__flex {
    display: flex;
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .obogInterviewbox__fig {
    width: 41.5%;
    margin-left: 4.7%;
    margin-top: 10px;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .obogInterviewbox__txtbox {
    width: 53.8%;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .obogInterviewbox__txt {
    margin-top: 0;
  }
}
.new-wpContent .obogInterviewbox__name {
  border-bottom: 1px solid #dd4b03;
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .new-wpContent .obogInterviewbox__name {
    font-size: 22px;
  }
}
.new-wpContent .obogInterviewbox__employ {
  font-weight: bold;
}
.new-wpContent .obogInterviewbox__employ .p02 {
  color: #dd4b03;
}
@media screen and (min-width: 768px) {
  .new-wpContent .obogInterviewbox__employ .p02 {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent #campusmap .m-btn-wrap {
    margin-top: -110px;
  }
}

.new-wpContent {
  /* ------- message ------- */
}
.new-wpContent .outline-philosophy-box {
  background: #fff;
  padding: 40px 20px 50px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-philosophy-box {
    padding: 80px 45px 100px;
  }
}
.new-wpContent .outline-philosophy-box__tit {
  padding: 0;
  margin: 0 0 20px;
  color: #333;
  font-family: "Noto Serif JP", serif;
  font-weight: 400;
  text-align: center;
  line-height: 1.35;
  letter-spacing: 0.08em;
  font-size: 32px;
  font-size: 2rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-philosophy-box__tit {
    margin-bottom: 40px;
    font-size: 50px;
    font-size: 3.125rem;
  }
}
.new-wpContent .outline-philosophy-box__tit::before {
  display: none;
}
.new-wpContent .outline-philosophy-box__tit span {
  position: relative;
  z-index: 0;
  display: inline-block;
}
.new-wpContent .outline-philosophy-box__tit span::before {
  content: "";
  position: absolute;
  z-index: -2;
  top: 60%;
  left: -0.7em;
  background: #333;
  width: calc(100% + 1.4em);
  height: 3px;
  transform: translateY(-50%);
}
.new-wpContent .outline-philosophy-box__tit span::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: -0.4em;
  background: #fff;
  width: calc(100% + 0.8em);
  height: 100%;
}
.new-wpContent .outline-philosophy-box__lead {
  text-align: center;
  margin: 10px 0 25px;
  line-height: 1.875;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-philosophy-box__lead {
    margin: 20px 0 45px;
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent .outline-philosophy-box__list {
  counter-reset: number 0;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-philosophy-box__list {
    max-width: 865px;
  }
}
.new-wpContent .outline-philosophy-box__item {
  position: relative;
  z-index: 0;
  color: #333;
  font-family: "Noto Serif JP", serif;
  padding: 0 0 5px 35px;
  margin: 0 0 15px;
  line-height: 1.5;
  font-size: 20px;
  font-size: 1.25rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-philosophy-box__item {
    padding-left: 80px;
    margin: 0 0 30px;
    font-size: 30px;
    font-size: 1.875rem;
  }
}
.new-wpContent .outline-philosophy-box__item::before {
  counter-increment: number 1;
  content: counter(number);
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  width: auto;
  height: auto;
}
.new-wpContent .outline-philosophy-box__item::after {
  content: "";
  position: absolute;
  bottom: -0.2em;
  left: 25px;
  z-index: -1;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  width: calc(100% - 25px);
  height: 100%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-philosophy-box__item::after {
    left: 55px;
    width: calc(100% - 55px);
  }
}
.new-wpContent .outline-history__list {
  border-top: 1px solid #ccc;
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-history__list {
    margin-top: 80px;
  }
}
.new-wpContent .outline-history__item {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-history__item {
    padding: 20px 0;
  }
}
.new-wpContent .outline-history__item dt,
.new-wpContent .outline-history__item dd {
  line-height: 1.875;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-history__item dt,
.new-wpContent .outline-history__item dd {
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent .outline-history__item dt {
  border-right: 1px solid #ccc;
  width: 95px;
  flex: none;
  text-align: center;
  font-weight: 700;
  padding: 5px 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-history__item dt {
    width: 165px;
  }
}
.new-wpContent .outline-history__item dd {
  padding: 5px 10px 5px 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-history__item dd {
    padding: 5px 30px 5px 50px;
  }
}
.new-wpContent .outline-merit {
  position: relative;
  z-index: 0;
  padding: 30px 0 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit {
    padding: 120px 0 0;
  }
}
.new-wpContent .outline-merit::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 138px;
  left: 5.85%;
  right: 5.85%;
  background: #fff;
  margin: auto;
  max-width: 1600px;
  height: calc(100% - 138px);
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit::before {
    left: 40px;
    right: 40px;
    top: 230px;
    height: calc(100% - 230px);
  }
}
.new-wpContent .outline-merit::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 138px;
  right: 0;
  background: #fff;
  width: 50%;
  height: calc(100% - 138px);
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit::after {
    top: 230px;
    height: calc(100% - 230px);
  }
}
.new-wpContent .outline-merit .container {
  position: relative;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit .container {
    padding: 0 40px 0 80px;
    max-width: 1320px;
  }
}
@media screen and (min-width: 1331px) {
  .new-wpContent .outline-merit .container {
    padding: 0 60px;
    max-width: 1320px;
  }
}
.new-wpContent .outline-merit .course-tag {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit .course-tag {
    flex-wrap: nowrap;
  }
}
.new-wpContent .outline-merit .course-tag p {
  margin: 0;
  padding: 0 6px;
  width: 50%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit .course-tag p {
    width: 25%;
  }
}
.new-wpContent .outline-merit .course-tag p.active span {
  background: #fff;
}
.new-wpContent .outline-merit .course-tag p span {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #dd4b03;
  background: transparent;
  height: 54px;
  line-height: 1.4;
  font-weight: 700;
  font-size: 13px;
  font-size: 0.8125rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit .course-tag p span {
    height: 110px;
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 767px) {
  .new-wpContent .outline-merit .course-tag-box {
    padding-left: 7%;
  }
}
.new-wpContent .outline-merit .course-tag-box__inner {
  display: none;
  padding-bottom: 50px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit .course-tag-box__inner {
    padding-bottom: 115px;
  }
}
.new-wpContent .outline-merit .course-tag-box__inner.active {
  display: block;
}
.new-wpContent .outline-merit__list {
  margin: 40px 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit__list {
    display: flex;
    margin: 60px -25px;
    padding-top: 100px;
  }
}
.new-wpContent .outline-merit__item {
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit__item {
    width: 33.3334%;
    margin: 0;
    padding: 0 25px;
  }
}
.new-wpContent .outline-merit__item:nth-of-type(2) {
  margin-top: 30px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit__item:nth-of-type(2) {
    margin: 0;
    transform: translateY(-50px);
  }
}
.new-wpContent .outline-merit__item:nth-of-type(3) {
  margin-top: 30px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit__item:nth-of-type(3) {
    margin: 0;
    transform: translateY(-100px);
  }
}
.new-wpContent .outline-merit__item:nth-of-type(3) .card::before, .new-wpContent .outline-merit__item:nth-of-type(3) .card::after {
  display: none;
}
.new-wpContent .outline-merit__item::before {
  display: none;
}
.new-wpContent .outline-merit__item .card {
  position: relative;
  z-index: 1;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 5px 5px 0;
  padding: 25px 20px 35px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit__item .card {
    padding: 35px 30px 50px;
    border-radius: 0 10px 10px 0;
    height: 100%;
  }
}
@media screen and (min-width: 992px) {
  .new-wpContent .outline-merit__item .card {
    padding: 50px 45px 70px;
  }
}
.new-wpContent .outline-merit__item .card::before, .new-wpContent .outline-merit__item .card::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 15px 0 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit__item .card::before, .new-wpContent .outline-merit__item .card::after {
    top: 0;
    bottom: 0;
    left: 100%;
    right: auto;
    border-width: 30px 0 30px 15px;
  }
}
.new-wpContent .outline-merit__item .card::before {
  z-index: -1;
  border-color: #fff transparent transparent transparent;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit__item .card::before {
    border-color: transparent transparent transparent #fff;
  }
}
.new-wpContent .outline-merit__item .card::after {
  z-index: -2;
  transform: scale(1.1);
  transform-origin: top;
  border-color: #ccc transparent transparent transparent;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit__item .card::after {
    border-color: transparent transparent transparent #ccc;
    transform-origin: left;
  }
}
.new-wpContent .outline-merit__item .tit-en {
  position: absolute;
  top: -1em;
  left: 0;
  z-index: 1;
  color: #dd4b03;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin: 0;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit__item .tit-en {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
.new-wpContent .outline-merit__item .tit-en::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: #fff;
  width: calc(100% + 15px);
  height: 100%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit__item .tit-en::before {
    width: calc(100% + 20px);
  }
}
.new-wpContent .outline-merit__item .icon {
  margin: 0 0 10px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit__item .icon {
    margin: 0 0 17px;
  }
}
.new-wpContent .outline-merit__item .tit-jp {
  color: #333;
  margin: 0 0 15px;
  font-weight: 700;
  letter-spacing: 0.08em;
  text-align: center;
  line-height: 1.35;
  font-size: 16px;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .outline-merit__item .tit-jp {
    margin: 0 0 25px;
    font-size: 24px;
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .message-heading-wrap h2 {
    margin: 0 45px 0 0;
    width: 255px;
  }
}
.new-wpContent .message-heading-wrap .message-sig {
  margin-top: 15px;
  text-align: right;
}
@media screen and (min-width: 768px) {
  .new-wpContent .message-heading-wrap .message-sig {
    margin-top: 5px;
  }
}
.new-wpContent .message-heading-wrap .message-sig span {
  font-size: 140%;
}
.new-wpContent .message-image {
  margin: auto;
  width: 83.3333333333%;
}

.new-wpContent .course-link {
  position: relative;
  z-index: 0;
  padding: 50px 0 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-link {
    padding: 125px 0 0;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-link::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 40px;
    right: 40px;
    background: #fff;
    margin: auto;
    max-width: 1600px;
    height: 100%;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-link::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    background: #fff;
    width: 50%;
    height: 100%;
  }
}
.new-wpContent .course-link .container {
  position: relative;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-link .container {
    padding: 0 40px 0 80px;
    max-width: 1320px;
  }
}
@media screen and (min-width: 1331px) {
  .new-wpContent .course-link .container {
    padding: 0 60px;
    max-width: 1320px;
  }
}
.new-wpContent .course-link__list {
  position: relative;
  z-index: 0;
  margin-left: 5.85%;
  padding-bottom: 50px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-link__list {
    margin: 0 -20px;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 992px) {
  .new-wpContent .course-link__list {
    margin: 0 -40px;
  }
}
@media screen and (max-width: 767px) {
  .new-wpContent .course-link__list::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -50px;
    right: 0;
    background: #fff;
    width: 100%;
    height: calc(100% + 50px);
  }
}
.new-wpContent .course-link__item {
  position: relative;
  margin-bottom: 50px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-link__item {
    margin-bottom: 80px;
    width: 50%;
    padding: 0 20px;
  }
}
@media screen and (min-width: 992px) {
  .new-wpContent .course-link__item {
    margin-bottom: 140px;
    width: 50%;
    padding: 0 40px;
  }
}
@media screen and (max-width: 767px) {
  .new-wpContent .course-link__item:last-child {
    margin-bottom: 0;
  }
}
.new-wpContent .course-link__item .catch {
  position: absolute;
  z-index: 1;
  top: -26px;
  right: 4.75%;
  margin: 0;
  width: 64px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-link__item .catch {
    top: -45px;
    right: 14px;
    width: 108px;
  }
}
.new-wpContent .course-link__item .img {
  position: relative;
  padding-left: 7%;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-link__item .img {
    padding-left: 0;
  }
}
.new-wpContent .course-link__item .img img {
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.new-wpContent .course-link__item .txt-wrap {
  padding: 0 7%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-link__item .txt-wrap {
    padding: 0;
  }
}
.new-wpContent .course-link__item .tit {
  position: relative;
  border-bottom: none;
  margin: 15px 0;
  padding: 0 0 7px;
  font-weight: 500;
  line-height: 1.5882352941;
  font-size: 19px;
  font-size: 1.1875rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-link__item .tit {
    margin: 25px 0;
    padding-bottom: 10px;
    font-size: 34px;
    font-size: 2.125rem;
  }
}
.new-wpContent .course-link__item .tit::before {
  display: none;
}
.new-wpContent .course-link__item .tit::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: #dd4b03;
  margin: auto;
  width: 15px;
  height: 2px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-link__item .tit::after {
    width: 20px;
    height: 3px;
  }
}
.new-wpContent .course-img-block {
  position: relative;
}
.new-wpContent .course-img-block__inner {
  padding: 0 3.5%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-img-block__inner {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1790px;
  }
}
.new-wpContent .course-img-block__img {
  text-align: center;
}
.new-wpContent .course-feature__item {
  background: #fff;
  margin-bottom: 20px;
  padding: 20px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-feature__item {
    display: flex;
    margin-bottom: 40px;
    padding: 40px;
  }
}
.new-wpContent .course-feature__item:last-child {
  margin-bottom: 0;
}
.new-wpContent .course-feature__item::before {
  display: none;
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-feature__item .img {
    width: 32.1428571429%;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-feature__item .txt-wrap {
    margin-left: 4.4642857143%;
    width: 63.3928571429%;
  }
}
.new-wpContent .course-feature__item h4 {
  margin: 20px 0 15px;
  line-height: 1.7857142857;
}
@media screen and (min-width: 768px) {
  .new-wpContent .course-feature__item h4 {
    margin: 0 0 25px;
  }
}
.new-wpContent .course-feature__item p {
  margin: 0;
}

.new-wpContent .activity-list {
  display: flex;
  flex-wrap: wrap;
  margin: 30px -5px -15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .activity-list {
    margin: 60px -15px -30px;
  }
}
.new-wpContent .activity-list__card {
  position: relative;
  display: flex;
  align-items: stretch;
  margin-bottom: 30px;
  width: 50%;
  padding: 0 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .activity-list__card {
    margin-bottom: 60px;
    width: 33.3333333333%;
    padding: 0 15px;
  }
}
.new-wpContent .activity-list__card .image {
  position: relative;
  z-index: 1;
  align-self: flex-start;
  width: 89.4736842105%;
  overflow: hidden;
}
.new-wpContent .activity-list__card .text-wrap {
  position: relative;
  z-index: 0;
  background: #fff;
  margin-top: 40%;
  margin-left: -78.9473684211%;
  padding: 30% 15px 55px;
  width: 89.4736842105%;
  box-shadow: 1px 3px 30px 3px rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 768px) {
  .new-wpContent .activity-list__card .text-wrap {
    padding: 30% 30px 105px;
    min-height: 360px;
    box-shadow: 2.5px 6.5px 72px 7px rgba(0, 0, 0, 0.1);
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .activity-list__card .text-wrap:hover .btn {
    background: #dd4b03;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .activity-list__card .text-wrap:hover .btn::before {
    border-top-color: #fff;
    border-right-color: #fff;
  }
}
.new-wpContent .activity-list__card .text-wrap a {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .activity-list__card .text-wrap a {
    bottom: 0;
    left: 0;
    right: auto;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
.new-wpContent .activity-list__card .title {
  display: block;
  color: #333;
  margin: 0 0 5px;
  padding: 0;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.08em;
  font-size: 16px;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .activity-list__card .title {
    margin-bottom: 10px;
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.new-wpContent .activity-list__card .title::before {
  display: none;
}
.new-wpContent .activity-list__card .text {
  margin: 0;
  line-height: 1.5833333333;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .activity-list__card .text {
    line-height: 1.875;
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent .activity-list__card .btn {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 1px 3px 30px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .new-wpContent .activity-list__card .btn {
    position: absolute;
    bottom: 35px;
    width: 60px;
    height: 60px;
    box-shadow: 2.5px 6.5px 72px 7px rgba(0, 0, 0, 0.1);
  }
}
.new-wpContent .activity-list__card .btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 2px;
  border-top: 2px solid #222;
  border-right: 2px solid #222;
  margin: auto;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .new-wpContent .activity-list__card .btn::before {
    border-top-width: 3px;
    border-right-width: 3px;
    width: 9px;
    height: 9px;
  }
}

/* school/event の追加SCSS */
.new-wpContent .event-list {
  position: relative;
  padding: 50px 0 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .event-list {
    padding: 100px 0 0;
  }
}
.new-wpContent .event-list__item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .event-list__item {
    margin-bottom: 50px;
  }
}
.new-wpContent .event-list__item:last-child {
  margin-bottom: 0;
}
.new-wpContent .event-list__item figure {
  position: relative;
  z-index: 1;
  margin-right: -45%;
  width: 55%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .event-list__item figure {
    margin-right: -17.5%;
    width: 30%;
  }
}
.new-wpContent .event-list__item .inner {
  background-color: #fff;
  margin-top: 50px;
  padding: 31.5% 5% 5.5% 20px;
  width: 90%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .event-list__item .inner {
    margin-top: 65px;
    padding: 45px 35px 55px;
    padding-left: 23.3333333333%;
    min-height: 250px;
    width: 87.5%;
  }
}
.new-wpContent .event-list__item h5 {
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: 7px;
  font-size: 20px;
  font-size: 1.25rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .event-list__item h5 {
    margin-bottom: 12px;
    font-size: 28px;
    font-size: 1.75rem;
  }
}
.new-wpContent .event-list__item ul,
.new-wpContent .event-list__item li {
  margin: 0;
}

.new-wpContent .wp-modal-g-box a.media figure::before {
  bottom: 8px;
  right: 8px;
  background-image: url(../img/support/media/icon_plus_w.svg);
  width: 14px;
  height: 14px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-modal-g-box a.media figure::before {
    bottom: 11px;
    right: 11px;
    width: 17px;
    height: 17px;
  }
}

.modaal-inline .modaal-container {
  background: transparent;
  box-shadow: none;
}
.modaal-inline .modaal-content-container {
  padding: 0;
}

.modal-content {
  display: none;
}

.modal-media .mov {
  position: relative;
  padding-bottom: 56.25%;
  max-width: 1000px;
  height: 0;
  overflow: hidden;
}
.modal-media iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-media h4 {
  color: #fff;
  margin: 25px 0 20px;
  font-weight: 700;
  letter-spacing: 0.08em;
  font-size: 20px;
  font-size: 1.25rem;
}
@media screen and (min-width: 768px) {
  .modal-media h4 {
    margin: 35px 0 30px;
    font-size: 32px;
    font-size: 2rem;
  }
}
.modal-media p {
  color: #fff;
  margin: 10px 0 15px;
  line-height: 2.125;
  letter-spacing: 0.08em;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .modal-media p {
    margin: 20px 0 30px;
    font-size: 16px;
    font-size: 1rem;
  }
}
.modal-media .name-tag {
  display: flex;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .modal-media .name-tag {
    margin-bottom: 20px;
  }
}
.modal-media .name-tag .ttl {
  color: #fff;
  margin-top: 4px;
  letter-spacing: 0.08em;
  font-size: 11.5px;
  font-size: 0.71875rem;
}
@media screen and (min-width: 768px) {
  .modal-media .name-tag .ttl {
    margin-top: 8px;
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
.modal-media .name-tag .tag {
  display: flex;
  flex-wrap: wrap;
}
.modal-media .name-tag .tag span {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dd4b03;
  background: #fff;
  color: #dd4b03;
  margin: 0 5px 7px 0;
  padding: 2px 7px;
  min-width: 60px;
  height: 25px;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
  border-radius: 19px;
  font-size: 11.5px;
  font-size: 0.71875rem;
}
@media screen and (min-width: 768px) {
  .modal-media .name-tag .tag span {
    border-width: 2px;
    margin: 0 10px 10px 0;
    padding: 5px 15px 7px;
    min-width: 105px;
    height: 38px;
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

.new-wpContent .m-contact-lead {
  position: relative;
  padding: 45px 0 0;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-contact-lead {
    padding: 120px 0 0;
  }
}
.new-wpContent .m-form-area {
  position: relative;
  z-index: 0;
  margin-top: 50px;
  padding: 30px 0 50px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-form-area {
    margin-top: 90px;
    padding: 60px 0 115px;
  }
}
.new-wpContent .m-form-area::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 5.85%;
  right: 5.85%;
  background: #fff;
  margin: auto;
  max-width: 1600px;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-form-area::before {
    left: 40px;
    right: 40px;
  }
}
.new-wpContent .m-form-area::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  background: #fff;
  width: 50%;
  height: 100%;
}
.new-wpContent .m-form-area .container {
  position: relative;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-form-area .container {
    padding: 0 40px 0 80px;
    max-width: 1320px;
  }
}
@media screen and (min-width: 1331px) {
  .new-wpContent .m-form-area .container {
    padding: 0 60px;
    max-width: 1320px;
  }
}
.new-wpContent .m-form-area__body {
  padding-left: 7%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-form-area__body {
    padding-left: 0;
  }
}
.new-wpContent .m-form-area .form-fir-p {
  text-align: right;
  color: #dd4b03;
  margin: 0;
}

.form1row {
  padding: 10px 0;
}
@media screen and (min-width: 768px) {
  .form1row {
    padding: 20px 0;
  }
}

.form1row:last-child {
  border-bottom: none;
}

@media screen and (min-width: 768px) {
  .form1row .innerflex {
    display: flex;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .form1row .innerflex.flex-start {
    align-items: flex-start;
  }
}
@media screen and (min-width: 768px) {
  .form1row .innerflex.flex-start .left-td {
    padding-top: 15px;
  }
}

.innerflex .left-td {
  width: 100%;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .innerflex .left-td {
    width: 260px;
    margin-bottom: 0;
  }
}
.innerflex .left-td .label {
  font-size: 13px;
  display: block;
}
@media screen and (min-width: 768px) {
  .innerflex .left-td .label {
    font-size: 16px;
  }
}

.label .hissu {
  color: #dd4b03;
  font-size: 11px;
  display: inline-block;
  transform: translate(2px, -1px);
}

.left-td .col-aka {
  color: #dd4b03;
}

.right-borbotombox {
  border-bottom: 1px solid #ddd;
  padding-bottom: 25px;
}
.right-borbotombox .f-r-ttl01 {
  font-size: 20px;
  color: #dd4b03;
  font-weight: bold;
  margin-bottom: 12px;
}
.right-borbotombox .f-r-ttl01--more2 {
  margin-top: 40px;
}
.right-borbotombox .f-r-ttl02 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 8px;
}
.right-borbotombox .f-r-ttl03 {
  font-size: 16px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
}

.right-borbotombox:last-child {
  border-bottom: none;
}

.f-r-subjectFlex {
  display: flex;
  flex-wrap: wrap;
}
.f-r-subjectFlex .f-r-subjectItem {
  font-size: 13px;
  padding: 0 4px;
}
@media screen and (min-width: 768px) {
  .f-r-subjectFlex .f-r-subjectItem {
    font-size: 16px;
  }
}

.right-td .sexradiowrap {
  font-size: 13px;
}
@media screen and (min-width: 768px) {
  .right-td .sexradiowrap {
    font-size: 16px;
  }
}

span.wpcf7-list-item.first {
  margin-left: 0;
}

.right-td .inp-txt input {
  width: 100%;
  height: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  .right-td .inp-txt input {
    padding: 20px 15px;
  }
}

.right-td .inp-txt textarea {
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  width: 100%;
  height: 100%;
  padding: 5 10px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  resize: none;
  min-height: 150px;
}
@media screen and (min-width: 768px) {
  .right-td .inp-txt textarea {
    padding: 20px 15px;
    min-height: 300px;
  }
}

.right-td {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .right-td {
    width: calc(100% - 260px);
  }
}
.right-td .selectwrap {
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  width: 280px;
  position: relative;
}
.right-td .selectwrap::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: calc(50% - 2px);
  width: 12px;
  height: 12px;
  border: 2px solid;
  border-color: #dd4b03 #dd4b03 transparent transparent;
  transform: rotate(135deg);
}

.right-td .selectwrap select {
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  .right-td .selectwrap select {
    padding: 20px 15px;
  }
}

.right-td .gradscoolSelect {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .right-td .gradscoolSelect {
    display: flex;
  }
}
.right-td .gradscoolSelect .gradscoolSelect__label {
  font-size: 13px;
  width: 100px;
  margin-bottom: 6px;
}
@media screen and (min-width: 768px) {
  .right-td .gradscoolSelect .gradscoolSelect__label {
    font-size: 16px;
    margin-bottom: 0;
  }
}

.gradscoolSelect .gradscoolSelect__select {
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  width: 280px;
  position: relative;
}
.gradscoolSelect .gradscoolSelect__select::before {
  transform: rotate(135deg);
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: calc(50% - 2px);
  width: 12px;
  height: 12px;
  border: 2px solid;
  border-color: #dd4b03 #dd4b03 transparent transparent;
}

.right-td .gradscoolSelect__select select {
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  .right-td .gradscoolSelect__select select {
    padding: 10px;
  }
}

.new-wpContent .form-ls-p {
  text-align: center;
  margin: 40px 0;
}

.form-l-buttonwrap {
  margin-top: 30px;
}
@media screen and (min-width: 768px) {
  .form-l-buttonwrap {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}
.form-l-buttonwrap > div {
  margin: 5px 0;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .form-l-buttonwrap > div {
    margin: 0 10px;
    max-width: 370px;
  }
}

.right-td .textarea-caption {
  font-size: 16px;
  margin: 30px 0 10px;
}

.right-td .textarea-top {
  font-size: 14px;
  margin-top: 5px;
}

.gradu1rowwrap {
  font-size: 16px;
  margin-top: 16px;
}
.gradu1rowwrap .gradu1rowInput {
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  width: 70px;
  display: inline-block;
}
.gradu1rowwrap .gradu1rowInput2 {
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  width: 200px;
  display: inline-block;
}

.gradu1rowwrap .gradu1rowInput input {
  width: 100%;
  height: 100%;
  padding: 1px;
}

.gradu1rowwrap .gradu1rowSelectwrap {
  display: inline-block;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  width: 120px;
  position: relative;
}

.gradu1rowwrap .gradu1rowSelectwrap::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  right: 8px;
  bottom: calc(50% - 2px);
  width: 8px;
  height: 8px;
  border: 1px solid;
  border-color: #dd4b03 #dd4b03 transparent transparent;
  transform: rotate(135deg);
  pointer-events: none;
}

.gradu1rowwrap .gradu1rowSelectwrap select {
  width: 100%;
  height: 100%;
  padding: 1px;
}

.inputInlineblockWrap {
  font-size: 16px;
}

.inputInlineblockWrap + .inputInlineblockWrap {
  margin-top: 8px;
}

.inputInlineblockWrap .inputInlineblock {
  display: inline-block;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  width: 180px;
}

.inputInlineblockWrap .inputInlineblock input {
  width: 100%;
  height: 100%;
  padding: 1px;
}

.formgakubuTtl {
  background-color: #eee;
  font-size: 16px;
  font-weight: bold;
  padding: 3px 10px;
  margin-top: 30px;
}

.ttl-underline {
  padding: 6px 0;
  margin-bottom: 6px;
  font-size: 14px;
  border-bottom: 1px dashed #ddd;
  letter-spacing: 0.08em;
}

.formgakkaTtl {
  font-size: 14px;
  margin: 6px 0;
  letter-spacing: 0.08em;
}

div.wpcf7-validation-errors {
  font-size: 16px;
  color: red;
  background-color: #f7e700;
}

div.wpcf7 .screen-reader-response[role*=alert] {
  position: relative;
  clip: unset;
  height: unset;
  width: unset;
  margin: 80px 0 0;
  padding: 10px;
  border: 2px solid #dd4b03;
  text-align: center;
  font-size: 20px;
}

div.wpcf7 .screen-reader-response > ul {
  display: none;
}

div.wpcf7-mail-sent-ok {
  display: none;
}

.hogosyasankaninzuuwrap {
  display: inline-block;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  width: 80px;
}

.hogosyasankaninzuuwrap input {
  width: 100%;
  padding: 1px;
}

.mw_wp_form_confirm .confirm-none {
  display: none;
}

.mw_wp_form_input .confirm-block {
  display: none;
}

.mw_wp_form_confirm .confirm-block {
  display: block;
}

.mw_wp_form_confirm .inp-txt {
  font-size: 16px;
}

.mw_wp_form .horizontal-item {
  display: inline-block;
  margin: 0 10px 0 0 !important;
  padding: 5px 0;
}
@media screen and (min-width: 768px) {
  .mw_wp_form .horizontal-item {
    padding: 5px 0;
  }
}

.form-captcha {
  margin-top: 30px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .form-captcha {
    margin-top: 50px;
  }
}

.new-wpContent {
  /* ------- sorter ------- */
  /* ------- pager ------- */
}
.new-wpContent .m-sorter {
  padding: 45px 0 0;
  margin-bottom: -20px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-sorter {
    padding: 120px 0 0;
    margin-bottom: -20px;
  }
}
.new-wpContent .m-sorter__inner {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.new-wpContent .m-sorter__block {
  position: relative;
  width: 48.5%;
  max-width: 320px;
  margin-left: 3%;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-sorter__block {
    width: 370px;
    margin-left: 20px;
  }
}
.new-wpContent .m-sorter__block:first-child {
  margin-left: 0;
}
.new-wpContent .m-sorter__box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  width: 100%;
  height: 48px;
  margin: 0;
  padding-right: 10px;
  cursor: pointer;
  border-radius: 24px;
  box-shadow: 2px 4px 36px 4px rgba(0, 0, 0, 0.08);
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-sorter__box {
    height: 80px;
    border-radius: 40px;
    box-shadow: 2px 7px 72px 7px rgba(0, 0, 0, 0.08);
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent .m-sorter__box::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: 0;
  width: 6px;
  height: 6px;
  margin: auto;
  border-bottom: 2px solid #222;
  border-left: 2px solid #222;
  transform: rotate(-45deg);
  content: "";
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-sorter__box::before {
    top: -4px;
    right: 30px;
    width: 8px;
    height: 8px;
    border-bottom-width: 3px;
    border-left-width: 3px;
  }
}
.new-wpContent .m-sorter__list {
  position: absolute;
  top: 58px;
  right: 0;
  z-index: 1;
  display: none;
  margin: 0;
  padding: 5px 15px;
  width: 100%;
  background: #fff;
  border-radius: 24px;
  box-shadow: 2px 4px 36px 4px rgba(0, 0, 0, 0.08);
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-sorter__list {
    top: 90px;
    padding: 10px 45px;
    border-radius: 40px;
    box-shadow: 2px 7px 72px 7px rgba(0, 0, 0, 0.08);
  }
}
.new-wpContent .m-sorter__list > li {
  width: 100%;
}
.new-wpContent .m-sorter__list > li:nth-child(n+2) {
  border-top: 1px solid #ccc;
}
.new-wpContent .m-sorter__list > li::before {
  display: none;
}
.new-wpContent .m-sorter__list > li a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 5px 0;
  transition: 0.2s;
  font-feature-settings: "palt";
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-sorter__list > li a {
    height: 70px;
    font-size: 16px;
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .new-wpContent .m-sorter__list > li a:hover {
    color: #dd4b03;
  }
}
.new-wpContent .wp-pagenavi {
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-pagenavi {
    margin-top: 60px;
  }
}
.new-wpContent .wp-pagenavi a,
.new-wpContent .wp-pagenavi span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  width: 28px;
  height: 28px;
  margin: 0 3px;
  padding: 0;
  font-family: "Roboto Condensed", sans-serif;
  border: none;
  border-radius: 50%;
  background: #fff;
  font-size: 12px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-pagenavi a,
.new-wpContent .wp-pagenavi span {
    width: 70px;
    height: 70px;
    margin: 0 7px;
    font-size: 16px;
    font-size: 1rem;
  }
}
.new-wpContent .wp-pagenavi a.nextpostslink,
.new-wpContent .wp-pagenavi a.previouspostslink {
  position: relative;
}
.new-wpContent .wp-pagenavi a.nextpostslink::before,
.new-wpContent .wp-pagenavi a.previouspostslink::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  border-top: 2px solid #333;
  border-right: 2px solid #333;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-pagenavi a.nextpostslink::before,
.new-wpContent .wp-pagenavi a.previouspostslink::before {
    width: 8px;
    height: 8px;
  }
}
.new-wpContent .wp-pagenavi a.nextpostslink::before {
  transform: rotate(45deg);
}
.new-wpContent .wp-pagenavi a.previouspostslink::before {
  transform: rotate(45deg) scale(-1, -1);
}
.new-wpContent .wp-pagenavi a.first,
.new-wpContent .wp-pagenavi a.last {
  position: relative;
  background-color: transparent;
  width: 15px;
  height: 15px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-pagenavi a.first,
.new-wpContent .wp-pagenavi a.last {
    width: 20px;
    height: 20px;
  }
}
.new-wpContent .wp-pagenavi a.first::before, .new-wpContent .wp-pagenavi a.first::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 6px;
  height: 6px;
  border-top: 1px solid #111;
  border-right: 1px solid #111;
  transform: rotate(225deg);
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-pagenavi a.first::before, .new-wpContent .wp-pagenavi a.first::after {
    width: 8px;
    height: 8px;
  }
}
.new-wpContent .wp-pagenavi a.first::before {
  right: 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-pagenavi a.first::before {
    right: 6px;
  }
}
.new-wpContent .wp-pagenavi a.first::after {
  right: 2px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-pagenavi a.first::after {
    right: 0;
  }
}
.new-wpContent .wp-pagenavi a.last::before, .new-wpContent .wp-pagenavi a.last::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  border-top: 1px solid #111;
  border-right: 1px solid #111;
  transform: rotate(45deg);
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-pagenavi a.last::before, .new-wpContent .wp-pagenavi a.last::after {
    width: 8px;
    height: 8px;
  }
}
.new-wpContent .wp-pagenavi a.last::before {
  left: 2px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-pagenavi a.last::before {
    left: 0;
  }
}
.new-wpContent .wp-pagenavi a.last::after {
  left: 5px;
}
@media screen and (min-width: 768px) {
  .new-wpContent .wp-pagenavi a.last::after {
    left: 6px;
  }
}
.new-wpContent .news-pageback {
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .new-wpContent .news-pageback {
    margin-top: 60px;
  }
}
.new-wpContent .entry-title {
  position: relative;
  border-bottom: 1px solid #ccc;
  margin: 15px 0 40px;
  padding: 0 0 15px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.08em;
  font-size: 22px;
  font-size: 1.375rem;
}
@media screen and (min-width: 768px) {
  .new-wpContent .entry-title {
    border-bottom-width: 2px;
    margin: 30px 0 75px;
    padding: 0 0 30px;
    line-height: 2;
    font-size: 36px;
    font-size: 2.25rem;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*!
	Modaal - accessible modals - v0.4.4
	by Humaan, for all humans.
	http://humaan.com
 */
.modaal-noscroll {
  overflow: hidden;
}

.modaal-accessible-hide {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0;
}

.modaal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-in-out;
}
.modaal-wrapper * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
}
.modaal-wrapper .modaal-close {
  border: none;
  background: transparent;
  padding: 0;
  -webkit-appearance: none;
}
.modaal-wrapper.modaal-start_none {
  display: none;
  opacity: 1;
}
.modaal-wrapper.modaal-start_fade {
  opacity: 0;
}
.modaal-wrapper *[tabindex="0"] {
  outline: none !important;
}
.modaal-wrapper.modaal-fullscreen {
  overflow: hidden;
}

.modaal-outer-wrapper {
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
}
.modaal-fullscreen .modaal-outer-wrapper {
  display: block;
}

.modaal-inner-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 80px 25px;
}
.modaal-fullscreen .modaal-inner-wrapper {
  padding: 0;
  display: block;
  vertical-align: top;
}

.modaal-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: left;
  color: #000;
  max-width: 1000px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: auto;
}
.modaal-container.is_loading {
  height: 100px;
  width: 100px;
  overflow: hidden;
}
.modaal-fullscreen .modaal-container {
  max-width: none;
  height: 100%;
  overflow: auto;
}

.modaal-close {
  position: fixed;
  right: 20px;
  top: 20px;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}
.modaal-close:focus, .modaal-close:hover {
  outline: none;
  background: #fff;
}
.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #b93d0c;
}
.modaal-close span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
.modaal-close:before, .modaal-close:after {
  display: block;
  content: " ";
  position: absolute;
  top: 14px;
  left: 23px;
  width: 4px;
  height: 22px;
  border-radius: 4px;
  background: #fff;
  transition: background 0.2s ease-in-out;
}
.modaal-close:before {
  transform: rotate(-45deg);
}
.modaal-close:after {
  transform: rotate(45deg);
}
.modaal-fullscreen .modaal-close {
  background: #afb7bc;
  right: 10px;
  top: 10px;
}

.modaal-content-container {
  padding: 30px;
}

.modaal-confirm-wrap {
  padding: 30px 0 0;
  text-align: center;
  font-size: 0;
}

.modaal-confirm-btn {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: transparent;
}
.modaal-confirm-btn.modaal-ok {
  padding: 10px 15px;
  color: #fff;
  background: #555;
  border-radius: 3px;
  transition: background 0.2s ease-in-out;
}
.modaal-confirm-btn.modaal-ok:hover {
  background: #2f2f2f;
}
.modaal-confirm-btn.modaal-cancel {
  text-decoration: underline;
}
.modaal-confirm-btn.modaal-cancel:hover {
  text-decoration: none;
  color: #2f2f2f;
}

@keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modaal-instagram .modaal-container {
  width: auto;
  background: transparent;
  box-shadow: none !important;
}
.modaal-instagram .modaal-content-container {
  padding: 0;
  background: transparent;
}
.modaal-instagram .modaal-content-container > blockquote {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important;
}
.modaal-instagram iframe {
  opacity: 0;
  margin: -6px !important;
  border-radius: 0 !important;
  width: 1000px !important;
  max-width: 800px !important;
  box-shadow: none !important;
  animation: instaReveal 1s linear forwards;
}

.modaal-image .modaal-inner-wrapper {
  padding-left: 140px;
  padding-right: 140px;
}
.modaal-image .modaal-container {
  width: auto;
  max-width: 100%;
}

.modaal-gallery-wrap {
  position: relative;
  color: #fff;
}

.modaal-gallery-item {
  display: none;
}
.modaal-gallery-item img {
  display: block;
}
.modaal-gallery-item.is_active {
  display: block;
}

.modaal-gallery-label {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  color: #fff;
}
.modaal-gallery-label:focus {
  outline: none;
}

.modaal-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  cursor: pointer;
  color: #fff;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}
.modaal-gallery-control.is_hidden {
  opacity: 0;
  cursor: default;
}
.modaal-gallery-control:focus, .modaal-gallery-control:hover {
  outline: none;
  background: #fff;
}
.modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
  background: #afb7bc;
}
.modaal-gallery-control span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
.modaal-gallery-control:before, .modaal-gallery-control:after {
  display: block;
  content: " ";
  position: absolute;
  top: 16px;
  left: 25px;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  background: #fff;
  transition: background 0.2s ease-in-out;
}
.modaal-gallery-control:before {
  margin: -5px 0 0;
  transform: rotate(-45deg);
}
.modaal-gallery-control:after {
  margin: 5px 0 0;
  transform: rotate(45deg);
}

.modaal-gallery-next-inner {
  left: 100%;
  margin-left: 40px;
}
.modaal-gallery-next-outer {
  right: 45px;
}

.modaal-gallery-prev:before, .modaal-gallery-prev:after {
  left: 22px;
}
.modaal-gallery-prev:before {
  margin: 5px 0 0;
  transform: rotate(-45deg);
}
.modaal-gallery-prev:after {
  margin: -5px 0 0;
  transform: rotate(45deg);
}
.modaal-gallery-prev-inner {
  right: 100%;
  margin-right: 40px;
}
.modaal-gallery-prev-outer {
  left: 45px;
}

.modaal-video-wrap {
  margin: auto 50px;
  position: relative;
}

.modaal-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #000;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
.modaal-video-container iframe,
.modaal-video-container object,
.modaal-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modaal-iframe .modaal-content {
  width: 100%;
  height: 100%;
}

.modaal-iframe-elem {
  width: 100%;
  height: 100%;
  display: block;
}

@media only screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px;
  }
}
@media only screen and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }

  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7);
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    background: #fff;
  }

  .modaal-gallery-next {
    left: auto;
    right: 20px;
  }

  .modaal-gallery-prev {
    left: 20px;
    right: auto;
  }
}
@media screen and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
}
@media screen and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important;
  }
}
@media screen and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .modaal-instagram iframe {
    width: 600px !important;
  }
}
@media screen and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }

  .modaal-video-container {
    max-width: 900px;
    max-height: 510px;
  }
}
@media only screen and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important;
  }
}
@media only screen and (max-height: 820px) {
  .modaal-gallery-label {
    display: none;
  }
}
.modaal-loading-spinner {
  background: none;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  transform: scale(0.25);
}

@-ms-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.modaal-loading-spinner > div {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute;
}

.modaal-loading-spinner > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #fff;
}

.modaal-loading-spinner > div:nth-of-type(1) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(2) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.12s;
  -moz-animation-delay: 0.12s;
  -webkit-animation-delay: 0.12s;
  -o-animation-delay: 0.12s;
  animation-delay: 0.12s;
}

.modaal-loading-spinner > div:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(3) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -webkit-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(4) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.37s;
  -moz-animation-delay: 0.37s;
  -webkit-animation-delay: 0.37s;
  -o-animation-delay: 0.37s;
  animation-delay: 0.37s;
}

.modaal-loading-spinner > div:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(5) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(6) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.62s;
  -moz-animation-delay: 0.62s;
  -webkit-animation-delay: 0.62s;
  -o-animation-delay: 0.62s;
  animation-delay: 0.62s;
}

.modaal-loading-spinner > div:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(7) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.modaal-loading-spinner > div:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(8) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.87s;
  -moz-animation-delay: 0.87s;
  -webkit-animation-delay: 0.87s;
  -o-animation-delay: 0.87s;
  animation-delay: 0.87s;
}

.modaal-loading-spinner > div:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
}