.new-wpContent {
	.course-link {
		position: relative;
		z-index: 0;
		padding: 50px 0 0;

		@include mq-min(s) {
			padding: 125px 0 0;
		}

		&::before {
			@include mq-min(s) {
				content: "";
				position: absolute;
				z-index: -1;
				top: 0;
				left: 40px;
				right: 40px;
				background: $white_color;
				margin: auto;
				max-width: 1600px;
				height: 100%;
			}
		}

		&::after {
			@include mq-min(s) {
				content: "";
				position: absolute;
				z-index: -1;
				top: 0;
				right: 0;
				background: $white_color;
				width: 50%;
				height: 100%;
			}
		}

		.container {
			position: relative;
			padding: 0;

			@include mq-min(s) {
				padding: 0 40px 0 80px;
				max-width: 1320px;
			}

			@media screen and (min-width: 1331px) {
				padding: 0 60px;
				max-width: 1320px;
			}
		}

		&__list {
			position: relative;
			z-index: 0;
			margin-left: 5.85%;
			padding-bottom: 50px;

			@include mq-min(s) {
				margin: 0 -20px;
				padding-bottom: 0;

				@include flex-wrap;
			}

			@include mq-min(sm) {
				margin: 0 -40px;
			}

			&::before {
				@include mq-max(s) {
					content: "";
					position: absolute;
					z-index: -1;
					top: -50px;
					right: 0;
					background: $white_color;
					width: 100%;
					height: calc(100% + 50px);
				}
			}
		}

		&__item {
			position: relative;
			margin-bottom: 50px;

			@include mq-min(s) {
				margin-bottom: 80px;

				@include col(6, 20px);
			}

			@include mq-min(sm) {
				margin-bottom: 140px;

				@include col(6, 40px);
			}

			&:last-child {
				@include mq-max(s) {
					margin-bottom: 0;
				}
			}

			.catch {
				position: absolute;
				z-index: 1;
				top: -26px;
				right: 4.75%;
				margin: 0;
				width: 64px;

				@include mq-min(s) {
					top: -45px;
					right: 14px;
					width: 108px;
				}
			}

			.img {
				position: relative;
				padding-left: 7%;
				overflow: hidden;

				@include mq-min(s) {
					padding-left: 0;
				}

				img {
					transition: all 0.3s cubic-bezier(.25, .46, .45, .94);
				}
			}

			.txt-wrap {
				padding: 0 7%;

				@include mq-min(s) {
					padding: 0;
				}
			}

			.tit {
				position: relative;
				border-bottom: none;
				margin: 15px 0;
				padding: 0 0 7px;
				font-weight: 500;
				line-height: (54 / 34);

				@include fontsize(19);

				@include mq-min(s) {
					margin: 25px 0;
					padding-bottom: 10px;

					@include fontsize(34);
				}

				&::before {
					display: none;
				}

				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					background: $primary_color;
					margin: auto;
					width: 15px;
					height: 2px;

					@include mq-min(s) {
						width: 20px;
						height: 3px;
					}
				}
			}
		}
	}

	.course-img-block {
		position: relative;

		&__inner {
			padding: 0 3.5%;
			width: 100%;

			@include mq-min(s) {
				margin: 0 auto;
				padding: 0 20px;
				max-width: 1790px;
			}
		}

		&__img {
			text-align: center;
		}
	}

	.course-feature {
		&__item {
			background: $white_color;
			margin-bottom: 20px;
			padding: 20px;

			@include mq-min(s) {
				display: flex;
				margin-bottom: 40px;
				padding: 40px;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&::before {
				display: none;
			}

			.img {
				@include mq-min(s) {
					width: percentage(360 / 1120);
				}
			}

			.txt-wrap {
				@include mq-min(s) {
					margin-left: percentage(50 / 1120);
					width: percentage(710 / 1120);
				}
			}

			h4 {
				margin: 20px 0 15px;
				line-height: (50 / 28);

				@include mq-min(s) {
					margin: 0 0 25px;
				}
			}

			p {
				margin: 0;
			}
		}
	}
}
