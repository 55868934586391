/* _reset.scss
--------------------------------------------------------------------------------------- */
html {
	box-sizing: border-box;
	overflow-y: scroll; /* All browsers without overlaying scrollbars */
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%; /* iOS 8+ */
}

*,
::before,
::after {
	background-repeat: no-repeat; /* Set `background-repeat: no-repeat` to all elements and pseudo elements */
	box-sizing: inherit;
}

::before,
::after {
	text-decoration: inherit; /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
	vertical-align: inherit;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	vertical-align: baseline;
	background: transparent;
}

article, aside, dialog, figure, footer, header,
hgroup, nav, section {
	display: block;
}

nav ul {
	list-style: none;
}

address, caption, cite, code, dfn, em, strong, th, var {
	font-style: normal;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

caption, th {
	text-align: left;
}

q::before, q::after {
	content: '';
}

object,embed {
	vertical-align: top;
}

hr,legend {
	display: none;
}

h1, h2, h3, h4, h5, h6 {
	font-size: 100%;
}

img, abbr, acronym, fieldset {
	border: 0;
}

ul, ol {
	list-style-type: none;
}

/* link
--------------------------------------------------------------------------------------- */
a {
	background-color: transparent; /* Remove the gray background on active links in IE 10 */
	-webkit-text-decoration-skip: objects; /* Remove gaps in links underline in iOS 8+ and Safari 8+ */
	text-decoration: none;
}

a:active,
a:hover {
	outline-width: 0; /* Remove the outline when hovering in all browsers */
}

a:hover {
	text-decoration: none;
}

/* sub sup
--------------------------------------------------------------------------------------- */
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

/* form
--------------------------------------------------------------------------------------- */
input {
	border-radius: 0;
}

/* Apply cursor pointer to button elements */
button,
[type="button"],
[type="reset"],
[type="submit"],
[role="button"] {
	cursor: pointer;
}

/* Replace pointer cursor in disabled elements */
[disabled] {
	cursor: default;
}

[type="number"] {
	width: auto; /* Firefox 36+ */
}

[type="search"] {
	-webkit-appearance: textfield; /* Safari 8+ */
	outline-offset: -2px; /* Correct the outline style in Safari */
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none; /* Safari 8 */
}

textarea {
	overflow: auto; /* Internet Explorer 11+ */
	resize: vertical; /* Specify textarea resizability */
}

button,
input,
optgroup,
select,
textarea {
	font: inherit; /* Specify font inheritance of form elements */
}

optgroup {
	font-weight: bold; /* Restore the font weight unset by the previous rule. */
}

button {
	overflow: visible; /* Address `overflow` set to `hidden` in IE 8/9/10/11 */
}

/* Remove inner padding and border in Firefox 4+ */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	border-style: 0;
	padding: 0;
}

/* Replace focus style removed in the border reset above */
button:-moz-focusring,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	outline: 1px dotted ButtonText;
}

button,
html [type="button"], /* Prevent a WebKit bug where (2) destroys native `audio` and `video`controls in Android 4 */
[type="reset"],
[type="submit"] {
	-webkit-appearance: button; /* Correct the inability to style clickable types in iOS */
}

button,
select {
	text-transform: none; /* Firefox 40+, Internet Explorer 11- */
}

/* Remove the default button styling in all browsers */
button,
input,
select,
textarea {
	background-color: transparent;
	border-style: none;
	color: inherit;
}

/* Style select like a standard input */
select {
	-moz-appearance: none; /* Firefox 36+ */
	-webkit-appearance: none; /* Chrome 41+ */
}

select::-ms-expand {
	display: none; /* Internet Explorer 11+ */
}

select::-ms-value {
	color: currentColor; /* Internet Explorer 11+ */
}

legend {
	border: 0; /* Correct `color` not being inherited in IE 8/9/10/11 */
	color: inherit; /* Correct the color inheritance from `fieldset` elements in IE */
	display: table; /* Correct the text wrapping in Edge and IE */
	max-width: 100%; /* Correct the text wrapping in Edge and IE */
	white-space: normal; /* Correct the text wrapping in Edge and IE */
}

::-webkit-file-upload-button {
	-webkit-appearance: button; /* Correct the inability to style clickable types in iOS and Safari */
	font: inherit; /* Change font properties to `inherit` in Chrome and Safari */
}
