/* header.scss
--------------------------------------------------------------------------------------- */
@import "mixin", "base";

.g-header {
	position: fixed;
	z-index: 75;
	width: 100%;
	min-width: 100%;

	@include mq-min(s) {
		min-width: 1000px;
	}

	@include mq-min(ml) {
		position: absolute;
		padding-top: 34px;
	}

	&.fixed {
		@include mq-min(ml) {
			position: fixed;
			top: -34px;
		}
	}

	&-top {
		position: relative;
		background: $white_color;
		height: 58px;

		@include mq-min(s) {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 75px;
		}

		@include mq-min(ml) {
			background: rgba($primary_color, 0.8);
			top: 34px;
		}

		.logo {
			position: absolute;
			z-index: 2;
			top: 50%;
			left: 3.5%;
			width: 190px;
			transform: translateY(-50%);

			@include mq-min(s) {
				top: 0;
				left: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				background: $white_color;
				width: 300px;
				height: 100%;
				transform: translateY(0);
			}

			@include mq-min(sm) {
				width: 330px;
			}

			img {
				width: 180px;

				@include mq-min(s) {
					width: 282px;
				}
			}
		}
	}

	.gnav {
		position: relative;
		z-index: -1;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;

		@include mq-min(ml) {
			position: static;
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
		}

		&.js-open {
			position: fixed;
			z-index: 100;
			top: 58px;
			left: 0;
			width: 100%;
			height: calc(100% - 55px);
			opacity: 1;
			visibility: visible;
			pointer-events: auto;

			@include mq-min(s) {
				top: 75px;
				min-width: 1000px;
				height: calc(100% - 75px);
			}

			.gnav-wrap {
				opacity: 1;
				visibility: visible;
				overflow: auto;
			}
		}

		&-wrap {
			position: absolute;
			left: 0;
			background: rgba($secondary_color, 0.9);
			padding-bottom: 85px;
			width: 100%;
			height: 100%;
			opacity: 0;
			visibility: hidden;
			transition: all 0.2s ease;

			@include mq-min(ml) {
				position: static;
				background: transparent;
				padding-bottom: 0;
				opacity: 1;
				visibility: visible;
				pointer-events: auto;
				transition: none;
			}
		}

		&-top {
			@include mq-min(ml) {
				position: absolute;
				top: 34px;
				right: 0;
				padding: 0 192px 0 300px;
				width: 100%;
				height: 75px;
			}

			@include mq-min(l) {
				padding: 0 150px 0 350px;
			}
		}

		&__list {
			display: block;

			@include mq-min(ml) {
				display: flex;
				justify-content: flex-end;
				border-right: 1px solid rgba($white_color, 0.4);
				width: 100%;
				height: 100%;
			}
		}

		&__item {
			position: relative;
			border-bottom: 1px solid $white_color;
			line-height: 1.25;

			@include mq-min(ml) {
				position: static;
				border-left: 1px solid rgba($white_color, 0.4);
				border-bottom: none;
			}

			&:hover {
				.gnav__text {
					@include mq-min(s) {
						opacity: 0.7;
					}

					@include mq-min(ml) {
						opacity: 1;
					}

					&::before {
						@include mq-min(ml) {
							opacity: 1;
							transition: all 0.3s ease;
						}
					}
				}

				.gnav__second-list-wrap {
					@include mq-min(ml) {
						z-index: 1;
						height: auto;
						visibility: visible;
						opacity: 1;
						pointer-events: auto;
					}

					&::before {
						@include mq-min(ml) {
							opacity: 1;
						}
					}
				}

				.gnav__second-list {
					@include mq-min(ml) {
						padding: 55px 40px 50px;
					}
				}
			}

			.switch {
				position: absolute;
				z-index: 5;
				top: 0;
				right: 0;
				width: 44px;
				height: 50px;

				@include mq-min(s) {
					height: 60px;
				}

				@include mq-min(ml) {
					display: none;
				}

				&::before {
					content: '';
					position: absolute;
					top: 50%;
					right: 13px;
					background: $white_color;
					width: 17px;
					height: 2px;

					@include mq-min(s) {
						right: 20px;
					}
				}

				&::after {
					content: '';
					position: absolute;
					top: 50%;
					right: 20px;
					background: $white_color;
					margin-top: -7px;
					width: 2px;
					height: 17px;
					transition: all 0.2s ease;

					@include mq-min(s) {
						right: 27px;
					}
				}

				&.active {
					&::after {
						opacity: 0;
						transform: rotate(-90deg);
					}
				}
			}
		}

		&__text {
			position: relative;
			z-index: 2;
			display: block;
			color: $white_color;
			padding: 0 30px;
			font-weight: 500;
			line-height: 50px;
			letter-spacing: 0.1em;
			white-space: nowrap;
			transition: all 0.2s ease;

			@include fontsize(14);

			@include mq-min(s) {
				line-height: 60px;

				@include fontsize(15);
			}

			@include mq-min(ml) {
				padding: 0 20px;
				min-width: 104px;
				text-align: center;
				line-height: 75px;
				letter-spacing: 0.08em;
			}

			@include mq-min(l) {
				padding: 0 30px;
				min-width: 124px;
			}

			&::before {
				@include mq-min(ml) {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					background: $white_color;
					width: 100%;
					height: 4px;
					opacity: 0;
					transition: 0;
				}
			}
		}

		&__second-list-wrap {
			position: relative;
			z-index: 1;
			display: none;
			background: $primary_color;
			padding: 10px 0;

			@include mq-min(ml) {
				position: absolute;
				z-index: -100;
				top: 100%;
				left: 0;
				display: block;
				background: rgba($secondary_color, 0.9);
				padding: 0;
				width: 100%;
				height: 0;
				visibility: hidden;
				opacity: 0;
				pointer-events: none;
			}

			&::before {
				@include mq-min(ml) {
					content: '';
					position: fixed;
					z-index: -1;
					top: 34px;
					left: 0;
					width: 100%;
					height: 75px;
					opacity: 0;
				}
			}
		}

		&__second-list {
			@include mq-min(ml) {
				margin: 0 auto;
				padding: 60px 40px 55px;
				max-width: 1680px;
				transition: all 0.3s ease-out;

				@include flex-wrap;
			}
		}

		&__second-item {
			position: relative;

			@include mq-min(ml) {
				border-bottom: 1px solid rgba($white_color, 0.45);
				margin: 0 0 20px 2.5%;
				padding: 0 0 0 25px;
				width: percentage(370 / 1600);
			}

			@include mq-min(l) {
				padding: 0 0 0 35px;
			}

			&:nth-child(4n+1) {
				@include mq-min(s) {
					margin-left: 0;
				}
			}

			&::before {
				@include mq-min(ml) {
					content: "";
					position: absolute;
					top: calc(0.7em + 20px);
					left: 0;
					border-top: 3px solid $white_color;
					border-right: 3px solid $white_color;
					margin: auto;
					width: 9px;
					height: 9px;
					transform: rotate(45deg) translate3d(0, 0, 0);
				}
			}

			.switch2 {
				position: absolute;
				z-index: 5;
				top: 0;
				right: 0;
				width: 44px;
				height: 40px;

				@include mq-min(s) {
					height: 50px;
				}

				@include mq-min(ml) {
					display: none;
				}

				&::before {
					content: '';
					position: absolute;
					top: 50%;
					right: 13px;
					background: $white_color;
					width: 17px;
					height: 2px;

					@include mq-min(s) {
						right: 20px;
					}
				}

				&::after {
					content: '';
					position: absolute;
					top: 50%;
					right: 20px;
					background: $white_color;
					margin-top: -7px;
					width: 2px;
					height: 17px;
					transition: all 0.2s ease;

					@include mq-min(s) {
						right: 27px;
					}
				}

				&.active {
					&::after {
						opacity: 0;
						transform: rotate(-90deg);
					}
				}
			}
		}

		&__second-text {
			position: relative;
			display: block;
			color: $white_color;
			margin-left: 30px;
			padding: 0 15px;
			font-weight: 500;
			line-height: 40px;
			letter-spacing: 0.1em;
			white-space: nowrap;
			transition: all 0.2s ease;

			@include fontsize(12);

			@include mq-min(s) {
				line-height: 50px;

				@include fontsize(14);
			}

			@include mq-min(ml) {
				margin-left: 0;
				padding: 20px 0;
				line-height: 1.5;
				letter-spacing: 0.08em;

				@include fontsize(16);
			}

			@include mq-min(l) {
				@include fontsize(18);
			}

			&.text-link {
				&::before {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					right: 18px;
					border-top: 2px solid $white_color;
					border-right: 2px solid $white_color;
					margin: auto;
					width: 10px;
					height: 10px;
					transform: rotate(45deg) translate3d(0, 0, 0);

					@include mq-min(s) {
						right: 25px;
					}

					@include mq-min(ml) {
						display: none;
					}
				}
			}

			&:hover {
				@include mq-min(s) {
					opacity: 0.7;
				}
			}
		}

		&__third-list-wrap {
			position: relative;
			z-index: 1;
			display: none;
			background: #9d0e30;
			padding: 5px 0;

			@include mq-min(ml) {
				background: transparent;
				display: block;
				margin: 5px 0 25px;
				padding: 0;
			}
		}

		&__third-item {
			&:nth-child(n+2) {
				@include mq-min(ml) {
					margin-top: 10px;
				}
			}
		}

		&__third-text {
			position: relative;
			display: block;
			color: $white_color;
			margin-left: 42px;
			padding: 0 15px;
			font-weight: 500;
			line-height: 40px;
			letter-spacing: 0.1em;
			white-space: nowrap;
			transition: all 0.2s ease;

			@include fontsize(12);

			@include mq-min(s) {
				line-height: 50px;

				@include fontsize(14);
			}

			@include mq-min(ml) {
				margin-left: 0;
				padding: 5px 0;
				line-height: 1.3;
				letter-spacing: 0.08em;

				@include fontsize(15);
			}

			&::before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				right: 18px;
				border-top: 2px solid $white_color;
				border-right: 2px solid $white_color;
				margin: auto;
				width: 10px;
				height: 10px;
				transform: rotate(45deg) translate3d(0, 0, 0);

				@include mq-min(s) {
					right: 25px;
				}

				@include mq-min(ml) {
					display: none;
				}
			}

			&:hover {
				@include mq-min(s) {
					opacity: 0.7;
				}
			}
		}

		&-bottom {
			position: relative;

			@include mq-min(ml) {
				position: absolute;
				top: 0;
				left: 0;
				background: $black_color;
				width: 100%;
				height: 34px;
			}
		}

		&-aside {
			position: relative;

			@include mq-min(ml) {
				display: none;
			}

			&__list {
				display: flex;
				border-top: 1px solid $white_color;
				border-bottom: 1px solid $white_color;
			}

			&__item {
				border-right: 1px solid $white_color;
				width: percentage(100 / 2);

				&:last-child {
					border-right: none;
				}

				a {
					display: block;
					padding: 16px 5px;
					height: 100%;

					& > span {
						display: block;
						text-align: center;

						img {
							width: 28px;

							@include mq-min(s) {
								width: 36px;
							}
						}

						.text {
							display: flex;
							justify-content: center;
							align-items: center;
							color: $white_color;
							margin-top: 5px;
							min-height: 2.75em;
							line-height: (34 / 24);
							letter-spacing: 0;

							@include fontsize(12);

							@include mq-min(s) {
								@include fontsize(14);
							}
						}
					}
				}
			}
		}
	}

	.gsubnav {
		padding: 25px 30px;

		@include mq-min(s) {
			padding: 35px 30px;
		}

		@include mq-min(ml) {
			padding: 0;
		}

		&__list {
			display: flex;
			flex-wrap: wrap;

			@include mq-min(s) {
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
			}

			@include mq-min(ml) {
				justify-content: flex-end;
				height: 34px;
			}
		}

		&__item {
			position: relative;
			margin: 7px 0;
			padding-left: 15px;
			width: 50%;

			@include mq-min(s) {
				margin: 0 24px;
				padding-left: 20px;
				width: auto;
			}

			@include mq-min(ml) {
				padding-left: 0;
			}

			&::before {
				content: "";
				position: absolute;
				top: 0.7em;
				left: 0;
				border-top: 2px solid $white_color;
				border-right: 2px solid $white_color;
				margin: auto;
				width: 7px;
				height: 7px;
				transform: rotate(45deg) translate3d(0, 0, 0);

				@include mq-min(s) {
					top: 0.6em;
				}

				@include mq-min(ml) {
					display: none;
				}
			}

			a {
				color: $white_color;
				font-weight: 500;
				transition: all 0.2s ease;

				@include fontsize(12);

				@include mq-min(s) {
					@include fontsize(13);
				}

				&:hover {
					@include mq-min(s) {
						opacity: 0.7;
					}
				}
			}
		}
	}
}

#gnav__map {
	display: none;

	@include mq-min(ml) {
		position: absolute;
		z-index: 2;
		top: 0;
		right: 96px;
		background: $secondary_color;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 96px;
		height: 75px;
		cursor: pointer;
	}

	@include mq-min(l) {
		right: 75px;
		width: 75px;
	}

	.icon-pin {
		display: block;
		text-align: center;

		img {
			@include mq-min(s) {
				width: 32px;
			}
		}

		.text {
			display: none;

			@include mq-min(s) {
				display: block;
				color: $white_color;
				margin-top: 5px;
				line-height: 1;

				@include fontsize(14);
			}
		}
	}
}

#gnav__search {
	position: absolute;
	z-index: 2;
	top: 0;
	right: 58px;
	background: $black_color;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 58px;
	height: 58px;
	cursor: pointer;

	@include mq-min(s) {
		right: 96px;
		width: 96px;
		height: 75px;
	}

	@include mq-min(ml) {
		right: 0;
	}

	@include mq-min(l) {
		width: 75px;
	}

	.icon-search {
		display: block;
		text-align: center;

		img {
			width: 28px;

			@include mq-min(s) {
				width: 32px;
			}
		}

		.text {
			display: none;

			@include mq-min(ml) {
				display: block;
				color: $white_color;
				margin-top: 5px;
				padding-left: 0.3em;
				line-height: 1;
				letter-spacing: 0.3em;

				@include fontsize(14);
			}
		}
	}

	.icon-close {
		position: relative;
		display: none;
		width: 22px;
		height: 19px;

		@include mq-min(s) {
			width: 30px;
			height: 30px;
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			background-color: $white_color;
			margin: 0 auto;
			width: 100%;
			height: 2px;
		}

		&::before {
			transform: translateY(-50%) rotate(45deg);
		}

		&::after {
			transform: translateY(-50%) rotate(-45deg);
		}
	}

	&.active {
		.icon-search {
			display: none;
		}

		.icon-close {
			display: block;
		}
	}
}

#gnav__search-form {
	position: absolute;
	z-index: 105;
	top: 100%;
	right: 0;
	display: none;
	background: transparent;
	width: 100%;
	overflow: hidden;
	pointer-events: none;

	@include mq-min(s) {
		max-width: 671px;
	}

	.gsc-search-box {
		.gsc-input {
			padding-right: 0;
		}
	}

	.gsc-input-box {
		margin-right: -5px;
		padding: 0;
		border: 5px solid $bg_secondary_color;
		border-radius: 0;
	}

	.gsib_a {
		display: flex;
		align-items: center;
		padding: 5px 50px 5px 3.5%;
		height: 48px;

		@include mq-min(s) {
			padding: 5px 80px 5px 30px;
			height: 65px;
		}
	}

	.gsst_b {
		position: absolute;
		top: 50%;
		right: 15px;
		padding: 0;
		width: 30px;
		height: 30px;
		text-align: center;
		transform: translateY(-50%);
	}

	.gsst_a {
		padding: 2px 4px;
		width: 100%;
		height: 100%;
	}

	.gsc-input {
		position: relative;
		background-position: 0 0.25em !important;
		font-weight: 500;
		letter-spacing: 0.08em;

		@include fontsize(12);

		@include mq-min(s) {
			@include fontsize(18);
		}
	}

	.gsc-search-button-v2 {
		position: relative;
		background: $bg_secondary_color;
		border: none;
		border-radius: 0;
		margin: 0;
		padding: 0;
		width: 58px;
		height: 58px;
		outline: none;

		@include mq-min(s) {
			width: 75px;
			height: 75px;
		}

		&::before {
			content: '';
			display: block;
			background-image: url(../img/common/icon_search.svg);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
			margin: 0 auto;
			width: 28px;
			height: 28px;

			@include mq-min(s) {
				width: 32px;
				height: 32px;
			}
		}

		svg {
			display: none;
		}
	}

	&.show {
		pointer-events: all;
	}
}

.btn-menu {
	position: absolute;
	z-index: 5;
	top: 0;
	right: 0;
	background: $primary_color;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 58px;
	height: 58px;
	outline: none;

	@include mq-min(s) {
		width: 96px;
		height: 75px;
	}

	@include mq-min(ml) {
		display: none;
	}

	span {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 23px;
		height: 17px;
		z-index: 10;

		i {
			position: absolute;
			left: 0;
			background-color: $white_color;
			margin: 0 auto;
			width: 100%;
			height: 1px;
			transition: all .3s ease;

			&:nth-last-of-type(1) {
				top: 0;
				transform: translate3d(0, 0, 0);
			}

			&:nth-last-of-type(2) {
				top: 8px;
				width: 18px;
			}

			&:nth-last-of-type(3) {
				top: auto;
				bottom: 0;
				transform: translate3d(0, 0, 0);
			}
		}
	}

	&.js-open {
		span {
			i {
				&:nth-last-of-type(1) {
					top: 50%;
					transform: translateY(-50%) rotate(45deg);
				}

				&:nth-last-of-type(2) {
					width: 0;
					visibility: hidden;
					opacity: 0;
				}

				&:nth-last-of-type(3) {
					top: 50%;
					transform: translateY(-50%) rotate(-45deg);
				}
			}
		}
	}
}
