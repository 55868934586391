/* footer.scss
--------------------------------------------------------------------------------------- */
@import "mixin", "base";

.g-footer {
	position: relative;
	color: $white_color;

	.container {
		@include mq-max(s) {
			padding: 0;
		}
	}

	&-top {
		position: relative;
		background: $primary_color;
		padding: 0;

		@include mq-min(s) {
			padding: 30px 0;
		}
	}

	&-school {
		.list {
			@include flex-wrap;

			@include mq-min(s) {
				flex-wrap: nowrap;
			}
		}

		.item {
			border-bottom: 1px solid rgba($white_color, 0.3);
			width: 50%;

			@include mq-min(s) {
				border-bottom: none;
				margin-left: 3.334%;
				width: auto;
			}

			&:first-child {
				@include mq-min(s) {
					margin-left: 0;
				}
			}

			&:nth-child(odd) {
				@include mq-max(s) {
					border-right: 1px solid rgba($white_color, 0.3);
				}
			}

			&:last-child {
				@include mq-max(s) {
					border-bottom: none;
				}
			}

			a {
				position: relative;
				display: block;
				color: $white_color;
				padding: 14px 12px;
				font-weight: 500;
				line-height: 1.8;
				transition: all 0.2s ease;

				@include fontsize(12);

				@include mq-min(s) {
					display: inline;
					padding: 0;

					@include fontsize(16);
				}

				&[target="_blank"]::after {
					content: '';
					display: inline-block;
					background-image: url(../img/common/icon_window2_w.svg);
					background-position: left top;
					background-repeat: no-repeat;
					background-size: contain;
					margin-left: 5px;
					width: 10px;
					height: 10px;

					@include mq-min(s) {
						margin-left: 7px;
						width: 14px;
						height: 14px;
					}
				}

				&:hover {
					@include mq-min(s) {
						opacity: 0.7;
					}
				}
			}
		}
	}

	&-middle {
		position: relative;
		padding: 30px 0;

		@include mq-min(s) {
			padding: 75px 0;
		}

		.social {
			display: flex;
			justify-content: center;
			align-items: center;

			a {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				background: $primary_color;
				color: $white_color;
				width: 35px;
				height: 35px;
				margin: 0 5px;
				overflow: hidden;
				border-radius: 50%;
				letter-spacing: -0.035em;
				transition: all 0.2s ease;

				@include mq-min(s) {
					margin: 0 7px;
					width: 50px;
					height: 50px;
				}

				i {
					@include fontsize(15);

					@include mq-min(s) {
						@include fontsize(21);
					}
				}

				&:hover {
					@include mq-min(s) {
						background: rgba($primary_color, 0.7);
					}
				}
			}
		}
	}

	&-logo {
		margin-bottom: 10px;
		text-align: center;

		@include mq-min(s) {
			margin-bottom: 30px;
		}

		img {
			width: 202px;

			@include mq-min(s) {
				width: 369px;
			}
		}
	}

	&-text {
		color: $text_color;
		margin: 0 auto;
		max-width: 300px;
		text-align: center;
		line-height: (38 / 24);

		@include fontsize(12);

		@include mq-min(s) {
			max-width: 100%;
			line-height: (24 / 16);

			@include fontsize(16);
		}

		span {
			display: inline-block;
		}

		.post {
			margin-right: 1em;
		}

		.address {
			@include mq-min(s) {
				margin-right: 2em;
			}
		}
	}

	&-bottom {
		position: relative;
		background: #f2f1ef;
		padding: 20px 0 0;

		@include mq-min(s) {
			padding: 25px 0;
		}

		.container {
			position: relative;
		}
	}

	&-nav {
		margin: auto;
		max-width: 250px;

		@include mq-min(s) {
			max-width: 100%;
		}

		.list {
			justify-content: center;

			@include flex-wrap;

			@include mq-min(s) {
				justify-content: flex-start;
			}
		}

		.item {
			position: relative;
			margin: 5px 12px;

			@include mq-min(s) {
				margin: 0 0 0 31px;
			}

			@include mq-min(m) {
				margin-left: 41px;
			}

			&:first-child {
				@include mq-min(s) {
					margin-left: 0;
				}

				&::before {
					display: none;
				}
			}

			&:nth-of-type(3) {
				&::before {
					@include mq-max(s) {
						display: none;
					}
				}
			}

			&::before {
				content: '';
				position: absolute;
				top: 5px;
				bottom: 0;
				left: -12px;
				background: #cdccc9;
				margin: auto;
				width: 1px;
				height: 10px;

				@include mq-min(s) {
					top: 3px;
					left: -16px;
					width: 1px;
					height: 14px;
				}

				@include mq-min(m) {
					left: -21px;
				}
			}

			a {
				position: relative;
				color: #919191;
				font-weight: 500;
				transition: all 0.2s ease;

				@include fontsize(11);

				@include mq-min(s) {
					@include fontsize(13);
				}

				&:hover {
					@include mq-min(s) {
						opacity: 0.7;
					}
				}
			}
		}
	}

	.copyright {
		color: #cdccc9;
		font-family: $en_font;
		text-align: center;
		letter-spacing: 0.08em;

		@include fontsize(10);

		@include mq-max(s) {
			position: relative;
			z-index: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 25px;
			height: 64px;
		}

		@include mq-min(s) {
			position: absolute;
			top: 50%;
			right: 40px;
			transform: translateY(-50%);

			@include fontsize(12);
		}

		&::before {
			@include mq-max(s) {
				content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				left: calc((100vw - 100%) / -2);
				background: $white_color;
				width: calc((100vw - 100%) / 2 + 100%);
				height: 100%;
			}
		}
	}
}

// pagetop
.pagetop {
	position: absolute;
	z-index: 25;
	right: 12px;
	bottom: 42px;
	width: 45px;
	height: 45px;
	transition: all 0.2s ease;

	@include mq-min(s) {
		right: 0;
		top: 0;
		bottom: auto;
		width: 88px;
		height: 88px;
	}

	a {
		position: relative;
		display: block;
		background: $text_color;
		color: $white_color;
		width: 100%;
		height: 100%;
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;

		&::before {
			content: '';
			position: absolute;
			top: 5px;
			bottom: 0;
			left: 0;
			right: 0;
			border-top: 2px solid $white_color;
			border-right: 2px solid $white_color;
			margin: auto;
			width: 10px;
			height: 10px;
			transform: rotate(-45deg);
			transition: all 0.2s ease;

			@include mq-min(s) {
				top: 10px;
				width: 15px;
				height: 15px;
			}
		}

		&:hover {
			&::before {
				@include mq-min(s) {
					top: 5px;
				}
			}
		}
	}
}

.side-fixed-bnr {
	display: none;

	@include mq-min(sm) {
		position: fixed;
		z-index: 50;
		bottom: 20px;
		right: 30px;
		display: block;
	}

	&__item {
		&.book {
			a {
				height: 90px;

				span {
					.text {
						@include fontsize(24);
					}
				}
			}
		}

		&.campus {
			margin-top: 30px;

			a {
				height: 90px;

				span {
					.text {
						@include fontsize(14);
					}
				}
			}
		}

		a {
			position: relative;
			display: flex;
			align-items: center;
			background: $primary_color;
			border-left: 4px solid $white-color;
			padding-left: 30px;
			width: 270px;
			transition: all 0.2s ease;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				right: 20px;
				border-top: 2px solid $white_color;
				border-right: 2px solid $white_color;
				margin: auto;
				width: 7px;
				height: 7px;
				transform: rotate(45deg);
				transition: all 0.2s ease;

				@include mq-min(s) {
					right: 20px;
					border-top-width: 3px;
					border-right-width: 3px;
					width: 9px;
					height: 9px;
				}
			}

			span {
				display: flex;
				align-items: center;

				img {
					width: 35px;
				}

				.text {
					color: $white_color;
					margin-left: 20px;
					font-weight: 700;
					text-align: left;
					line-height: 1.6;
					letter-spacing: 0.15em;
				}
			}

			&:hover {
				@include mq-min(s) {
					background: lighten($primary-color, 10%);
				}

				&::before {
					right: 17px;
				}
			}
		}
	}
}
