/* parts.scss
--------------------------------------------------------------------------------------- */
@import "mixin", "base";

.clr {
	clear: both;
}

.u-m-i-n {
	display: inline;

	@include mq-min(m) {
		display: none;
	}
}

.u-m-n-i {
	display: none;

	@include mq-min(m) {
		display: inline;
	}
}

.u-s-i-n {
	display: inline;

	@include mq-min(s) {
		display: none;
	}
}

.u-s-n-i {
	display: none;

	@include mq-min(s) {
		display: inline;
	}
}

.u-s-n-b {
	display: none;

	@include mq-min(s) {
		display: block;
	}
}

.m-btn {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $white_color;
	margin: auto;
	padding: 5px 35px;
	width: 80vw;
	max-width: 370px;
	height: 50px;
	border-radius: 25px;
	box-shadow: 1px 3px 30px 3px rgba($black_color, 0.1);
	transition: all 0.2s ease;

	@include fontsize(12);

	@include mq-min(s) {
		padding: 5px 50px;
		width: 100%;
		height: 80px;
		border-radius: 40px;
		box-shadow: 2.5px 6.5px 72px 7px rgba($black_color, 0.1);

		@include fontsize(16);
	}

	&:hover {
		@include mq-min(s) {
			background: $primary_color;
			color: $white_color;
		}
	}

	&.arw {
		&::after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 20px;
			border-top: 2px solid $text_color;
			border-right: 2px solid $text_color;
			margin: auto;
			width: 7px;
			height: 7px;
			transform: rotate(45deg);
			transition: all 0.2s ease;

			@include mq-min(s) {
				right: 30px;
				border-top-width: 3px;
				border-right-width: 3px;
				width: 9px;
				height: 9px;
			}
		}

		&:hover {
			&::after {
				@include mq-min(s) {
					border-top-color: $white_color;
					border-right-color: $white_color;
				}
			}
		}
	}
}

.new-wpContent .tl-anchor {
	position: relative;

	&__list {
		border-bottom: 1px solid #ccc;
		margin: 55px 0;
		padding-bottom: 20px;

		@include mq-min(s) {
			margin: 90px 0;
			padding-bottom: 25px;

			@include flex-wrap;
		}
	}

	&__item {
		margin: 10px 0;
		padding-left: 0;

		@include mq-min(s) {
			margin: 17px 45px 17px 0;
		}

		&::before {
			display: none;
		}

		a {
			position: relative;
			display: inline-block;
			padding-left: 17px;
			line-height: (28 / 16);
			letter-spacing: 0.08em;

			@include fontsize(14);

			@include mq-min(s) {
				padding-left: 25px;

				@include fontsize(18);
			}

			&::before {
				content: "";
				position: absolute;
				top: 0.55em;
				left: 2px;
				border-bottom: 2px solid $primary_color;
				border-right: 2px solid $primary_color;
				margin: auto;
				width: 7px;
				height: 7px;
				transform: rotate(45deg) translate3d(0, 0, 0);
				transition: border-color 0.2s ease;

				@include mq-min(s) {
					border-bottom-width: 3px;
					border-right-width: 3px;
					width: 9px;
					height: 9px;
				}
			}
		}
	}
}

.new-wpContent .ttc-point,
.new-wpContent .cs-point {
	.icon {
		img {
			max-width: 40%;
		}
	}
}

.new-wpContent .ttc-about {
	&__list {
		margin: 10px 0 15px;

		@include mq-min(s) {
			margin: 20px 0 30px;
		}
	}

	&__item {
		position: relative;
		background-color: $white_color;
		margin-bottom: 15px;
		padding: 20px 15px;

		@include mq-min(s) {
			margin-bottom: 20px;
			padding: 47px 50px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		dt {
			color: $primary_color;
			margin-bottom: 7px;
			font-family: $en_font;
			font-weight: 700;
			line-height: 1.35;
			letter-spacing: 0.08em;

			@include fontsize(15);

			@include mq-min(s) {
				margin-bottom: 15px;

				@include fontsize(22);
			}
		}

		dd {
			color: $text_color;
			line-height: (28 / 15);

			@include fontsize(11.5);

			@include mq-min(s) {
				@include fontsize(15);
			}
		}
	}
}

.notfound-box {
	margin-top: 75px;
	text-align: center;

	@include mq-min(s) {
		margin-top: 150px;
	}

	.m-btn-wrap {
		margin-top: 35px;

		@include mq-min(s) {
			margin-top: 75px;
		}
	}
}
