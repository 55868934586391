.new-wpContent {
	.wp-modal-g-box a.media figure::before {
		bottom: 8px;
		right: 8px;
		background-image: url(../img/support/media/icon_plus_w.svg);
		width: 14px;
		height: 14px;

		@include mq-min(s) {
			bottom: 11px;
			right: 11px;
			width: 17px;
			height: 17px;
		}
	}
}

.modaal-inline {
	.modaal-container {
		background: transparent;
		box-shadow: none;
	}

	.modaal-content-container {
		padding: 0;
	}
}

.modal-content {
	display: none;
}

.modal-media {
	.mov {
		position: relative;
		padding-bottom: 56.25%;
		max-width: 1000px;
		height: 0;
		overflow: hidden;
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	h4 {
		color: $white_color;
		margin: 25px 0 20px;
		font-weight: 700;
		letter-spacing: 0.08em;

		@include fontsize(20);

		@include mq-min(s) {
			margin: 35px 0 30px;

			@include fontsize(32);
		}
	}

	p {
		color: $white_color;
		margin: 10px 0 15px;
		line-height: (34 / 16);
		letter-spacing: 0.08em;

		@include fontsize(12);

		@include mq-min(s) {
			margin: 20px 0 30px;

			@include fontsize(16);
		}
	}

	.name-tag {
		display: flex;
		margin-bottom: 10px;

		@include mq-min(s) {
			margin-bottom: 20px;
		}

		.ttl {
			color: $white_color;
			margin-top: 4px;
			letter-spacing: 0.08em;

			@include fontsize(11.5);

			@include mq-min(s) {
				margin-top: 8px;

				@include fontsize(15);
			}
		}

		.tag {
			display: flex;
			flex-wrap: wrap;

			span {
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid $primary_color;
				background: $white_color;
				color: $primary_color;
				margin: 0 5px 7px 0;
				padding: 2px 7px;
				min-width: 60px;
				height: 25px;
				font-weight: 700;
				line-height: 1;
				white-space: nowrap;
				border-radius: 19px;

				@include fontsize(11.5);

				@include mq-min(s) {
					border-width: 2px;
					margin: 0 10px 10px 0;
					padding: 5px 15px 7px;
					min-width: 105px;
					height: 38px;

					@include fontsize(15);
				}
			}
		}
	}
}
