@import '~aos/dist/aos.css';

@for $i from 1 through 8 {
	[data-aos-delay-item] {
		&:nth-of-type(#{$i}) {
			@include mq-min(s) {
				transition-delay: .15s * $i;
			}
		}
	}
}

html:not(.no-js) [data-aos=zoom-in] {
	transform: scale(.85);
}

html:not(.no-js) [data-aos=fade-up] {
	transform: translate3d(0, 30px, 0);
}
