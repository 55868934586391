/* ==========================
		WP CONTENT
============================= */
.new-wpContent {
	section {
		position: relative;
	}

	.img-tit-link {
		position: relative;
		padding: 50px 0 35px;

		@include mq-min(s) {
			padding: 100px 0;
		}

		&.img-tit-link {
			&:last-child {
				padding-bottom: 0;
			}
		}

		&__item {
			position: relative;
			z-index: 0;
			display: flex;
			margin-bottom: 50px;

			@include mq-min(s) {
				margin-bottom: 100px;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&::before {
				@include mq-min(s) {
					content: "";
					position: absolute;
					top: 105px;
					right: 0;
					z-index: -1;
					background: $white_color;
					width: percentage(1760 / 1920);
					height: calc(100% - 105px);
				}
			}

			&:nth-child(odd) {
				&::before {
					@include mq-min(s) {
						right: 0;
					}
				}

				.text-wrap {
					@include mq-min(s) {
						padding-left: 30px;
					}

					@include mq-min(ml) {
						padding-left: 80px;
					}
				}

				.text {
					@include mq-max(s) {
						padding: 55% 22.5% 40px 35px;
					}

					@include mq-min(s) {
						max-width: 410px;
						padding-right: 50px;
					}
				}
			}

			&:nth-child(even) {
				@include mq-min(s) {
					flex-direction: row-reverse;
				}

				&::before {
					@include mq-min(s) {
						left: 0;
					}
				}

				.image {
					left: auto;
					right: 0;
				}

				.title {
					left: 7%;
					right: auto;

					@include mq-min(s) {
						left: auto;
						right: 61%;
					}
				}

				.text-wrap {
					@include mq-min(s) {
						display: flex;
						flex-direction: column;
						align-items: center;
						padding: 160px 80px 80px 40px;
					}
				}

				.text {
					@include mq-max(s) {
						margin-left: 0;
						margin-right: percentage(70 / 750);
						padding: 55% 35px 40px 22.5%;
					}

					&::before {
						@include mq-max(s) {
							left: 0;
							right: auto;
						}
					}
				}
			}
		}

		.image {
			position: absolute;
			top: 0;
			left: 0;
			width: percentage(500 / 750);

			@include mq-min(s) {
				position: relative;
				margin-bottom: 125px;
				width: percentage(1100 / 1920);
			}
		}

		.title {
			position: absolute;
			top: 10px;
			right: 6.75%;
			margin: 0;
			writing-mode: vertical-rl;
			vertical-align: top;

			@include mq-min(s) {
				top: 15px;
				right: 4.5%;
			}

			.jp {
				line-height: (54 / 40);
			}

			.en {
				margin: 2px 0.6em 0 0;

				@include mq-min(s) {
					margin-right: 1.1em;
				}
			}
		}

		.text-wrap {
			@include mq-min(s) {
				margin-top: 105px;
				padding: 160px 40px 80px 80px;
				width: percentage(820 / 1920);
			}
		}

		.text {
			position: relative;
			margin-left: percentage(70 / 750);
			margin-bottom: 30px;
			line-height: (50 / 24);
			width: percentage(680 / 750);

			@include fontsize(12);

			@include mq-min(s) {
				background: transparent;
				margin: 0 0 8.5%;
				padding: 0 15px 0 0;
				width: 100%;
				max-width: 375px;
				line-height: (60 / 16);

				@include fontsize(16);
			}

			&::before {
				content: "";
				position: absolute;
				top: 95px;
				right: 0;
				z-index: -1;
				background: $white_color;
				width: 100%;
				height: calc(100% - 95px);

				@include mq-min(s) {
					display: none;
				}
			}
		}

		.m-btn {
			margin: 0 auto;

			@include mq-min(s) {
				margin: 0;
			}
		}
	}

	.img-tit-link2 {
		position: relative;
		z-index: 0;
		padding: 50px 0 35px;

		@include mq-min(s) {
			padding: 100px 0;
		}

		&:last-child {
			@include mq-max(s) {
				padding-bottom: 0;
			}

			@include mq-min(s) {
				margin-bottom: -100px;
			}
		}

		&::before {
			@include mq-min(s) {
				content: "";
				position: absolute;
				z-index: -1;
				top: 180px;
				left: 40px;
				right: 40px;
				background: $white_color;
				margin: auto;
				max-width: 1600px;
				height: calc(100% - 280px);
			}
		}

		&::after {
			@include mq-min(s) {
				content: "";
				position: absolute;
				z-index: -1;
				top: 180px;
				right: 0;
				background: $white_color;
				width: 50%;
				height: calc(100% - 280px);
			}
		}

		.container {
			position: relative;
			padding: 0;

			@include mq-min(s) {
				padding: 0 40px 0 80px;
				max-width: 1320px;
			}

			@media screen and (min-width: 1331px) {
				padding: 0 60px;
				max-width: 1320px;
			}
		}

		&__list {
			position: relative;
			z-index: 0;
			margin-left: 5.85%;
			padding-bottom: 50px;

			@include mq-min(s) {
				margin: 0 -20px;
				padding-bottom: 20px;

				@include flex-wrap;
			}

			@include mq-min(sm) {
				margin: 0 -40px;
				padding-bottom: 60px;
			}

			&::before {
				@include mq-max(s) {
					content: "";
					position: absolute;
					z-index: -1;
					top: 50px;
					right: 0;
					background: $white_color;
					width: 100%;
					height: calc(100% - 50px);
				}
			}
		}

		&__item {
			margin-bottom: 40px;

			@include mq-min(s) {
				margin-bottom: 40px;

				@include col(6, 20px);
			}

			@include mq-min(sm) {
				margin-bottom: 80px;

				@include col(6, 40px);
			}

			&:last-child {
				@include mq-max(s) {
					margin-bottom: 0;
				}
			}

			a {
				&:hover {
					img {
						@include mq-min(s) {
							transform: scale(1.05);
						}
					}

					.title {
						&::after {
							@include mq-min(s) {
								right: 3px;
								border-top-color: $primary_color;
								border-right-color: $primary_color;
							}
						}
					}
				}
			}

			.image {
				position: relative;
				padding-left: 7%;
				overflow: hidden;

				@include mq-min(s) {
					padding-left: 0;
				}

				&.border {
					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						z-index: 1;
						border: 1px solid #ccc;
						width: 100%;
						height: 100%;
					}
				}

				img {
					transition: all 0.3s cubic-bezier(.25, .46, .45, .94);
				}
			}

			.title {
				position: relative;
				border-bottom: 1px solid #ccc;
				margin: 0 7%;
				padding: 20px 5px;

				@include mq-min(s) {
					margin: 0;
					padding: 25px 5px 30px;
				}

				&::before {
					display: none;
				}

				&::after {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					right: 3px;
					border-top: 2px solid $black_color;
					border-right: 2px solid $black_color;
					margin: auto;
					width: 7px;
					height: 7px;
					transform: rotate(45deg);
					transition: all 0.2s ease;

					@include mq-min(s) {
						right: 6px;
						border-top-width: 3px;
						border-right-width: 3px;
						width: 9px;
						height: 9px;
					}
				}

				.jp {
					display: block;
					font-weight: 500;
					line-height: 1.35;

					@include fontsize(18);

					@include mq-min(s) {
						@include fontsize(26);
					}
				}

				.en {
					display: block;
					color: $primary_color;
					margin-top: 5px;
					font-family: $en_font;
					font-weight: 700;
					text-transform: uppercase;
					letter-spacing: 0.08em;

					@include fontsize(10);

					@include mq-min(s) {
						margin-top: 8px;

						@include fontsize(13);
					}
				}
			}
		}
	}

	.m-heading-wrap {
		margin-bottom: 35px;

		@include mq-min(s) {
			display: flex;
			margin: 105px 0 80px;
		}

		h2 {
			@include mq-min(s) {
				flex: none;
				margin: 0 45px 0 0;
				width: 400px;
			}
		}

		p {
			margin: 0;
			line-height: (50 / 24);

			@include fontsize(12);

			@include mq-min(s) {
				line-height: (60 / 16);

				@include fontsize(16);
			}
		}
	}

	h2 {
		margin: 55px 0 35px;

		@include mq-min(s) {
			margin: 105px 0 80px;
		}

		.jp {
			display: block;
			font-weight: 700;
			line-height: 1.7;
			letter-spacing: 0.08em;

			@include fontsize(25);

			@include mq-min(s) {
				line-height: 2;

				@include fontsize(40);
			}
		}

		.en {
			display: block;
			color: $primary_color;
			margin-top: 5px;
			font-family: $en_font;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 0.08em;

			@include fontsize(10);

			@include mq-min(s) {
				margin-top: 10px;

				@include fontsize(15);
			}
		}
	}

	h3 {
		position: relative;
		border-bottom: 1px solid #ccc;
		margin: 35px 0 20px;
		padding: 0 0 15px 35px;
		font-weight: 700;
		letter-spacing: 0.08em;

		@include fontsize(22);

		@include mq-min(s) {
			border-bottom-width: 2px;
			margin: 75px 0 45px;
			padding: 0 0 30px 60px;
			line-height: 2;

			@include fontsize(36);
		}

		&::before {
			content: "";
			position: absolute;
			top: 0.7em;
			left: 0;
			background: $primary_color;
			width: 20px;
			height: 3px;

			@include mq-min(s) {
				top: 1em;
				width: 28px;
				height: 5px;
			}
		}
	}

	h4 {
		position: relative;
		padding-bottom: 10px;
		margin: 25px 0;
		font-weight: 700;
		letter-spacing: 0.08em;

		@include fontsize(18);

		@include mq-min(s) {
			padding-bottom: 15px;
			margin: 35px 0;
			line-height: 2;

			@include fontsize(28);
		}

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			height: 2px;
			width: 15px;
			background-color: $primary_color;

			@include mq-min(s) {
				height: 3px;
				width: 20px;
			}
		}
	}

	figure {
		margin: 10px 0 15px;

		@include mq-min(s) {
			margin: 20px 0 30px;
		}

		img {
			@include mq-max(s) {
				width: 100%;
			}
		}

		figcaption {
			position: relative;
			margin-top: 7px;
			line-height: (34 / 16);
			letter-spacing: 0.08em;

			@include fontsize(10);

			@include mq-max(s) {
				width: 100% !important;
			}

			@include mq-min(s) {
				margin-top: 10px;

				@include fontsize(14);
			}
		}
	}

	p {
		margin: 10px 0 15px;
		line-height: (34 / 16);
		letter-spacing: 0.08em;

		@include fontsize(12);

		@include mq-min(s) {
			margin: 20px 0 30px;

			@include fontsize(16);
		}

		&:last-child {
			margin-bottom: 0;
		}

		&::after {
			@include cf;
		}

		& > .img-fl {
			display: block;
			margin: 0 auto 15px;

			@include mq-min(s) {
				display: inline;
				float: left;
				margin: 10px 50px 50px 0;
			}
		}

		& > .img-fr {
			display: block;
			margin: 0 auto 15px;

			@include mq-min(s) {
				display: inline;
				float: right;
				margin: 10px 0 50px 50px;
			}
		}

		& > a {
			color: $primary_color;
			text-decoration: underline;

			&:hover {
				@include mq-min(s) {
					text-decoration: none;
				}
			}

			&.iblocklink {
				position: relative;
				display: inline-block;
				margin-right: 1.25em;
				line-height: 1.25;
				vertical-align: middle;
			}

			&.pdflink {
				position: relative;
				display: inline-block;
				margin-right: 1.25em;
				line-height: 1.25;
				vertical-align: middle;

				&::after {
					content: "";
					display: inline-block;
					background-image: url("/hs/wp-content/themes/ghs/img/common/icon_pdf.svg");
					background-size: contain;
					background-repeat: no-repeat;
					margin-left: 5px;
					width: 12px;
					height: 15px;
					vertical-align: text-bottom;

					@include mq-min(s) {
						width: 15px;
						height: 18px;
					}
				}
			}

			&.doclink {
				position: relative;
				display: inline-block;
				margin-right: 1.25em;
				line-height: 1.25;
				vertical-align: middle;

				&::after {
					content: "";
					display: inline-block;
					background-image: url("/hs/wp-content/themes/ghs/img/common/icon_doc.svg");
					background-size: contain;
					background-repeat: no-repeat;
					margin-left: 5px;
					width: 12px;
					height: 15px;
					vertical-align: text-bottom;

					@include mq-min(s) {
						width: 15px;
						height: 18px;
					}
				}
			}

			&.windowlink {
				position: relative;
				display: inline-block;
				margin-right: 1.25em;
				line-height: 1.25;
				vertical-align: middle;

				&::after {
					content: "";
					display: inline-block;
					background-image: url("/hs/wp-content/themes/ghs/img/common/icon_window.svg");
					background-size: contain;
					background-repeat: no-repeat;
					margin-left: 5px;
					width: 12px;
					height: 12px;
					vertical-align: middle;

					@include mq-min(s) {
						width: 15px;
						height: 15px;
					}
				}
			}
		}

		& > b {
			font-weight: 700;
		}

		& > span.red {
			color: $primary_color;
		}

		& > span.large {
			@include fontsize(16);

			@include mq-min(s) {
				@include fontsize(22);
			}
		}

		&.tal {
			text-align: left;
		}

		&.tac {
			text-align: center;
		}

		&.tar {
			text-align: right;
		}
	}

	hr {
		display: block;
		border: none;
		background: #ccc;
		width: 100%;
		height: 1px;
		margin: 40px 0 30px;

		@include mq-min(s) {
			margin: 80px 0 60px;
		}
	}

	ul {
		margin: 10px 0 15px;

		@include mq-min(s) {
			margin: 20px 0 30px;
		}

		& > li {
			position: relative;
			padding-left: 1.25em;
			line-height: (28 / 16);
			margin: 5px 0;
			letter-spacing: 0.08em;

			@include fontsize(12);

			@include mq-min(s) {
				@include fontsize(16);
			}

			&::before {
				content: "";
				display: block;
				height: 6px;
				width: 6px;
				border-radius: 50%;
				background-color: $primary_color;
				position: absolute;
				top: 8px;
				left: 0;

				@include mq-min(s) {
					height: 8px;
					width: 8px;
					top: 12px;
				}
			}

			& > p {
				margin: 0;
				line-height: (28 / 16);
			}
		}
	}

	ol {
		margin: 10px 0 15px;
		counter-reset: number 0;

		@include mq-min(s) {
			margin: 20px 0 30px;
		}

		& > li {
			position: relative;
			margin: 5px 0;
			padding-left: 1.75em;
			line-height: (28 / 16);
			letter-spacing: 0.08em;

			@include fontsize(12);

			@include mq-min(s) {
				@include fontsize(16);
			}

			&::before {
				counter-increment: number 1;
				content: counter(number) ".";
				position: absolute;
				top: 0;
				left: 0;
				color: $primary_color;
				font-family: $en_font;
				letter-spacing: 0.08em;
			}
		}
	}

	.row1 {
		@include mq-min(s) {
			margin: 0 -20px;

			@include flex-wrap;
		}
	}

	.row2 {
		margin: 0 -5px;

		@include flex-wrap;

		@include mq-min(s) {
			margin: 0 -15px;
		}
	}

	span.newitem {
		display: inline-block;
		font-weight: 700;
		background-color: $primary_color;
		color: $white_color;
		margin-left: 5px;
		width: 36px;
		text-align: center;
		line-height: 20px;
		vertical-align: middle;
		transform: translateY(-0.2em);

		@include fontsize(10);

		@include mq-min(s) {
			width: 70px;
			line-height: 32px;

			@include fontsize(15);
		}
	}

	.box01 {
		border: 2px solid rgba($primary-color, 0.25);
		padding: 20px 20px;
		margin: 15px 0 20px;

		@include mq-min(s) {
			border-width: 4px;
			margin: 30px 0 40px;
			padding: 40px 40px;
		}

		.row {
			margin: -10px 0;

			@include mq-min(s) {
				@include flex-wrap;
			}

			p {
				margin: 10px 0;

				@include mq-min(s) {
					margin: 10px 40px 10px 0;
				}
			}
		}

		dt {
			margin-bottom: 5px;
			font-weight: 700;
			letter-spacing: 0.08em;

			@include fontsize(15);

			@include mq-min(s) {
				margin-bottom: 10px;

				@include fontsize(22);
			}
		}

		p {
			margin-top: 0;

			@include fontsize(11.5);

			@include mq-min(s) {
				@include fontsize(15);
			}
		}

		ul:last-child,
		ol:last-child {
			margin-bottom: 0;
		}
	}

	.imgandtxt {
		margin-top: 15px;

		@include mq-min(s) {
			margin-top: 20px;
		}

		.row1 {
			.col-6 {
				@include col(12, 0);

				@include mq-min(s) {
					@include col(6, 20px);
				}
			}
		}

		.row2 {
			.col-3 {
				@include col(6, 5px);

				@include mq-min(s) {
					@include col(3, 15px);
				}
			}

			.col-4 {
				@include col(6, 5px);

				@include mq-min(s) {
					@include col(4, 15px);
				}
			}
		}

		&__item01 {
			margin-bottom: 30px;

			@include col(6, 5px);

			@include mq-min(s) {
				margin-bottom: 55px;

				@include col(4, 15px);
			}

			& > div {
				border-bottom: 2px solid $primary_color;
				padding-bottom: 10px;
				height: 100%;

				@include mq-min(s) {
					padding-bottom: 20px;
				}
			}

			figure {
				margin: 0 0 15px;

				@include mq-min(s) {
					margin: 0 0 20px;
				}
			}

			h6 {
				font-weight: 700;
				line-height: (30 / 22);
				margin: 10px 0;

				@include fontsize(15);

				@include mq-min(s) {
					margin: 20px 0;

					@include fontsize(22);
				}
			}

			p {
				margin: 5px 0;
				line-height: (28 / 15);

				@include fontsize(12);

				@include mq-min(s) {
					margin: 10px 0;

					@include fontsize(16);
				}
			}
		}
	}

	.sbs {
		margin: 10px 0 15px;

		@include mq-min(s) {
			display: flex;
			margin: 20px 0 30px;
		}

		&.reverse {
			flex-direction: row-reverse;

			figure {
				@include mq-min(s) {
					margin: 10px 0 0 50px;
				}
			}
		}

		figure {
			margin: 0 0 20px;

			@include mq-min(s) {
				flex: 0 0 auto;
				margin: 10px 50px 0 0;
			}
		}

		.txtbox {
			width: 100%;
		}

		p {
			margin: 0;
		}
	}

	.category-board {
		margin-top: 15px;

		@include mq-min(s) {
			margin-top: 20px;
		}

		.inner {
			background-color: $white_color;
			border-top: 2px solid $primary_color;
			padding: 10px 15px 15px;
			height: 100%;

			@include mq-min(s) {
				border-top-width: 3px;
				padding: 10px 35px 30px;
			}
		}

		h6 {
			font-weight: 700;
			line-height: (30 / 22);
			margin: 10px 0 5px;

			@include fontsize(16);

			@include mq-min(s) {
				margin: 20px 0 -5px;

				@include fontsize(22);
			}
		}

		ul {
			margin-bottom: 0;
		}

		& > .row1 {
			.category-board__item {
				margin-bottom: 20px;

				@include mq-min(s) {
					margin-bottom: 30px;

					@include col(6, 20px);
				}

				&:last-child {
					@include mq-max(s) {
						margin-bottom: 0;
					}
				}
			}
		}

		&.type2 {
			.category-board__item {
				@include mq-min(s) {
					display: block;
				}

				.row {
					@include mq-min(s) {
						display: flex;
					}
				}

				ul {
					&.col-6 {
						@include mq-min(s) {
							margin-left: 40px;
							width: calc(50% - 20px);
						}
					}

					&.col-4 {
						@include mq-min(s) {
							margin-left: 15px;
							width: calc(33.3334% - 20px);
						}
					}

					&:first-child {
						@include mq-min(s) {
							margin-left: 0;
						}
					}
				}
			}
		}
	}

	.tablewrap {
		position: relative;
		margin: 35px 0;

		@include mq-min(s) {
			margin: 50px 0;
		}

		table {
			margin: 0;
		}

		&__inner {
			@include mq-max(xs) {
				display: block;
				overflow-x: scroll;
				white-space: nowrap;
				-webkit-overflow-scrolling: touch;
			}

			& > table {
				@include mq-max(s) {
					min-width: 560px;
				}
			}

			&::-webkit-scrollbar {
				height: 5px;
			}

			&::-webkit-scrollbar-track {
				background: $gray_light_color;
			}

			&::-webkit-scrollbar-thumb {
				background: $gray_color;
			}
		}

		.note-scroll {
			position: absolute;
			bottom: 100%;
			left: 0;
			margin: 0 0 5px;

			@include fontsize(10);

			@include mq-min(xs) {
				display: none;
			}
		}

		.note {
			margin: 10px 0 0;

			@include mq-min(s) {
				margin: 15px 0 0;
			}

			li {
				margin: 0.5em 0;
				padding-left: 0;
				line-height: (24 / 16);
				letter-spacing: 0.08em;

				@include fontsize(12);

				@include mq-min(s) {
					@include fontsize(16);
				}

				&:last-child {
					margin-bottom: 0;
				}

				&::before {
					display: none;
				}
			}
		}
	}

	.table-w-wrap {
		@include mq-min(ml) {
			display: flex;
		}

		&-l {
			@include mq-min(ml) {
				margin-right: 20px;
				width: 580px;
			}
		}

		&-r {
			@include mq-min(ml) {
				margin-left: 20px;
				width: 580px;
			}
		}
	}

	table {
		width: 100%;
		margin: 35px 0;

		@include mq-min(s) {
			margin: 50px 0;
		}

		&.small {
			th,
			td {
				padding: 12px 5px;

				@include fontsize(10);

				@include mq-min(s) {
					@include fontsize(12);
				}

				p {
					@include fontsize(10);

					@include mq-min(s) {
						@include fontsize(12);
					}
				}

				li {
					@include fontsize(10);

					@include mq-min(s) {
						@include fontsize(12);
					}

					&::before {
						top: 0.75em;

						@include mq-min(s) {
							top: 0.75em;
						}
					}

					& > p {
						line-height: (28 / 16);
					}
				}
			}

			th {
				@include mq-min(s) {
					padding: 20px 10px;
				}
			}

			td {
				@include mq-min(s) {
					padding: 20px 15px;
				}
			}
		}

		tr {
			&:last-child {
				td {
					border-bottom: none;
				}
			}

			&.close {
				th,
				td {
					position: relative;

					&:first-child {
						&::before {
							left: 0;
							width: 100%;
						}
					}
				}

				th {
					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: -1px;
						background: rgba($black_color, 0.5);
						width: calc(100% + 1px);
						height: 100%;
					}

					&.borderBottomWhite {
						&::before {
							height: calc(100% + 1px);
						}
					}
				}

				td {
					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: -1px;
						background: rgba($black_color, 0.5);
						width: calc(100% + 1px);
						height: calc(100% + 1px);
					}

					&.borderBottomNone {
						&::before {
							height: 100%;
						}
					}

					&.borderLeft {
						&::before {
							left: -1px;
							width: calc(100% + 1px);
						}
					}

					&.closeTxt {
						&::after {
							content: '受付終了';
							position: absolute;
							z-index: 5;
							top: 0;
							left: 0;
							display: flex;
							justify-content: center;
							align-items: center;
							color: $white_color;
							width: 560px;
							height: 100%;
							font-weight: 700;

							@include fontsize(15);

							@include mq-min(xs) {
								width: 93vw;
							}

							@include mq-min(s) {
								left: -40px;
								width: 992px;

								@include fontsize(20);
							}

							@include mq-min(sm) {
								width: 100vw;
								max-width: 1280px;
							}
						}
					}
				}

				&:last-child {
					td {
						&::before {
							height: 100%;
						}
					}
				}
			}
		}

		th,
		td {
			vertical-align: middle;
			line-height: (24 / 16);
			letter-spacing: 0.08em;

			@include fontsize(12);

			@include mq-min(s) {
				padding: 30px 25px;

				@include fontsize(16);
			}

			&:nth-last-of-type(1) {
				border-right: none;
			}

			p {
				margin: 0 0 10px;
				line-height: (24 / 16);
			}
		}

		th {
			background-color: $table_color;
			border-right: 1px solid $white_color;
			padding: 17px 10px;
			text-align: center;

			&.borderBottomWhite {
				border-bottom: 1px solid $white_color;
			}

			&.borderRightWhite {
				border-right: 1px solid $white_color;
			}
		}

		td {
			background-color: $white_color;
			border-right: 1px solid $table_color;
			border-bottom: 1px solid $table_color;
			padding: 17px 15px;

			&.borderBottomNone {
				border-bottom: none;
			}

			&.borderLeft {
				border-left: 1px solid $table_color;
			}

			&.borderRight {
				border-right: 1px solid $table_color;
			}

			&.bgColor {
				background-color: #f1f0f0;
			}

			ul {
				&:last-child {
					margin: 0 0 -10px;
				}
			}
		}
	}

	.wp-img-box {
		margin-top: 15px;

		@include mq-min(s) {
			margin-top: 20px;
		}

		.row1 {
			.col-6 {
				@include col(12, 0);

				@include mq-min(s) {
					@include col(6, 20px);
				}
			}
		}

		.row2 {
			.col-3 {
				@include col(6, 5px);

				@include mq-min(s) {
					@include col(3, 15px);
				}
			}

			.col-4 {
				@include col(6, 5px);

				@include mq-min(s) {
					@include col(4, 15px);
				}
			}
		}

		&__item01 {
			margin-bottom: 10px;

			@include col(6, 5px);

			@include mq-min(s) {
				margin-bottom: 30px;

				@include col(4, 15px);
			}

			figure {
				margin: 0;

				@include mq-min(s) {
					margin: 0;
				}
			}
		}
	}

	.wp-mov-box {
		margin: 20px 0 35px;

		@include mq-min(s) {
			margin: 20px 0 50px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.row1 {
			.col-6 {
				@include mq-min(s) {
					@include col(6, 20px);
				}
			}
		}

		.row2 {
			.col-4 {
				@include col(6, 5px);

				@include mq-min(s) {
					@include col(4, 15px);
				}
			}
		}

		&__item {
			margin-bottom: 20px;

			@include mq-min(s) {
				margin-bottom: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}

			.wp-mov {
				padding-top: 56.25%;
				position: relative;

				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}

			p {
				margin: 10px 0;
				line-height: (24 / 16);

				@include mq-min(s) {
					margin: 10px 0;
				}
			}
		}
	}

	.wp-map {
		position: relative;
		margin: 35px 0;
		padding-top: 56.25%;

		@include mq-min(s) {
			margin: 50px 0;
			padding-top: 35%;
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.wp-modal-g-box {
		margin-top: 15px;

		@include mq-min(s) {
			margin-top: 20px;
		}

		&__item {
			position: relative;
			z-index: 0;
			margin-bottom: 30px;

			@include col(6, 5px);

			@include mq-min(s) {
				margin-bottom: 40px;

				@include col(4, 15px);
			}
		}

		a {
			display: block;
			height: 100%;
			transition: all 0.2s ease;

			figure {
				position: relative;

				&::before {
					content: "";
					display: block;
					position: absolute;
					bottom: 5px;
					right: 5px;
					width: 20px;
					height: 20px;
					background-image: url(../img/common/icon_search_w.svg);
					background-position: center center;
					background-repeat: no-repeat;
					background-size: contain;
					z-index: 2;

					@include mq-min(s) {
						bottom: 6px;
						right: 7px;
						width: 28px;
						height: 28px;
					}
				}

				&::after {
					content: "";
					display: block;
					width: 30px;
					height: 30px;
					background-color: $primary_color;
					position: absolute;
					bottom: 0;
					right: 0;
					z-index: 1;

					@include mq-min(s) {
						width: 40px;
						height: 40px;
					}
				}
			}

			&:hover {
				figure img {
					@include mq-min(s) {
						transform: scale(1.05);
					}
				}
			}
		}

		figure {
			margin: 0;
			width: 100%;
			overflow: hidden;

			img {
				transition: all 0.3s cubic-bezier(.25, .46, .45, .94);
			}
		}

		.txtbox {
			position: relative;
			margin-top: 7px;

			@include mq-min(s) {
				margin-top: 10px;
			}
		}

		p {
			margin: 0;
			line-height: (24 / 16);
		}
	}

	.m-btn-wrap {
		margin-bottom: 20px;

		@include mq-min(s) {
			margin-bottom: 45px;
		}

		.row {
			flex-direction: column;

			@include flex-wrap;

			@include mq-min(s) {
				flex-direction: row;
				margin: 0 -15px -30px;
			}

			&.row-c {
				@include mq-max(s) {
					align-items: center;
				}

				@include mq-min(s) {
					justify-content: center;
				}
			}

			.m-btn {
				margin: 0 0 15px;
				max-width: 380px;

				@include mq-min(s) {
					margin-bottom: 30px;
					margin-left: percentage(15 / 1230);
					margin-right: percentage(15 / 1230);
				}

				&:last-child {
					@include mq-max(s) {
						margin-bottom: 0;
					}
				}
			}

			.m-btn2 {
				margin: 0 0 15px;

				@include mq-min(s) {
					margin-bottom: 30px;
					margin-left: percentage(15 / 1230);
					margin-right: percentage(15 / 1230);
				}

				&:last-child {
					@include mq-max(s) {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.m-btn {
		&::after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 20px;
			margin: auto;
			transition: all 0.2s ease;

			@include mq-min(s) {
				right: 30px;
			}
		}

		&.window {
			&::after {
				background-image: url("/hs/wp-content/themes/ghs/img/common/icon_window.svg");
				background-size: contain;
				background-repeat: no-repeat;
				width: 12px;
				height: 12px;

				@include mq-min(s) {
					width: 15px;
					height: 15px;
				}
			}

			&:hover {
				&::after {
					@include mq-min(s) {
						background-image: url("/hs/wp-content/themes/ghs/img/common/icon_window_w.svg");
					}
				}
			}
		}

		&.dl {
			&::after {
				background-image: url("/hs/wp-content/themes/ghs/img/common/icon_dl.svg");
				background-size: contain;
				background-repeat: no-repeat;
				width: 12px;
				height: 12px;

				@include mq-min(s) {
					width: 15px;
					height: 15px;
				}
			}

			&:hover {
				&::after {
					@include mq-min(s) {
						background-image: url("/hs/wp-content/themes/ghs/img/common/icon_dl_w.svg");
					}
				}
			}
		}

		&.doc {
			&::after {
				background-image: url("/hs/wp-content/themes/ghs/img/common/icon_doc.svg");
				background-size: contain;
				background-repeat: no-repeat;
				width: 12px;
				height: 15px;

				@include mq-min(s) {
					width: 15px;
					height: 18px;
				}
			}

			&:hover {
				&::after {
					@include mq-min(s) {
						background-image: url("/hs/wp-content/themes/ghs/img/common/icon_doc_w.svg");
					}
				}
			}
		}

		&.xls {
			&::after {
				background-image: url("/hs/wp-content/themes/ghs/img/common/icon_xls.svg");
				background-size: contain;
				background-repeat: no-repeat;
				width: 12px;
				height: 15px;

				@include mq-min(s) {
					width: 15px;
					height: 18px;
				}
			}

			&:hover {
				&::after {
					@include mq-min(s) {
						background-image: url("/hs/wp-content/themes/ghs/img/common/icon_xls_w.svg");
					}
				}
			}
		}

		&.pdf {
			&::after {
				background-image: url("/hs/wp-content/themes/ghs/img/common/icon_pdf.svg");
				background-size: contain;
				background-repeat: no-repeat;
				width: 12px;
				height: 15px;

				@include mq-min(s) {
					width: 15px;
					height: 18px;
				}
			}

			&:hover {
				&::after {
					@include mq-min(s) {
						background-image: url("/hs/wp-content/themes/ghs/img/common/icon_pdf_w.svg");
					}
				}
			}
		}

		&.long {
			max-width: 100%;

			@include mq-min(s) {
				width: 100%;
			}
		}

		&.big {
			margin: 15px auto 0;
			padding: 5px 35px;
			max-width: 100%;
			height: 70px;
			border-radius: 35px;

			@include fontsize(16);

			@include mq-min(s) {
				margin: 30px auto 0;
				padding: 5px 50px;
				width: 100%;
				height: 120px;
				border-radius: 60px;

				@include fontsize(22);
			}

			&::after {
				right: 20px;

				@include mq-min(s) {
					right: 35px;
				}
			}

			&.arw {
				&::after {
					width: 10px;
					height: 10px;

					@include mq-min(s) {
						width: 13px;
						height: 13px;
					}
				}
			}

			&.window {
				&::after {
					width: 14px;
					height: 14px;

					@include mq-min(s) {
						width: 17px;
						height: 17px;
					}
				}
			}

			&.dl {
				&::after {
					width: 15px;
					height: 15px;

					@include mq-min(s) {
						width: 18px;
						height: 18px;
					}
				}
			}

			&.doc {
				&::after {
					width: 15px;
					height: 18px;

					@include mq-min(s) {
						width: 18px;
						height: 22px;
					}
				}
			}

			&.xls {
				&::after {
					width: 15px;
					height: 18px;

					@include mq-min(s) {
						width: 18px;
						height: 22px;
					}
				}
			}

			&.pdf {
				&::after {
					width: 15px;
					height: 18px;

					@include mq-min(s) {
						width: 18px;
						height: 22px;
					}
				}
			}
		}
	}

	.m-btn2 {
		position: relative;
		display: flex;
		align-items: center;
		background: $white_color;
		border-left: 1px solid $primary_color;
		margin: 15px auto 0;
		padding: 15px 30px 15px 20px;
		width: 100%;
		max-width: 380px;
		box-shadow: 1px 3px 30px 3px rgba($black_color, 0.1);
		transition: all 0.2s ease;

		@include fontsize(12);

		@include mq-min(s) {
			border-left-width: 2px;
			margin: 30px auto 0;
			padding: 33px 50px 33px 35px;
			box-shadow: 2.5px 6.5px 72px 7px rgba($black_color, 0.1);

			@include fontsize(16);
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 15px;
			margin: auto;

			@include mq-min(s) {
				right: 25px;
			}
		}

		&:hover {
			@include mq-min(s) {
				color: $primary_color;
			}
		}

		&.arw {
			&::after {
				border-top: 2px solid $black_color;
				border-right: 2px solid $black_color;
				width: 7px;
				height: 7px;
				transform: rotate(45deg);
				transition: all 0.2s ease;

				@include mq-min(s) {
					border-top-width: 3px;
					border-right-width: 3px;
					width: 9px;
					height: 9px;
				}
			}

			&:hover {
				&::after {
					@include mq-min(s) {
						border-top-color: $primary_color;
						border-right-color: $primary_color;
					}
				}
			}
		}

		&.window {
			&::after {
				background-image: url("/hs/wp-content/themes/ghs/img/common/icon_window2.svg");
				background-size: contain;
				background-repeat: no-repeat;
				width: 10px;
				height: 10px;

				@include mq-min(s) {
					width: 14px;
					height: 14px;
				}
			}

			&:hover {
				&::after {
					@include mq-min(s) {
						background-image: url("/hs/wp-content/themes/ghs/img/common/icon_window2_o.svg");
					}
				}
			}
		}

		&.dl {
			&::after {
				background-image: url("/hs/wp-content/themes/ghs/img/common/icon_dl.svg");
				background-size: contain;
				background-repeat: no-repeat;
				width: 12px;
				height: 12px;

				@include mq-min(s) {
					width: 15px;
					height: 15px;
				}
			}

			&:hover {
				&::after {
					@include mq-min(s) {
						background-image: url("/hs/wp-content/themes/ghs/img/common/icon_dl_o.svg");
					}
				}
			}
		}

		&.doc {
			&::after {
				background-image: url("/hs/wp-content/themes/ghs/img/common/icon_doc.svg");
				background-size: contain;
				background-repeat: no-repeat;
				width: 12px;
				height: 15px;

				@include mq-min(s) {
					width: 15px;
					height: 18px;
				}
			}

			&:hover {
				&::after {
					@include mq-min(s) {
						background-image: url("/hs/wp-content/themes/ghs/img/common/icon_doc_o.svg");
					}
				}
			}
		}

		&.xls {
			&::after {
				background-image: url("/hs/wp-content/themes/ghs/img/common/icon_xls.svg");
				background-size: contain;
				background-repeat: no-repeat;
				width: 12px;
				height: 15px;

				@include mq-min(s) {
					width: 15px;
					height: 18px;
				}
			}

			&:hover {
				&::after {
					@include mq-min(s) {
						background-image: url("/hs/wp-content/themes/ghs/img/common/icon_xls_o.svg");
					}
				}
			}
		}

		&.pdf {
			&::after {
				background-image: url("/hs/wp-content/themes/ghs/img/common/icon_pdf.svg");
				background-size: contain;
				background-repeat: no-repeat;
				width: 12px;
				height: 15px;

				@include mq-min(s) {
					width: 15px;
					height: 18px;
				}
			}

			&:hover {
				&::after {
					@include mq-min(s) {
						background-image: url("/hs/wp-content/themes/ghs/img/common/icon_pdf_o.svg");
					}
				}
			}
		}

		&.long {
			width: 100%;
			max-width: 100%;
		}

		&.big {
			padding-right: 40px;
			max-width: 100%;
			min-height: 65px;
			font-weight: 700;
			letter-spacing: 0.08em;

			@include fontsize(16);

			@include mq-min(s) {
				padding-right: 70px;
				min-height: 130px;

				@include fontsize(22);
			}

			&::after {
				right: 20px;

				@include mq-min(s) {
					right: 35px;
				}
			}

			&.arw {
				&::after {
					width: 10px;
					height: 10px;

					@include mq-min(s) {
						width: 13px;
						height: 13px;
					}
				}
			}

			&.window {
				&::after {
					width: 14px;
					height: 14px;

					@include mq-min(s) {
						width: 17px;
						height: 17px;
					}
				}
			}

			&.dl {
				&::after {
					width: 15px;
					height: 15px;

					@include mq-min(s) {
						width: 18px;
						height: 18px;
					}
				}
			}

			&.doc {
				&::after {
					width: 15px;
					height: 18px;

					@include mq-min(s) {
						width: 18px;
						height: 22px;
					}
				}
			}

			&.xls {
				&::after {
					width: 15px;
					height: 18px;

					@include mq-min(s) {
						width: 18px;
						height: 22px;
					}
				}
			}

			&.pdf {
				&::after {
					width: 15px;
					height: 18px;

					@include mq-min(s) {
						width: 18px;
						height: 22px;
					}
				}
			}
		}

		.thumb {
			display: block;
			margin-right: 15px;
			width: 80px;

			@include mq-min(s) {
				margin-right: 45px;
				width: 150px;
			}
		}
	}

	.wp-point-box {
		margin: 10px 0;

		@include mq-min(s) {
			margin: 20px 0;
		}

		.row2 {
			counter-reset: number 0;

			.col-2_5 {
				margin-bottom: 15px;

				@include col(6, 5px);

				@include mq-min(s) {
					margin-bottom: 25px;

					@include col(2.4, 15px);
				}

				&::before {
					left: 5px;
					width: calc(100% - 10px);

					@include mq-min(s) {
						left: 15px;
						width: calc(100% - 30px);
					}
				}
			}

			.col-3 {
				margin-bottom: 15px;

				@include col(6, 5px);

				@include mq-min(s) {
					margin-bottom: 25px;

					@include col(3, 15px);
				}

				&::before {
					left: 5px;
					width: calc(100% - 10px);

					@include mq-min(s) {
						left: 15px;
						width: calc(100% - 30px);
					}
				}
			}

			.col-4 {
				margin-bottom: 15px;

				@include col(6, 5px);

				@include mq-min(s) {
					margin-bottom: 25px;

					@include col(4, 15px);
				}

				&::before {
					left: 5px;
					width: calc(100% - 10px);

					@include mq-min(s) {
						left: 15px;
						width: calc(100% - 30px);
					}
				}
			}
		}

		&__item {
			position: relative;
			z-index: 0;

			&::before {
				content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				background: $white_color;
				height: 100%;
			}
		}

		.txtbox {
			background-color: $white_color;
			padding: 15px 10px;
			border-top: 2px solid $primary_color;
			position: relative;
			text-align: center;

			@include mq-min(s) {
				border-top-width: 3px;
				padding: 30px 10px;
			}
		}

		.icon {
			padding: 5px 0 12px;

			@include mq-min(s) {
				padding: 10px 0 35px;
			}
		}

		dt {
			padding: 7px 0;

			&::before {
				counter-increment: number 1;
				content: "POINT."counter(number);
				display: block;
				color: $primary_color;
				font-family: $en_font;
				font-weight: 700;
				line-height: 1;
				letter-spacing: 0.08em;

				@include fontsize(12);

				@include mq-min(s) {
					@include fontsize(16);
				}
			}
		}

		dd {
			display: flex;
			justify-content: center;
			align-items: center;
			color: $text_color;
			min-height: 3.75em;
			font-weight: bold;
			line-height: (30 / 22);

			@include fontsize(15);

			@include mq-min(s) {
				@include fontsize(22);
			}
		}
	}

	.wp-figcap-box {
		margin: 20px 0 35px;

		@include mq-min(s) {
			margin: 20px 0 50px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.row1 {
			.col-6 {
				@include mq-min(s) {
					@include col(6, 20px);
				}
			}
		}

		.row2 {
			.col-4 {
				@include col(6, 5px);

				@include mq-min(s) {
					@include col(4, 15px);
				}
			}
		}

		&__item {
			margin-bottom: 30px;

			@include mq-min(s) {
				margin-bottom: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}

			figure {
				position: relative;
				margin: 0;
				width: 100%;
			}

			p {
				margin: 10px 0;
				line-height: (24 / 16);

				@include mq-min(s) {
					margin: 10px 0;
				}
			}
		}
	}

	.wp-guide-box-l {
		margin-top: 20px;

		@include mq-min(s) {
			margin-top: 30px;
		}

		&__item {
			background: $white_color;
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			padding: 20px 15px;

			@include mq-min(s) {
				margin-bottom: 50px;
				padding: 40px 60px;
			}
		}

		figure {
			margin: 0 !important;
			width: percentage(190 / 510);

			@include mq-min(s) {
				width: percentage(450 / 1080);
			}
		}

		.txtbox {
			position: relative;
			margin-left: percentage(20 / 510);
			width: percentage(300 / 510);

			@include mq-min(s) {
				margin-left: percentage(50 / 1080);
				width: percentage(580 / 1080);
			}
		}

		h6 {
			color: $text_color;
			margin-bottom: 10px;
			font-weight: 700;

			@include fontsize(15);

			@include mq-min(s) {
				margin-bottom: 25px;

				@include fontsize(28);
			}
		}

		p {
			margin: 0 !important;
		}
	}

	.wp-guide-box {
		margin: 20px 0 30px;

		@include mq-min(s) {
			margin: 30px 0 50px;
		}

		.row1 {
			.col-6 {
				margin-bottom: 20px;

				@include mq-min(s) {
					margin-bottom: 50px;

					@include col(6, 20px);
				}
			}
		}

		&__item {
			position: relative;
			z-index: 0;

			.inner {
				display: flex;
				align-items: center;
				background: $white_color;
				padding: 20px 15px;
				height: 100%;

				@include mq-min(s) {
					padding: 30px 35px;
				}
			}
		}

		figure {
			margin: 0 !important;
			width: percentage(190 / 510);
		}

		.txtbox {
			position: relative;
			margin-left: percentage(20 / 510);
			width: percentage(300 / 510);
		}

		h6 {
			color: $text_color;
			margin-bottom: 10px;
			font-weight: 700;

			@include fontsize(15);

			@include mq-min(s) {
				margin-bottom: 25px;

				@include fontsize(22);
			}
		}

		p {
			margin: 0 !important;
		}
	}

	.capmpus-event-cal {
		&__item {
			position: relative;
			z-index: 0;
			display: flex;
			justify-content: space-between;
			margin-bottom: 35px;

			@include mq-min(s) {
				margin-bottom: 60px;
			}

			&:last-child {
				margin-bottom: 0;

				&::before {
					display: none;
				}
			}

			&::before {
				content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				left: 31px;
				background: #ddd;
				width: 2px;
				height: calc(100% + 35px);

				@include mq-min(s) {
					left: 74px;
					width: 3px;
					height: calc(100% + 60px);
				}
			}

			.month {
				display: flex;
				flex: 0 0 auto;
				justify-content: center;
				align-items: center;
				background: $primary_color;
				color: $white_color;
				width: 64px;
				height: 64px;
				font-weight: 700;
				line-height: 1.15;
				letter-spacing: 0.08em;

				@include fontsize(24);

				@include mq-min(s) {
					width: 150px;
					height: 150px;

					@include fontsize(40);
				}

				span {
					display: block;
					margin-top: 0.4em;
					font-size: 60%;
				}
			}

			.card {
				border-top: 2px solid $primary_color;
				background: $white_color;
				margin-left: 15px;
				padding: 7px 25px 15px;

				@include mq-min(s) {
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-top-width: 4px;
					margin-left: 50px;
					padding: 25px 60px;
				}
			}

			.list {
				margin: 0 0 15px;

				@include mq-min(s) {
					margin-bottom: 0;
					width: percentage(450 / 880);
				}
			}

			.img {
				margin: 0;

				@include mq-min(s) {
					margin-left: percentage(30 / 880);
					width: percentage(400 / 880);
				}
			}
		}
	}

	.gcal {
		text-align: center;

		iframe {
			width: 100%;
			height: 400px;

			@include mq-min(s) {
				height: 500px;
			}

			@include mq-min(sm) {
				height: 600px;
			}
		}
	}

	.undergraduate-box {
		margin: -15px 0 10px;

		@include mq-min(s) {
			margin: -25px -10px 45px;

			@include flex-wrap;
		}

		& > .sitemap__block {
			margin-bottom: 25px;

			@include mq-min(s) {
				margin-bottom: 30px;
				padding: 0 10px;
				width: 50%;
			}
		}
	}

	.sitemap__block {
		margin-bottom: 35px;

		@include mq-min(s) {
			margin-bottom: 75px;
		}

		.undergraduate-ttl {
			position: relative;
			padding: 0 0 7px;
			margin: 0 0 15px;
			font-weight: 400;
			letter-spacing: 0.08em;

			@include fontsize(14);

			@include mq-min(s) {
				padding: 0 0 12px;
				margin: 0 0 20px;

				@include fontsize(18);
			}

			&::before {
				content: "";
				display: block;
				height: 2px;
				width: 100%;
				background-color: #ccc;
				position: absolute;
				top: auto;
				bottom: 0;
				left: 0;
			}

			&.yellow {
				&::before {
					background: $dept_yellow_color;
				}

				a {
					&::before {
						border-top-color: $dept_yellow_color;
						border-right-color: $dept_yellow_color;
					}
				}
			}

			&.orange {
				&::before {
					background: $dept_orange_color;
				}

				a {
					&::before {
						border-top-color: $dept_orange_color;
						border-right-color: $dept_orange_color;
					}
				}
			}

			&.blue {
				&::before {
					background: $dept_blue_color;
				}

				a {
					&::before {
						border-top-color: $dept_blue_color;
						border-right-color: $dept_blue_color;
					}
				}
			}

			&.purple {
				&::before {
					background: $dept_purple_color;
				}

				a {
					&::before {
						border-top-color: $dept_purple_color;
						border-right-color: $dept_purple_color;
					}
				}
			}

			&.green {
				&::before {
					background: $dept_green_color;
				}

				a {
					&::before {
						border-top-color: $dept_green_color;
						border-right-color: $dept_green_color;
					}
				}
			}

			&.pink {
				&::before {
					background: $dept_pink_color;
				}

				a {
					&::before {
						border-top-color: $dept_pink_color;
						border-right-color: $dept_pink_color;
					}
				}
			}

			a {
				position: relative;
				padding-left: 20px;
				transition: all 0.2s ease;

				@include mq-min(s) {
					padding-left: 25px;
				}

				&::before {
					content: '';
					position: absolute;
					top: 0.5em;
					left: 0;
					border-top: 2px solid $primary_color;
					border-right: 2px solid $primary_color;
					width: 7px;
					height: 7px;
					transform: rotate(45deg);
					transition: all 0.2s ease;

					@include mq-min(s) {
						top: 0.55em;
						border-top-width: 3px;
						border-right-width: 3px;
						width: 9px;
						height: 9px;
					}
				}

				&:hover {
					@include mq-min(s) {
						opacity: 0.7;
					}
				}
			}
		}
	}

	.sitemap__list {
		@include mq-min(s) {
			margin: 0 -10px;

			@include flex-wrap;
		}
	}

	.sitemap__item {
		padding: 0;

		@include mq-min(s) {
			padding: 0 10px;
			width: 50%;
		}

		&::before {
			display: none;
		}

		a {
			position: relative;
			display: block;
			padding: 5px 0 5px 20px;
			letter-spacing: 0.08em;
			transition: all 0.2s ease;

			@include fontsize(12);

			@include mq-min(s) {
				padding: 0 0 0 25px;

				@include fontsize(16);
			}

			&::before {
				content: '';
				position: absolute;
				top: calc(0.5em + 5px);
				left: 0;
				border-top: 2px solid $primary_color;
				border-right: 2px solid $primary_color;
				width: 7px;
				height: 7px;
				transform: rotate(45deg);
				transition: all 0.2s ease;

				@include mq-min(s) {
					top: 0.65em;
					border-top-width: 3px;
					border-right-width: 3px;
					width: 9px;
					height: 9px;
				}
			}

			&:hover {
				@include mq-min(s) {
					opacity: 0.7;
				}
			}
		}
	}

	.arowlink {
		position: relative;
		padding-left: 18px;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 8px;
			width: 7px;
			height: 7px;
			border-top: 2px solid $primary_color;
			border-right: 2px solid $primary_color;
			transform: rotate(45deg);
		}
	}

	.sec-search-results {
		margin-top: 55px;

		@include mq-min(s) {
			margin-top: 105px;
		}

		&__area {
			background: $white_color;
			padding: 16px 10px;

			@include mq-min(s) {
				padding: 40px 80px 60px;
			}
		}

		table {
			margin: 0;

			td {
				border: none;
				padding: 0;
			}
		}
	}

	.obogNav {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

		&__item {
			display: block;
			border: 1px solid $primary_color;
			font-size: 13px;
			color: $primary_color;
			padding: 7px 10px;
			margin-bottom: 8px;
			width: 49%;
			background-color: $white_color;
			text-align: center;
			font-weight: bold;
			transition: 0.3s;

			@include mq-min(s) {
				width: auto;
				width: percentage(380 / 1200);
				font-size: 17px;
				margin-bottom: 0;
				padding: 28px 14px;
			}

			&:hover {
				@include mq-min(s) {
					color: $white_color;
					background-color: $primary_color;
				}
			}
		}
	}

	.obogInterviewbox {
		background-color: $white_color;
		padding: 1px 16px 16px;

		@include mq-min(s) {
			padding: 24px 70px 70px 70px;
		}

		&:nth-child(n+2) {
			margin-top: 45px;
		}

		&__flex {
			@include mq-min(s) {
				display: flex;
				flex-direction: row-reverse;
			}
		}

		&__fig {
			@include mq-min(s) {
				width: 41.5%;
				margin-left: 4.7%;
				margin-top: 10px;
			}
		}

		&__txtbox {
			@include mq-min(s) {
				width: 53.8%;
			}
		}

		&__txt {
			@include mq-min(s) {
				margin-top: 0;
			}
		}

		&__name {
			border-bottom: 1px solid $primary_color;
			padding-bottom: 8px;
			font-size: 16px;
			font-weight: bold;

			@include mq-min(s) {
				font-size: 22px;
			}
		}

		&__employ {
			font-weight: bold;
		}

		&__employ .p02 {
			color: $primary_color;

			@include mq-min(s) {
				margin-bottom: 0;
			}
		}
	}

	#campusmap {
		.m-btn-wrap {
			@include mq-min(s) {
				margin-top: -110px;
			}
		}
	}
}
